/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Performance from "./Performance";
import {
  getSingleStudentData,
  getSingleStudentSBRData,
  getStandardBaseReport,
  getStudentDataForReportCard,
  getStudentReportCardData,
} from "redux/actionCreators/classroom";
import { orderBy } from "lodash";
import filterAndReorderClassrooms from "./types/classroomObjectsFilter";
import StudentPerformancePrint from "./print/StudentPerformancePrint";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import BasedReportPrint from "../../components/StandardReports/Based/Print";
import DownloadPdf from "../../components/StandardReports/Based/Download";
import StudentHeader from "components/common/StudentDetailHeader";
import StandardBasedReport from "components/common/StandardBased";
import { useLocation } from "react-router-dom";
import { useSchoolName } from "hooks/useSchoolName";
import { performanceOptions } from "./types/type";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";

interface RootState {
  auth: any;
  studentReport: any;
  classroom: {
    studentsReportData: any;
    classroomSchoolId: string;
    classroomId: string;
    classrooms: any;
    studentReport: {
      data: {
        students_mapped: any;
        performance_level: {};
        performance: any;
        students: any;
      };
    };
    studentDetailData: {
      data: { [key: string]: any };
    };
    studentSBRDetailData: any;
    subject: number;
  };
}

const ReportCard: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [allStudentsPerformanceData, setAllStudentsPerformanceData] = useState<
    any[]
  >([]);
  const [selectedPerformance, setSelectedPerformance] = useState<any>(
    performanceOptions[0]
  );
  const [allStudentsSBRData, setAllStudentsSBRData] = useState<any>([]);
  const [performanceData, setPerformanceData] = useState<any>({});
  const [classroomStudentData, setClassroomStudentData] = useState<any[]>([]);
  const [studentData, setStudentData] = useState<any>({});
  const [studentSBRData, setStudentSBRData] = useState<any>([]);
  const [classrooms, setClassrooms] = useState<any>({});
  const [sortInfo, setSortInfo] = useState<any>([]);
  const [isPerformance, setIsPerformance] = useState(true);
  const [performanceProgress, setPerformanceProgress] = useState<any>();
  const [studentSelected, setStudentSelected] = useState(0);
  const [yearSelected, setYearSelected] = useState(1);
  const [descriptionRowsHeights, setDescriptionRowsHeights] = useState<any>([]);
  const [skeletonLoad, setSkeletonLoad] = useState(false);
  const [previousYearClassroom, setPreviousYearClassroom] = useState(0);
  const [previousYearStudent, setPreviousYearStudent] = useState(0);
  const [showLastThreeAttempts, setShowLastThreeAttempts] = useState(true);
  const [showAllClasses, setShowAllClasses] = useState(
    location.state?.student ? true : false
  );
  const componentPerformancePrintRef = useRef(null);
  const componentPerformancePrintAllRef = useRef(null);
  const componentSBRPrintRef = useRef(null);
  const componentSBRPrintAllRef = useRef(null);

  const detailsRef = useRef<any>(null);
  const app = useSelector((state: RootState) => state);

  const school_name = useSchoolName();

  const handlePerformancePrint = useReactToPrint({
    content: () => componentPerformancePrintRef.current,
    documentTitle: `StudentDetailsReport_${
      studentData?.student?.first_name
    }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      window.close();
    },
  });

  const handlePerformancePrintAll = useReactToPrint({
    content: () => componentPerformancePrintAllRef.current,
    documentTitle: `StudentDetailsReport_${
      app?.classroom?.classrooms[0]?.name
        ? app?.classroom?.classrooms[0]?.name
        : "classroom"
    }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      window.close();
    },
  });

  const handleSBRPrint = useReactToPrint({
    content: () => componentSBRPrintRef.current,
    documentTitle: `StandardBasedReport_${
      studentData?.student?.first_name
        ? studentData?.student?.first_name
        : "student"
    }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      window.close();
    },
  });

  const handleSBRPrintAll = useReactToPrint({
    content: () => componentSBRPrintAllRef.current,
    documentTitle: `StandardBasedReport_${
      app?.classroom?.classrooms[0]?.name
        ? app?.classroom?.classrooms[0]?.name
        : "classroom"
    }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      window.close();
    },
  });

  const handlePrintClick = (option: string, type: number) => {
    if (type === 1 && option === "print") handlePerformancePrint();
    else if (type === 2 && option === "print") handleSBRPrint();
    else if (type === 1 && option === "print_all") handlePerformancePrintAll();
    else if (type === 2 && option === "print_all") handleSBRPrintAll();
  };

  const handleSortClick = (sortData: any) => {
    const info = sortInfo.find(
      (item: any) =>
        item.className === sortData.className &&
        item.isSummative === sortData.isSummative
    );
    if (info) {
      const updatedData = sortInfo.map((data: any) => {
        if (data === info) {
          data.orderBy = info.orderBy === "ASC" ? "DESC" : "ASC";
        }
        return data;
      });
      setSortInfo([...updatedData]);
    } else {
      sortData.orderBy = "DESC";
      setSortInfo([...sortInfo, sortData]);
    }
  };

  const fetchAllSBRData = async () => {
    const checkClassroomId =
      yearSelected === 2
        ? +previousYearClassroom
        : +app?.classroom?.classroomId;

    if (checkClassroomId) {
      const response: any = await getStandardBaseReport(checkClassroomId);
      setAllStudentsSBRData(response?.data?.data);
    }
  };

  const getClassName = (sortData: any) => {
    const info = sortInfo.find(
      (item: any) =>
        item.className === sortData.className &&
        item.isSummative === sortData.isSummative
    );

    return info?.orderBy === "DESC"
      ? "arrow-down-classname"
      : "arrow-up-classname";
  };

  const classroomDataUpdate = (studentInfo: any) => {
    const classrooms: any = {};

    studentInfo?.performance?.tests &&
      Object.keys(studentInfo.performance.tests).forEach((item: any) => {
        studentInfo.performance.tests[item].forEach((sub: any) => {
          const key = sub.summative ? "summatives" : "formatives";
          if (!classrooms[item]) {
            classrooms[item] = { summatives: [], formatives: [] };
          }
          classrooms[item][key].push(sub);
        });
      });

    Object.keys(classrooms).forEach((cls) => {
      const sortDataSummative = sortInfo.find(
        (item: any) => item.className === cls && item.isSummative === true
      );
      const sortDataFormative = sortInfo.find(
        (item: any) => item.className === cls && item.isSummative === false
      );
      classrooms[cls].summatives = orderBy(
        classrooms[cls].summatives,
        [(sum: any) => moment(sum["date"])],
        [sortDataSummative ? sortDataSummative.orderBy.toLowerCase() : "asc"]
      );
      classrooms[cls].formatives = orderBy(
        classrooms[cls].formatives,
        [(sum: any) => moment(sum["date"])],
        [sortDataFormative ? sortDataFormative.orderBy.toLowerCase() : "asc"]
      );
    });

    return classrooms;
  };

  const setPerformanceDataWithClassesToggle = (
    classroomId: number,
    data: any
  ) => {
    const testData = filterAndReorderClassrooms(
      data,
      +classroomId,
      showAllClasses ? false : true
    );

    setPerformanceProgress({
      ...data,
      tests: testData,
    });
  };

  const formatStudentName = (student_name: string) => {
    const nameArray = student_name?.split(", ");
    if (nameArray.length) {
      return `${nameArray[nameArray.length - 1]}${
        nameArray.length > 1 ? ` ${nameArray[0]}` : ""
      }`;
    }

    return "";
  };

  const getFilteredSBRData = useMemo(() => {
    let studentFilteredData = studentSBRData;

    if (!showAllClasses) {
      const classroomId =
        yearSelected === 2
          ? previousYearClassroom
          : app?.classroom?.classroomId;

      studentFilteredData = studentFilteredData
        .filter((data: any) => +data.classroom_id === +classroomId)
        .map((data: any) => {
          return {
            ...data,
            student_formatted_name: formatStudentName(data?.student_name),
          };
        });
    } else {
      studentFilteredData = studentFilteredData
        .filter((data: any) => +data.classroom_name?.indexOf("Science") === -1)
        .map((data: any) => {
          return {
            ...data,
            student_formatted_name: formatStudentName(data?.student_name),
          };
        });
    }

    return studentFilteredData;
  }, [
    studentSBRData,
    showAllClasses,
    previousYearClassroom,
    isPerformance,
    app?.classroom?.classroomId,
  ]);

  const triggerUpdate = (value: any, type: number) => {
    if (!value || studentSelected <= 0) return;

    setSkeletonLoad(true);
    if (type === 1) {
      setStudentSelected(value);
      if (isPerformance) {
        dispatch(getSingleStudentData({ studentId: value }));
      } else {
        const previousYear = yearSelected === 1 ? false : true;
        dispatch(
          getSingleStudentSBRData({
            classroomId: previousYear
              ? previousYearClassroom
              : app?.classroom?.classroomId,
            studentId: value,
            previousYear,
          })
        );
      }
    } else if (type === 2) {
      setYearSelected(value);
      const previousYear = value === 1 ? false : true;

      if (isPerformance) {
        if (value === 2) {
          const classroomData = classroomDataUpdate(
            studentData?.previous_year_data
          );
          setClassrooms(classroomData);
          setPerformanceDataWithClassesToggle(
            +previousYearClassroom,
            studentData?.previous_year_data?.performance
          );
        } else {
          dispatch(getSingleStudentData({ studentId: studentSelected }));
        }
      } else {
        dispatch(
          getSingleStudentSBRData({
            studentId: previousYear
              ? studentData?.student?.previous_year_id
              : studentSelected,
            classroomId: previousYear
              ? previousYearClassroom
              : app?.classroom?.classroomId,
            previous_year: previousYear,
          })
        );
      }
    } else if (type === 3) {
      setIsPerformance(value !== 2);
      const previousYear = yearSelected === 1 ? false : true;

      if (value === 1) {
        dispatch(getSingleStudentData({ studentId: studentSelected }));
      } else {
        dispatch(
          getSingleStudentSBRData({
            studentId: previousYear ? previousYearStudent : studentSelected,
            classroomId: previousYear
              ? previousYearClassroom
              : app?.classroom?.classroomId,
            previous_year: previousYear,
          })
        );
      }
    }
  };

  const getInitialStudentState = () => {
    if (location.state?.student?.id) {
      setStudentSelected(location.state.student.id);
      dispatch(getSingleStudentData({ studentId: location.state.student.id }));
    } else if (app?.classroom?.classroomId) {
      dispatch(
        getStudentDataForReportCard({
          classroomId: app?.classroom?.classroomId,
        })
      );
    }
  };

  useEffect(() => {
    getInitialStudentState();
  }, [app?.classroom?.classroomId]);

  useEffect(() => {
    setPerformanceData(
      app?.classroom?.studentsReportData?.performance_level || {}
    );
    const mapStudents =
      app?.classroom?.studentsReportData?.mapped_students || [];
    setClassroomStudentData(mapStudents || []);
    if (mapStudents?.length > 0) {
      const firstStudentId = +mapStudents[0]?.id;
      setStudentSelected(firstStudentId);
      dispatch(getSingleStudentData({ studentId: firstStudentId }));
      dispatch(
        getStudentReportCardData({
          classroomId: app?.classroom?.classroomId,
        })
      );
    }
  }, [app.classroom.studentsReportData]);

  useEffect(() => {
    setAllStudentsPerformanceData(
      app?.classroom?.studentReport?.data?.students
    );

    fetchAllSBRData();
  }, [app.classroom.studentReport]);

  useEffect(() => {
    setPreviousYearClassroom(
      app?.classroom?.studentDetailData?.data?.previous_year_data?.performance
        ?.classroom_ids[0]?.classroom_id
    );

    setPreviousYearStudent(
      app?.classroom?.studentDetailData?.data?.previous_year_data?.student
        ?.id || 0
    );
    if (yearSelected === 1) {
      const studentDetail = app?.classroom?.studentDetailData?.data || {};
      setStudentData(studentDetail);

      if (studentDetail?.performance) {
        setPerformanceDataWithClassesToggle(
          +app?.classroom?.classroomId,
          studentDetail.performance
        );
      }

      const classroomData = classroomDataUpdate(studentDetail);
      setClassrooms(classroomData);
    } else {
      const previousYearData =
        app?.classroom?.studentDetailData?.data?.previous_year_data || {};
      setStudentData(previousYearData);
      setPerformanceDataWithClassesToggle(
        app?.classroom?.studentDetailData?.data?.previous_year_data?.performance
          ?.classroom_ids[0]?.classroom_id,
        previousYearData.performance
      );

      const classroomData = classroomDataUpdate(previousYearData);
      setClassrooms(classroomData);
    }
  }, [app.classroom.studentDetailData]);

  useEffect(() => {
    setStudentSBRData(app?.classroom?.studentSBRDetailData?.data || []);
  }, [app?.classroom?.studentSBRDetailData]);

  useEffect(() => {
    if (skeletonLoad) {
      const timer = setTimeout(() => setSkeletonLoad(false), 500);
      return () => clearTimeout(timer);
    }
  }, [skeletonLoad]);

  useEffect(() => {
    if (yearSelected === 1) {
      if (studentData?.performance) {
        setPerformanceDataWithClassesToggle(
          +app.classroom.classroomId,
          studentData?.performance
        );
      }
    } else {
      if (studentData?.previous_year_data) {
        setPerformanceDataWithClassesToggle(
          +previousYearClassroom,
          studentData?.previous_year_data?.performance
        );
      }
    }
  }, [showAllClasses]);

  useEffect(() => {
    const classroomData = classroomDataUpdate(studentData);
    setClassrooms(classroomData);
  }, [sortInfo]);

  const genPdf = () => {
    let name = "";
    let format = [];
    let reactElem = null;
    switch (selectedPerformance.value) {
      case "standard-based":
        name = `StandardBasedReport_${
          studentData?.student?.first_name
            ? studentData?.student?.first_name
            : "student"
        }_${moment(new Date()).format("M/DD/YY hh:mmA")}`;
        format = [320, 340];
        reactElem = (
          <>
            {getFilteredSBRData?.map((student: any, index: number) => {
              return (
                <BasedReportPrint
                  data={student.objectives}
                  classroom_name={student?.classroom_name}
                  lang={"en"}
                  test_name={`Student: ${student.student_name}`}
                  answerRowsHeights={100}
                  school_name={school_name}
                  student_name={student?.student_formatted_name}
                  className="download-standard-based"
                />
              );
            })}
          </>
        );
        break;
      case "performance":
        name = `StudentDetailsReport_${
          studentData?.student?.first_name
        }_${moment(new Date()).format("M/DD/YY hh:mmA")}`;
        format = [253, 280];
        reactElem = (
          <StudentPerformancePrint
            classrooms={classrooms}
            performance={performanceProgress}
            studentData={studentData?.student}
            performanceHeaderData={performanceData}
            showLastThreeAttempts={showLastThreeAttempts}
            subject={app?.classroom?.subject}
            isPrev={yearSelected === 2 ? true : false}
            school_name={school_name}
            className="download-performance-report"
          />
        );
        break;
      default:
        break;
    }

    reactElem && generatePDF(reactElem, format, name);
  };

  const handlePerformanceAsync = (format: any, name: string) => {
    const firstRecord = allStudentsPerformanceData?.length
      ? allStudentsPerformanceData[0]
      : null;
    if (!firstRecord) return;

    const classroomData = classroomDataUpdate(firstRecord);

    const firstHtmlString = ReactDOMServer.renderToStaticMarkup(
      <StudentPerformancePrint
        classrooms={classroomData}
        performance={firstRecord?.performance}
        studentData={firstRecord?.student}
        performanceHeaderData={performanceData}
        showLastThreeAttempts={showLastThreeAttempts}
        subject={app?.classroom?.subject}
        isPrev={yearSelected === 2 ? true : false}
        school_name={school_name}
        className="download-performance-report"
      />
    );
    const opt = {
      margin: [5, 10, 5, 10],
      filename: name,
      html2canvas: { scale: 2, letterRendering: true },
      pagebreak: { mode: ["css", "legacy"] },
      jsPDF: { orientation: "p", unit: "mm", format: format },
    };

    let worker = html2pdf().set(opt).from(firstHtmlString);

    worker = worker.toPdf();

    const restRecords = allStudentsPerformanceData?.slice(1);

    let i = 0;
    while (i < restRecords.length) {
      const reactElem = (
        <>
          {restRecords
            ?.slice(i, i + 7)
            ?.map((allStudentPerformanceData, index) => {
              const classroomData = classroomDataUpdate(
                allStudentPerformanceData
              );

              return (
                <StudentPerformancePrint
                  classrooms={classroomData}
                  performance={allStudentPerformanceData?.performance}
                  studentData={allStudentPerformanceData?.student}
                  performanceHeaderData={performanceData}
                  showLastThreeAttempts={showLastThreeAttempts}
                  subject={app?.classroom?.subject}
                  isPrev={yearSelected === 2 ? true : false}
                  school_name={school_name}
                  className="download-performance-report"
                />
              );
            })}
        </>
      );
      const firstHtmlString = ReactDOMServer.renderToStaticMarkup(reactElem);
      worker = worker
        .get("pdf")
        .then((pdf) => {
          pdf.addPage();
        })
        .from(firstHtmlString)
        .toContainer()
        .toCanvas()
        .toPdf();
      i += 7;
    }

    worker.save();
  };

  const handleSBRAsync = (format) => {
    const firstSt = allStudentsSBRData?.students[0];
    const student_formatted_name = formatStudentName(firstSt.student_name);
    const firstHtmlString = ReactDOMServer.renderToStaticMarkup(
      <BasedReportPrint
        data={firstSt.objectives}
        classroom_name={firstSt?.classroom_name}
        lang={"en"}
        test_name={`Student: ${firstSt.student_name}`}
        answerRowsHeights={100}
        school_name={school_name}
        student_name={student_formatted_name}
        className="download-standard-based"
      />
    );
    const opt = {
      margin: [5, 10, 5, 10],
      filename: `StandardBasedReport_${
        studentData?.student?.first_name
      }_${moment(new Date()).format("M/DD/YY hh:mmA")}.pdf`,
      html2canvas: { scale: 2, letterRendering: true },
      pagebreak: { mode: ["css", "legacy"] },
      jsPDF: { orientation: "p", unit: "mm", format: format },
    };

    let worker = html2pdf().set(opt).from(firstHtmlString);

    worker = worker.toPdf();

    const restRecords = allStudentsSBRData?.students?.slice(1);

    let i = 0;
    while (i < restRecords.length) {
      const reactElem = (
        <>
          {restRecords?.slice(i, i + 7)?.map((student) => {
            const student_formatted_name = formatStudentName(
              student.student_name
            );
            return (
              <BasedReportPrint
                data={student.objectives}
                classroom_name={student?.classroom_name}
                lang={"en"}
                test_name={`Student: ${student.student_name}`}
                answerRowsHeights={100}
                school_name={school_name}
                student_name={student_formatted_name}
                className="download-standard-based"
              />
            );
          })}
        </>
      );
      const firstHtmlString = ReactDOMServer.renderToStaticMarkup(reactElem);
      worker = worker
        .get("pdf")
        .then((pdf) => {
          pdf.addPage();
        })
        .from(firstHtmlString)
        .toContainer()
        .toCanvas()
        .toPdf();
      i += 7;
    }

    worker.save();
  };
  const genPdfAll = () => {
    switch (selectedPerformance.value) {
      case "standard-based":
        const sName = `StandardBasedReport_${
          app?.classroom?.classrooms[0]?.name
            ? app?.classroom?.classrooms[0]?.name
            : "classroom"
        }`;
        handleSBRAsync([320, 340], sName);
        break;
      case "performance":
        const pName = `StudentDetailsReport_${
          app?.classroom?.classrooms[0]?.name
            ? app?.classroom?.classrooms[0]?.name
            : "classroom"
        }`;
        handlePerformanceAsync([253, 280], pName);
        break;
      default:
        break;
    }
  };

  const generatePDF = (reactElem: any, format: any, name: string) => {
    const htmlString = ReactDOMServer.renderToStaticMarkup(reactElem);

    const opt = {
      margin: [5, 10, 5, 10],
      filename: name,
      html2canvas: { scale: 2, letterRendering: true },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { orientation: "p", unit: "mm", format: format },
    };

    html2pdf()
      .from(htmlString)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.text(
            `© ${new Date().getFullYear()} Alpha Plus Educational Systems`,
            pdf.internal.pageSize.getWidth() - 160,
            pdf.internal.pageSize.getHeight() - 2
          );
        }
      })
      .save();
  };

  return (
    <>
      <div className="reportCards hide-on-print">
        <StudentHeader
          performance={performanceData}
          students={classroomStudentData}
          triggerUpdate={triggerUpdate}
          performanceEnabled={isPerformance}
          showLastThreeAttempts={showLastThreeAttempts}
          setShowLastThreeAttempts={setShowLastThreeAttempts}
          showAllClasses={showAllClasses}
          setShowAllClasses={setShowAllClasses}
          triggerPrint={handlePrintClick}
          subject={app?.classroom?.subject}
          isPrev={yearSelected === 2 ? true : false}
          setSelectedPerformance={setSelectedPerformance}
          selectedPerformance={selectedPerformance}
          download={genPdf}
          downloadAll={genPdfAll}
          studentData={studentData}
        />
        {isPerformance ? (
          <>
            <Performance
              performance={performanceProgress}
              classrooms={classrooms}
              handleSortClick={handleSortClick}
              getClassName={getClassName}
              skeletonLoad={skeletonLoad}
              showLastThreeAttempts={showLastThreeAttempts}
              showAllClasses={showAllClasses}
              performanceSets={performanceData}
              isPrev={yearSelected === 2 ? true : false}
            />
          </>
        ) : (
          getFilteredSBRData?.map((studentSBR: any, index: number) => {
            return (
              <StandardBasedReport
                key={index}
                isLoading={skeletonLoad}
                detailsRef={detailsRef}
                data={studentSBR}
                setDescriptionRowsHeights={setDescriptionRowsHeights}
                lang="en"
              />
            );
          })
        )}
      </div>
      {selectedPerformance?.value === "performance" && (
        <div ref={componentPerformancePrintRef} className="printData">
          <StudentPerformancePrint
            classrooms={classrooms}
            performance={performanceProgress}
            studentData={studentData?.student}
            performanceHeaderData={performanceData}
            showLastThreeAttempts={showLastThreeAttempts}
            subject={app?.classroom?.subject}
            isPrev={yearSelected === 2 ? true : false}
            school_name={school_name}
          />
        </div>
      )}

      {selectedPerformance?.value === "performance" &&
        allStudentsPerformanceData?.length > 0 && (
          <div className="hide-on-print">
            <div ref={componentPerformancePrintAllRef} className="printData">
              {allStudentsPerformanceData?.map(
                (allStudentPerformanceData, index) => {
                  const classroomData = classroomDataUpdate(
                    allStudentPerformanceData
                  );

                  return (
                    <div key={index}>
                      <StudentPerformancePrint
                        classrooms={classroomData}
                        performance={allStudentPerformanceData?.performance}
                        studentData={allStudentPerformanceData?.student}
                        performanceHeaderData={performanceData}
                        showLastThreeAttempts={showLastThreeAttempts}
                        subject={app?.classroom?.subject}
                        isPrev={yearSelected === 2 ? true : false}
                        school_name={school_name}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      {selectedPerformance?.value === "standard-based" &&
        studentData?.student &&
        descriptionRowsHeights &&
        studentSBRData?.length > 0 && (
          <div ref={componentSBRPrintRef}>
            {getFilteredSBRData?.map((student: any, index: number) => {
              return (
                <div
                  key={index}
                  className={`printData ${
                    index && "add-page-break"
                  } hide-on-print`}
                >
                  <BasedReportPrint
                    data={student.objectives}
                    classroom_name={student?.classroom_name}
                    lang={"en"}
                    test_name={`Student: ${student.student_name}`}
                    answerRowsHeights={100}
                    school_name={school_name}
                    student_name={student?.student_formatted_name}
                  />
                </div>
              );
            })}
          </div>
        )}
      {selectedPerformance?.value === "standard-based" &&
        studentData?.student &&
        descriptionRowsHeights &&
        allStudentsSBRData?.students?.length > 0 && (
          <div className="hide-on-print">
            <div ref={componentSBRPrintAllRef}>
              {allStudentsSBRData?.students?.map(
                (student: any, index: number) => {
                  const student_formatted_name = formatStudentName(
                    student.student_name
                  );
                  return (
                    <div
                      key={index}
                      className={`printData ${
                        index && "add-page-break"
                      } hide-on-print`}
                    >
                      <BasedReportPrint
                        data={student.objectives}
                        classroom_name={student?.classroom_name}
                        lang={"en"}
                        test_name={`Student: ${student.student_name}`}
                        answerRowsHeights={100}
                        school_name={school_name}
                        student_name={student_formatted_name}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default ReportCard;
