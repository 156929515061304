import { SchoolProgressSkeleton } from "components/common/skeleton/GraphSkeleton";
import {ProgressChart} from "./chart";

const FormativeProgressChart = ({classrooms, isLoading, assessmentType}: any) => {
    return (
        <>
        <p className="text-center fw-bold mb-0 chart-title">Formative Progress Chart</p>
            <div className="report hide-on-print">
                
                <div className="report-content">
                    <div className="d-flex justify-content-center">
                        <div className="formative-test">
                            <div></div>
                            <p>Formative Tests Taken (#)</p>
                        </div>
                        <div className="running-rate">
                            <div></div>
                            <p>Running Proficiency Rate (%)</p>
                        </div>
                    </div>
                    {/* <div className="report__performance">
                        <div className="report__performance-average">
                        <div className="report__performance-row">
                            <div className="d-flex align-items-center">
                            <div className="report__performance-avg averagep"></div>
                            <p>Average Proficiency for Grade</p>
                            </div>
                        </div>
                        <div className="report__performance-row">
                            <div className="d-flex align-items-center">
                            <div className="report__performance-avg averageo"><LinePlotIcon /></div>
                            <p>Average Number of OAS Objectives Assessed Per Class</p>
                            </div>
                        </div>
                        </div>
                    </div> */}
                    
                    <div className="report__chart">
                        <div className="report__chart-container">
                            {isLoading ? (
                                <SchoolProgressSkeleton columns={9} width={'100%'} height={506} customClass={'report__chart-skelton-item'} />
                            ) : (
                                <ProgressChart 
                                    data={classrooms || []}
                                    assessmentType={assessmentType}
                                />
                                
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default FormativeProgressChart;
