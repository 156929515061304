import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  registerables,
  Chart
} from "chart.js";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ordinal_suffix_of } from "util/index";

Chart.register(...registerables);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  PointElement,
  LineElement,
);

const getChartData = ( data: any) => {
  let labels: string[] = [];
  let dataLinesTemplate: any[] = [];
  let dataPointsTemplate: any[] = [];
  data = _.orderBy(data,)
  data?.map((item:any) => {
    for (let key in item) {
      labels.push(`${ordinal_suffix_of(Number(key))} Grade`)
      dataPointsTemplate.push(item[key]['avg_proficiency'])
      dataLinesTemplate.push(item[key]['avg_formatives_tested'])
    }

    return item;
  })

  return { dataLinesTemplate, dataPointsTemplate, labels };
};

export const getOptions = (totalData:any) => {
  return {
    responsive: true,
  //   maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
      },
      legend: {
        display: false,
      },
      datalabels: {
       align: "end",
       anchor: "end",
       color:  function(ctx:any) {
          if(ctx.dataset.type === 'bar'){
              return "#454545"
          }
          else{
              return 'white'
          }
        },
       font: { size: 16 },
        formatter: function (value: any, ctx: any) {
          if(ctx.dataset.type === 'bar'){
           return value > 0 && ctx.dataset.type === 'bar' ? value+ '%' : "";
          }
        },
        backgroundColor: function(ctx:any) {
          if(ctx.dataset.type === 'bar'){
              return 'transparent'
          }
          else{
              return '#46AFB8'
          }
        },
        borderRadius: 17,
        padding: { left: 6, right: 6, top: 4, bottom: 3 },
      },
    },
    scales: {
        y: {
          type: 'linear',
          display: true,
          position: 'left',
          max: (Math.max( ...totalData)) + (10 - (Math.max( ...totalData)) % 5),
          ticks:{
            minTicksLimit: 5,
            color: '#46AFB8',
          }
        },
        y1: {
          type: 'linear',
          display: true,
          position: 'right',
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
          ticks: {
            color: '#537AB7',
            callback: function(value:any) {
                return value + '%';
            },
         },
      }
    },
  };
}

export const getData = (data1: number[], data2: number[], labels: string[]) => {
  return {
    labels,
    datasets: [
      {
        type: "line",
        label: "Line Dataset",
        data: data2,
        borderColor: "#46AFB8",
        pointBackgroundColor: "#46AFB8",
        pointBorderColor: "#46AFB8",
        pointRadius: 3,
        yAxisID: 'y',
      },
      {
        type: "bar",
        label: "Bar Dataset",
        data: data1,
        backgroundColor: '#537AB7',
        yAxisID: 'y1',
      },
    ],
  };
};

export function SchoolProgressChart(props: any) {
  const { data } = props;
  let { dataLinesTemplate, dataPointsTemplate, labels } = getChartData(data);
  const finalData = getData(dataPointsTemplate, dataLinesTemplate, labels);
  const options = getOptions(dataLinesTemplate);

  return (
    <div>
      <div >
        <Bar options={options as any} data={finalData as any} className="report__printChart-chart"/>
      </div>
    </div>
  );
}
