import { FC } from "react";
import classNames from "util/classNames";
import checkIcon from "assets/img/checked-green-1x.svg";

interface Props {
  objective_number: string;
  objective_description: string;
  objective_description_es: any[];
  nine_week: any[];
  lang: string;
  rounded?: boolean;
}
const Record: FC<Props> = ({
  objective_number,
  objective_description,
  objective_description_es,
  nine_week,
  lang,
  rounded = false,
}) => {
  return (
    <tr className="based-report__detail-row">
      <td className="based-report__detail-objectiveData">{objective_number}</td>
      <td className="based-report__detail-descriptionData">
        {lang === "en" ? objective_description : objective_description_es}
      </td>
      <td className="based-report__detail-weeksData">
        {nine_week?.map((item, index) => {
          return (
            <div key={index} className="based-report__week">
              {item === "P" ? (
                <img width={20} src={checkIcon} alt="" />
              ) : item === "B" ? (
                <div
                  className={classNames(
                    rounded ? "level-box-radius" : "",
                    "based-report__week-data non-proficient"
                  )}
                />
              ) : (
                <div className="empty" />
              )}
            </div>
          );
        })}
      </td>
    </tr>
  );
};
export default Record;
