/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useState, useCallback, useEffect, useRef } from "react";
import SearchIcon from "assets/img/search-icon.svg";
import CommentModal from "components/classroom/modals/comment";
import Pagination from "../pagination/pagination";
import moment from "moment";
import _ from "lodash";
import { useContext } from "react";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import { EmptyRecordMasteryVideo } from "components/common/EmptyRecord";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { trimStringToMentionFormat } from "util/shared/TextManipulation";

interface Props {}

interface Reply {
  commentId: number;
  comment: string;
}

const CommentSection: FC<Props> = (props) => {
  const {
    updateSupportTicketComment,
    ticketDetails,
    fetchSupportTicketManagementComment,
    commentLoading,
    superAdmins,
    fetchSuperAdmin,
  } = useContext(SupportTicketSuperAdminContext);
  const { comments, comment_count, loading } = ticketDetails;
  const { super_admins } = superAdmins;

  const firstRender = useRef<boolean>(true);
  const [modalShow, setModalShow] = useState(false);
  const [reply, setReply] = useState<Reply>(null!);
  const [queryParams, setQueryParams] = useState({
    limit: 5,
    search: "",
    currentPage: 1,
  });
  const { limit } = queryParams;

  const replayHandler = (commentId: number, comment: string) => {
    setReply({
      commentId,
      comment,
    });
    setModalShow(true);
  };

  const commentHandler = () => {
    setModalShow(true);
  };

  const onPageChange = (nextPage: number) => {
    // setCurrentPage(nextPage);
    setQueryParams((prev: any) => ({ ...prev, currentPage: nextPage }));
  };

  const addComment = async (comment: string) => {
    const payload = {
      comment,
      ...(reply && { comment_id: reply.commentId }),
    };
    const isSuccess = await updateSupportTicketComment(payload);
    isSuccess && onClose();
  };

  const onClose = () => {
    setReply(null!);
    setModalShow(false);
  };

  const handleSearch = useCallback(
    _.debounce((val: any) => {
      setQueryParams((prev: any) => ({ ...prev, search: val, currentPage: 1 }));
    }, 1000),
    []
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const offset = (queryParams.currentPage - 1) * limit;
    fetchSupportTicketManagementComment({ ...queryParams, offset });
  }, [queryParams]);

  useEffect(() => {
    console.log(34345);
    fetchSuperAdmin("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAnyReply = comments?.some((item: any) => item.replies?.length > 0);

  return (
    <div>
      <div className="MasteryVideos__comment SupportTicketDetails__comment">
        <div className="SupportTicketDetails__header header">
          <h2 className="text-white">Alpha Plus Comments</h2>
          <div className="section-search">
            <button
              onClick={commentHandler}
              className="SupportTicketDetails__comment__button bg-transparent text-white outline-none"
            >
              New Comment
            </button>
            <div className="section-search-field animated mx-md-0 mx-3">
              <div className="icon">
                <img src={SearchIcon} alt="" />
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search Comments"
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="SupportTicketDetails__comment__container">
          <table className="SupportTicketDetails__comment__container__table">
            <tbody className="SupportTicketDetails__comment__tablebody">
              {loading || commentLoading ? (
                <TableSkeleton columns={1} count={5} height={45} />
              ) : comments.length > 0 ? (
                comments.map((data) => {
                  const { replies } = data;

                  const username = data.comment.match(/\[[^\]]*]/g);
                  let commentToDisplay =
                    data.comment.trim().length > 0 && username !== null
                      ? trimStringToMentionFormat(data.comment, username)
                      : data.comment;

                  return (
                    <>
                      <tr key={data.id}>
                        <td className="SupportTicketDetails__comment__container__table__name name">
                          <div
                            style={{
                              backgroundColor: data.color_hex,
                              fontSize: "20px",
                            }}
                            className="text-center d-flex align-items-center justify-content-center"
                          >
                            <p>{data.name_initials}</p>
                          </div>
                        </td>
                        <td className="SupportTicketDetails__comment__container__table__info info">
                          <div
                            className={`${
                              isAnyReply ? "comment_with_replies" : ""
                            }`}
                          >
                            <div className="d-flex align-items-center basic-info flex-wrap">
                              <p className="me-3 color-white fw-bold">
                                {data.name}
                              </p>
                              {/* <p className="me-3 color-white">{data.date}</p> */}
                              <p className="me-3 color-white">
                                {moment(data.date).fromNow(true)}
                              </p>
                              <p
                                className="me-3 color-white fw-bold cursor-pointer"
                                onClick={() =>
                                  replayHandler(data.id, data.comment)
                                }
                              >
                                Reply
                              </p>
                            </div>
                            <div>
                              <p className="comment">
                                {/* {data.comment} */}
                                {commentToDisplay}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {replies?.length
                        ? replies.map((data: any) => {
                            const username = data.comment.match(/\[[^\]]*]/g);
                            let replyToDisplay =
                              data.comment.trim().length > 0 &&
                              username !== null
                                ? trimStringToMentionFormat(
                                    data.comment,
                                    username
                                  )
                                : data.comment;
                            return (
                              <tr>
                                <td className="SupportTicketDetails__comment__container__table__name d-flex">
                                  <div className="text-center d-flex align-items-center justify-content-center replayTd ">
                                    <p className="emptySpace"></p>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: data.color_hex,
                                      fontSize: "20px",
                                    }}
                                    className="text-center d-flex align-items-center justify-content-center"
                                  >
                                    <p>{data.name_initials}</p>
                                  </div>
                                </td>
                                <td className="SupportTicketDetails__comment__container__table__info">
                                  <div className="d-flex align-items-center basic-info flex-wrap">
                                    <p className="me-3 fw-bold">{data.name}</p>
                                    <p className="me-3 basic-info-color">
                                      {data.place}
                                    </p>
                                    <p className="me-3 basic-info-color">
                                      {moment(data.time).fromNow(true)}
                                    </p>
                                    <p
                                      className="me-3 basic-info-color fw-bold cursor-pointer"
                                      onClick={() =>
                                        replayHandler(data.id, data.comment)
                                      }
                                    >
                                      Reply
                                    </p>
                                  </div>
                                  <div>
                                    <p className="comment">
                                      {/* {data.comment} */}
                                      {replyToDisplay}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </>
                  );
                })
              ) : (
                <EmptyRecordMasteryVideo message="No Comments Yet" />
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        totalCount={comment_count}
        currentPage={queryParams.currentPage}
        pageSize={limit}
        onPageChange={onPageChange}
      />

      {modalShow && (
        <CommentModal
          show={modalShow}
          reply={reply?.comment}
          onSave={addComment}
          onClose={onClose}
          submitButtonName="Post"
          super_admins={super_admins}
        />
      )}
    </div>
  );
};
export default CommentSection;
