import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LimitSelect } from "components/common/selectComponent";
import SearchIcon from "assets/img/search-icon.svg";
import { AssigneeDropdown, StatusFilter } from "./dropdowns";
import Pagination from "components/pagination/pagination";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import _ from "lodash";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import { Collapse } from "react-bootstrap";
import EmptyRecord from "components/common/EmptyRecord";
import {
  ChatSessionsStatusMapper,
  getChatSessionsStatusClass,
} from "util/mappers";
import moment from "moment";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { useSearchParams, useNavigate } from "react-router-dom";
import ArchivedChat from "components/common/archivedChat";
import { getSuperAdminChatById, putSuperAdminChat } from "services/supportTicketSuperAdmin";

import useDidMountEffect from "hooks/useDidMountEffect";

import ArrowDownImg from "assets/img/drop-black-icon-down.svg";
import { GetTicketManagement, STATUS, TYPE } from "interfaces/supportTicketSuperAdmin";
import ToolTip from "components/common/Tooltip";

const initialDefaultSelected = { value: "", label: "All" };

interface IProps {
  refreshTable: Boolean;
  refreshTableOnClick: Boolean;
}

const ChatSessionTable = (props: IProps) => {
  const {
    supportChat,
    fetchSupportChat,
    fetchSupportChatWithoutPageLoad,
    superAdmins,
    setSupportChat
  } = useContext(SupportTicketSuperAdminContext);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const from = useRef<any>(searchParams.get("from"));

  const [chatById, setChatById] = useState<any>(null!);
  const [selectedAssignee] = useState<any>(
    searchParams.get("assigned_to") || ""
  );
  const [defaultSelected, setDefaultSelected] = useState(
    initialDefaultSelected
  );

  const { super_admins } = superAdmins;

  const [chatQueryParams, setChatQueryParams] = useState<any>(null!);
  const [chatByIdLoading, setChatByIdLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState<any>(null!);
  const [open, setOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("last_updated");
  const [OrderByColumn, setOrderBy] = useState("DESC");
  const [supportTicketQueryParams, setSupportTicketQueryParams] =
    useState<GetTicketManagement>({
      limit: 10,
      offset: 0,
      search: "",
      assigneeId: 0,
      status: "",
      sort_by: sortBy,
      order_by: OrderByColumn,
      ...(from.current && { admin_created_id: Number(from.current) }),
    });
  const [search, setSearch] = useState("");

  const { limit } = supportTicketQueryParams;
  const { count, chats, loading } = supportChat;

  const formattedChats = useMemo(() => {
    return chats.map((chat) => {
      const { status, created_at, last_reply } = chat;
      const formattedStatus =
        ChatSessionsStatusMapper[
          status as keyof typeof ChatSessionsStatusMapper
        ] || status;
      const formattedDate = moment(created_at).format("M/D/YY h:mm A");
      const formattedLastReply = last_reply
        ? moment(last_reply).format("M/D/YY h:mm A")
        : "";
      const statusClass = getChatSessionsStatusClass(status);
      return {
        ...chat,
        formattedStatus,
        formattedDate,
        statusClass,
        formattedLastReply,
      };
    });
  }, [chats]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleSearch = useCallback(
    _.debounce((value) => {
      setSupportTicketQueryParams((prev) => ({
        ...prev,
        search: value,
        offset: 0,
      }));
    }, 1000),
    []
  );

  const onChangePage = (page: number) => {
    setCurrentPage(page);
    setSupportTicketQueryParams((prev) => ({
      ...prev,
      offset: page * prev.limit - prev.limit,
    }));
  };

  const onChangeLimit = (value: number) => {
    setCurrentPage(1);
    setSupportTicketQueryParams((prev) => ({
      ...prev,
      limit: value,
      offset: 0,
    }));
  };

  const onResolveChat = async (chatId: number) => {
    const updatedChats = chats.map((item: any) => {
      if(item.id === chatId){
        return {
          ...item,
          status: STATUS.RESOLVED
        }
      }

      return item;
    });
    setSupportChat({...supportChat, chats: updatedChats});
    await putSuperAdminChat({
      chatId: chatId,
      type: TYPE.STATUS,
      status: STATUS.RESOLVED
    });
  }

  const onChangeStatus = (data: { value: string; label: string }) => {
    const { value } = data;
    setCurrentPage(1);
    setSupportTicketQueryParams((prev) => ({
      ...prev,
      status: value,
      offset: 0,
    }));
  };

  const onChangeAssignee = (data: any) => {
    const { value } = data;
    setCurrentPage(1);
    setSupportTicketQueryParams((prev) => ({
      ...prev,
      assigneeId: value,
      offset: 0,
    }));
  };

  useEffect(() => {
    setOpen(true);
    fetchSupportChat(supportTicketQueryParams);
  }, [supportTicketQueryParams, props.refreshTableOnClick]);

  // useDidMountEffect(() => {
  //     fetchSupportChat(supportTicketQueryParams);
  // }, [supportTicketQueryParams, props.refreshTableOnClick]);

  // useEffect(() => {
  //     fetchSupportChatWithoutPageLoad(supportTicketQueryParams);
  // }, [props.refreshTable]);

  useDidMountEffect(() => {
    fetchSupportChatWithoutPageLoad(supportTicketQueryParams);
  }, [props.refreshTable]);

  // useEffect(() => {
  //     setCurrentPage(1)
  //     setSupportTicketQueryParams(
  //         prev => (
  //             {
  //                 ...prev,
  //                 sort_by: sortBy,
  //                 order_by: OrderByColumn,
  //                 offset: 0
  //             }
  //         )
  //     );

  //     /* eslint-disable react-hooks/exhaustive-deps */
  // }, [sortBy, OrderByColumn]);

  useDidMountEffect(() => {
    // react please run me if 'key' changes, but not on initial render
    setCurrentPage(1);
    setSupportTicketQueryParams((prev) => ({
      ...prev,
      sort_by: sortBy,
      order_by: OrderByColumn,
      offset: 0,
    }));
  }, [sortBy, OrderByColumn]);

  //  useEffect(() => {
  //     fetchSupportChat(supportTicketQueryParams);
  // }, [props.refreshTableOnClick]);

  const onClickChat = async (e: any, id: number) => {
    e.preventDefault();
    setChatById(id);
    setChatByIdLoading(true);
    document.getElementById("chat-session-cont")?.scrollIntoView();

    setChatQueryParams({
      limit: 50,
      offset: 0,
    });
  };

  const navigateToDetailsPage = (ticket_id: number) => {
    navigate(`/super-admin/ticket-details/${ticket_id}`);
  };

  // useEffect(() => {

  //  if(selectedAssignee !== undefined) {

  // const assigneeToSearch: any = super_admins.filter((admin) => {
  //     return admin.label.toLowerCase() === selectedAssignee.toLowerCase();
  // })

  // const searchedAssigneeFromChatHistory: any = assigneeToSearch[0];
  // const value = searchedAssigneeFromChatHistory?.id || initialDefaultSelected.value;
  // const label = searchedAssigneeFromChatHistory?.label || initialDefaultSelected.label;

  // setDefaultSelected({
  //     value,
  //     label
  // });

  // onChangeAssignee({
  //     value
  // });

  // setSearch(selectedAssignee);
  // handleSearch(selectedAssignee);

  //     }

  // }, [selectedAssignee]);

  useEffect(() => {
    if (super_admins.length > 0) {
      const selectedAdmin: any = super_admins.filter((admin) => {
        return admin.first_name + " " + admin.last_name === selectedAssignee;
      });

      if (selectedAdmin.length) {
        setDefaultSelected({
          label: selectedAdmin[0].first_name + " " + selectedAdmin[0].last_name,
          value: selectedAdmin[0].id,
        });

        onChangeAssignee({ value: selectedAdmin[0].id });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssignee, super_admins]);

  useEffect(() => {
    if (chatQueryParams && chatById) {
      const fetchChat = async (chatById: any) => {
        const chat = await getSuperAdminChatById({
          ...chatQueryParams,
          chatId: chatById,
        });
        if (chat) {
          if (chatQueryParams.offset === 0) {
            setSelectedChat(chat);
          } else {
            setSelectedChat((prevChat: any) => ({
              ...prevChat,
              messages: [...prevChat.messages, ...chat.messages],
            }));
          }
        }
        setChatByIdLoading(false);
      };
      fetchChat(chatById);
    }
  }, [chatQueryParams, chatById]);

  const hideFilter = () => {
    setCurrentPage(1);
    setSupportTicketQueryParams((prev) => {
      const { admin_created_id, ...otherData } = prev;
      return {
        ...otherData,
        offset: 0,
      };
    });
  };

  const selectedChatSession = formattedChats.find(
    (item) => item.id === chatById
  );

  return (
    <>
      <div className="SupportTicket mt-3 chatSessionTable">
        <div
          className={`SupportCenter__Header__titleRow d-flex align-items-center justify-content-between SupportTicket__header ${
            !open ? "borderRadius" : ""
          }`}
        >
          <div className="d-flex align-items-center  m-lg-0 m-2">
            <div
              onClick={(e) => {
                setOpen(!open);
              }}
              className={`${open ? "open" : ""}`}
            >
              <div className="accordion__header-heading">
                <span className="accordion__header-button">
                  <img
                    src={ArrowIcon}
                    alt="arrow"
                    className={`${open ? "rotate" : ""}`}
                  />
                </span>
              </div>
            </div>
            <h2 className="title ">Chat Sessions</h2>
          </div>
          <div className="d-flex align-items-center m-lg-0 m-2">
            <p className="mb-0">Show</p>
            <LimitSelect
              disableHandler={count <= 10}
              value={limit}
              onChangeHandler={(e: any) => onChangeLimit(Number(e?.value))}
            />
          </div>
          <div className="d-flex align-items-center  m-lg-0 m-2 SupportCenter__Header__Assigneedropdown">
            <span className="text-white">Respondent</span>
            <AssigneeDropdown
              options={super_admins}
              defaultSelected={defaultSelected}
              onChangeHandler={onChangeAssignee}
            />
          </div>
          <div className="d-flex align-items-center  m-lg-0 m-2 SupportCenter__Header__Statusdropdown">
            <span className="text-white">Status</span>
            <StatusFilter
              onChangeHandler={onChangeStatus}
              options={[
                { value: "", label: "All" },
                { value: "RESOLVED", label: "Resolved" },
                { value: "IN_PROGRESS", label: "In Progress" },
                { value: "TICKET_CREATED", label: "Ticket Created" },
                { value: "NEEDS_RESPONSE", label: "Needs Response" },
                { value: "EXPIRED", label: "Expired" },
              ]}
            />
          </div>
          <div className="section-search-field  m-lg-0 m-2">
            <div className="icon">
              <img src={SearchIcon} alt="search-icon" />
            </div>
            {!!supportTicketQueryParams.admin_created_id && !loading ? (
              <div
                className="text-white"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "14px",
                  width: "185px",
                  alignItems: "center",
                  height: "21px",
                  lineHeight: "21px",
                  paddingTop: "4px",
                  paddingLeft: "5px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-3px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "2.5px",
                      height: "19px",
                    }}
                  >
                    Filter:
                  </span>
                  {!!formattedChats && formattedChats?.length > 0 && (
                    <ToolTip
                      styles={{ height: "19px" }}
                      content={formattedChats[0]?.name || ""}
                    >
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "80px",
                          display: "inline-block",
                          margin: 0,
                          whiteSpace: "nowrap",
                          height: "19px",
                          position: "relative",
                          top: "3px",
                        }}
                      >
                        {formattedChats[0]?.name || ""}
                      </span>
                    </ToolTip>
                  )}
                </span>

                <span
                  onClick={hideFilter}
                  className="cursor-pointer"
                  style={{
                    marginLeft: "8px",
                    marginTop: "-4.5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                  >
                    <g
                      id="remove_attachment_icon"
                      data-name="remove attachment icon"
                      transform="translate(-1121 -639)"
                    >
                      <g
                        id="close_field_icon"
                        data-name="close field icon"
                        transform="translate(645 89)"
                        opacity="0.375"
                      >
                        <g
                          id="Ellipse_41"
                          data-name="Ellipse 41"
                          transform="translate(477 551)"
                          stroke="#000"
                          strokeWidth="1"
                        >
                          <circle cx="9" cy="9" r="9" stroke="none" />
                          <circle cx="9" cy="9" r="8.5" fill="none" />
                        </g>
                        <line
                          id="Line_45"
                          data-name="Line 45"
                          x2="7"
                          y2="7"
                          transform="translate(482.5 556.5)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_46"
                          data-name="Line 46"
                          y1="7"
                          x2="7"
                          transform="translate(482.5 556.5)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="2"
                        />
                      </g>
                      <g
                        id="close_field_icon-2"
                        data-name="close field icon"
                        transform="translate(644 88)"
                      >
                        <circle
                          id="Ellipse_41-2"
                          data-name="Ellipse 41"
                          cx="9"
                          cy="9"
                          r="9"
                          transform="translate(477 551)"
                          fill="#c44538"
                        />
                        <line
                          id="Line_45-2"
                          data-name="Line 45"
                          x2="7"
                          y2="7"
                          transform="translate(482.5 556.5)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_46-2"
                          data-name="Line 46"
                          y1="7"
                          x2="7"
                          transform="translate(482.5 556.5)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
            ) : (
              <input
                value={search}
                type="text"
                className="form-control"
                placeholder="Search Chats"
                id="search-input-formative"
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearch(e.target.value);
                }}
              />
            )}
          </div>
        </div>
        <div className="overflow-auto">
          <Collapse in={open}>
            <div>
              <table className="SupportTicket__table ">
                <thead className="SupportTicket__table__thead">
                  <tr>
                    <td className="SupportTicketCircle"></td>
                    <td className=" admin">Admin</td>
                    <td className="districs">School or District</td>
                    <td
                      className="createdTime cursor-pointer"
                      onClick={() => {
                        setSortBy("last_updated");
                        if (
                          OrderByColumn === "DESC" &&
                          sortBy === "last_updated"
                        ) {
                          setOrderBy("ASC");
                        } else if (
                          OrderByColumn === "ASC" &&
                          sortBy === "last_updated"
                        ) {
                          setOrderBy("DESC");
                        } else {
                          setOrderBy("DESC");
                        }
                      }}
                    >
                      Last Reply
                      {sortBy === "last_updated" && (
                        <img
                          src={ArrowDownImg}
                          alt="sort icon"
                          className={`${
                            OrderByColumn === "ASC"
                              ? "arrow-up-classname"
                              : "arrow-down-classname"
                          }`}
                        />
                      )}
                    </td>
                    <td
                      className="SupportTicketstatus cursor-pointer"
                      onClick={() => {
                        setSortBy("createdAt");
                        if (
                          OrderByColumn === "DESC" &&
                          sortBy === "createdAt"
                        ) {
                          setOrderBy("ASC");
                        } else if (
                          OrderByColumn === "ASC" &&
                          sortBy === "createdAt"
                        ) {
                          setOrderBy("DESC");
                        } else {
                          setOrderBy("DESC");
                        }
                      }}
                    >
                      Created
                      {sortBy === "createdAt" && (
                        <img
                          src={ArrowDownImg}
                          alt="sort icon"
                          className={`${
                            OrderByColumn === "ASC"
                              ? "arrow-up-classname"
                              : "arrow-down-classname"
                          }`}
                        />
                      )}
                    </td>
                    <td>Status</td>
                    <td>Respondent</td>
                  </tr>
                </thead>
                <tbody className="SupportTicket__table__tbody">
                  {loading ? (
                    <TableSkeleton
                      columns={7}
                      count={limit}
                      height={36}
                      firstColumnWidth={45}
                    />
                  ) : (
                    <>
                      {formattedChats.length > 0 ? (
                        formattedChats.map((chat: any) => {
                          const {
                            formattedLastReply,
                            respondent,
                            unread_messages,
                            name,
                            formattedStatus,
                            formattedDate,
                            statusClass,
                            school_district,
                            ticket_id,
                          } = chat;
                          return (
                            <tr
                              key={chat.id}
                              onClick={(e) =>
                                formattedStatus ===
                                ChatSessionsStatusMapper.TICKET_CREATED
                                  ? {}
                                  : onClickChat(e, chat.id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <td
                                className=""
                                style={{ width: "45px" }}
                                onClick={(e) =>
                                  formattedStatus !==
                                  ChatSessionsStatusMapper.TICKET_CREATED
                                    ? {}
                                    : onClickChat(e, chat.id)
                                }
                              >
                                {unread_messages ? (
                                  <p className="redCircle"></p>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td
                                className="fw-bold summary"
                                onClick={(e) =>
                                  formattedStatus !==
                                  ChatSessionsStatusMapper.TICKET_CREATED
                                    ? {}
                                    : onClickChat(e, chat.id)
                                }
                              >
                                {name}
                              </td>
                              <td
                                onClick={(e) =>
                                  formattedStatus !==
                                  ChatSessionsStatusMapper.TICKET_CREATED
                                    ? {}
                                    : onClickChat(e, chat.id)
                                }
                              >
                                {school_district}
                              </td>
                              <td
                                style={{ paddingLeft: "4px" }}
                                onClick={(e) =>
                                  formattedStatus !==
                                  ChatSessionsStatusMapper.TICKET_CREATED
                                    ? {}
                                    : onClickChat(e, chat.id)
                                }
                              >
                                {!formattedLastReply ? (
                                  formattedLastReply
                                ) : (
                                  <>
                                    <span
                                      style={{ width: "73px" }}
                                      className="text-right d-inline-block me-1"
                                    >
                                      {moment(formattedLastReply).format(
                                        "M-D-YY "
                                      )}
                                    </span>

                                    <span
                                      style={{ width: "72px" }}
                                      className="text-right d-inline-block"
                                    >
                                      {moment(formattedLastReply).format(
                                        "h:mm A"
                                      )}
                                    </span>
                                  </>
                                )}
                              </td>

                              <td
                                style={{ paddingLeft: "4px" }}
                                className="text-nowrap"
                                onClick={(e) =>
                                  formattedStatus !==
                                  ChatSessionsStatusMapper.TICKET_CREATED
                                    ? {}
                                    : onClickChat(e, chat.id)
                                }
                              >
                                {
                                  <>
                                    <span
                                      style={{ width: "73px" }}
                                      className="text-right d-inline-block me-1"
                                    >
                                      {moment(formattedDate).format("M-D-YY ")}
                                    </span>

                                    <span
                                      style={{ width: "72px" }}
                                      className="text-right d-inline-block"
                                    >
                                      {moment(formattedDate).format("h:mm A")}
                                    </span>
                                  </>
                                }
                              </td>

                              <td onClick={e => e.stopPropagation()} className="SupportTicket__table__tbody__status">
                                {formattedStatus === ChatSessionsStatusMapper.EXPIRED ? (
                                  <AssigneeDropdown
                                    allOptions={[{label: ChatSessionsStatusMapper.RESOLVED, value: ChatSessionsStatusMapper.RESOLVED}]}
                                    placeholder={formattedStatus}
                                    onChangeHandler={() => onResolveChat(chat.id)}
                                    customClass="dropdown"
                                    excludeDefault
                                  />
                                ):(
                                  <p
                                    className={statusClass}
                                    onClick={() =>
                                      formattedStatus ===
                                      ChatSessionsStatusMapper.TICKET_CREATED
                                        ? navigateToDetailsPage(ticket_id)
                                        : {}
                                    }
                                  >
                                    {formattedStatus}
                                  </p>
                                )}
                              </td>
                              <td
                                onClick={(e) =>
                                  formattedStatus !==
                                  ChatSessionsStatusMapper.TICKET_CREATED
                                    ? {}
                                    : onClickChat(e, chat.id)
                                }
                              >
                                {respondent}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <EmptyRecord message="No Chats" />
                      )}
                    </>
                  )}
                </tbody>
              </table>
              <Pagination
                totalCount={count}
                currentPage={currentPage}
                pageSize={limit}
                onPageChange={onChangePage}
              />
            </div>
          </Collapse>
        </div>
      </div>
      {chatById && (
        <ArchivedChat
          chat={{
            ...selectedChat,
            date_created: selectedChatSession?.created_at || "",
          }}
          loading={chatByIdLoading}
          onClose={() => {
            setChatById(null!);
            setChatQueryParams(null!);
          }}
          setChatQueryParams={setChatQueryParams}
        />
      )}
    </>
  );
};

export default ChatSessionTable;
