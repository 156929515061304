import { FC } from "react";

export const InfoSection: FC = () => {
  return (
    <div className="based-report__info-wrapper">
      <p className="based-report__info-text">
        The Standard Based Report details student performance on OAS objectives,
        determined by their score on the formative assessments aligned with
        those objectives. Select the English or Spanish report for a student
        below to view the student’s performance levels and periods in which the
        objectives were assessed.
      </p>
    </div>
  );
};

export const DetailsInfoSection = () => {
  return (
    <div className="report__performance-performance based-info">
      <p className="report__performance-title">
        Performance Level (Percentage Score Range)
      </p>
      <div className="report__performance-percentage">
        <div className="report__performance-row">
          <div className="d-flex align-items-center">
            <div className="box proficient"></div>
            <p>Proficient</p>
          </div>
          <p>( 70% - 100%)</p>
        </div>
        <div className="report__performance-row">
          <div className="d-flex align-items-center">
            <div className="box non-proficient"></div>
            <p>Non-Proficient</p>
          </div>
          <p>( 0% - 69%)</p>
        </div>
      </div>
    </div>
  );
};
