import { useState } from "react";

const useSortBy = (sort: string, OrderByColumn: string)=>{
const [sortBy, setSortBy] = useState(sort);
const [orderBy, setOrderBy] = useState(OrderByColumn);

    const sortDataBy = (sortString: string) => {
        setSortBy(sortString);
        if (
            orderBy === "DESC" &&
            sortBy === sortString
        ) {
            setOrderBy("ASC");
        } else if (
            orderBy === "ASC" &&
            sortBy === sortString
        ) {
            setOrderBy("DESC");
        } else {
            setOrderBy("ASC");
        }
    }

    return {sortBy, OrderByColumn:orderBy, sortDataBy}

}

export default useSortBy;