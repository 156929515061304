import ButtonDropDown from "components/common/buttonDropDown";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import {
  Assessment,
  ReportsNavigation,
} from "interfaces/professionalDevelopment";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downLoadClassroomStlReport } from "redux/actionCreators/management";
import ActionType from "redux/actionTypes";
import { RootState } from "redux/reducers/combine";

const Reports = () => {
  const dispatch = useDispatch();
  const { classroomId, navigateTo, prevSTLClassroomExists, formatives, summatives } =
    useContext(ProfessionalDevelopmentContext);

  const { auth, classroom } = useSelector((state: RootState) => state);

  const classInfo = classroom?.classrooms.length
    ? classroom.classrooms[0]
    : null;
  const isOnlyResourcesClass =
    classInfo?.students_count === 0 || classInfo?.fk_grade_id === 9;

  const downloadSTL = async (previousYear = false) => {
    await downLoadClassroomStlReport(classroomId, previousYear);
    // dispatch({
    //   type: ActionType.SET_STL_SCHOOL_LOADING,
    //   payload: true,
    // });
  };

  const requestStl = () => {
    dispatch({
      type: ActionType.SET_STL_CLASS_LOADING,
      payload: true,
    });
  };

  const isAllNotSubmitted = formatives
    ?.map((x: any) => !!parseInt(x.students_assessed.split("/")[0]))
    .some((item: any) => item === true);
  const enableReportButtons = classroomId && isAllNotSubmitted;

  const isAllSummativesNotSubmitted = summatives
    ?.map((x: any) => !!parseInt(x.students_assessed.split("/")[0]))
    .some((item: any) => item === true);  
  const enableMasterReportButtons = classroomId && isAllSummativesNotSubmitted;



  return (
    <div className="reports-global">
      <h3 className="secHeading">Global Reports</h3>
      <div className="reports-buttons">


      <div className="d-flex flex-wrap reports-buttons__groupStyle" style={{gap: "11px"}}>
        <button
          style={{...(!enableMasterReportButtons && { opacity: "0.56" }) }}
          type="button"
          tabIndex={0}
          className={`report-button ${
            enableMasterReportButtons ? "cursor-pointer" : "cursor-default"
          }`}
          onClick={() =>
            navigateTo(Assessment.summative, ReportsNavigation.MASTER, {
              enableMasterReportButtons,
            })
          }
        >
          Master Report
        </button>
        {auth?.currentUser?.role === "Classroom Admin" &&
          (classroom?.classrooms?.length === 1 ||
            classroom?.classrooms?.length > 1) &&
          !isOnlyResourcesClass && (
            <button
              style={{
                opacity: !classroomId ? 0.5 : 1,
                cursor: !classroomId ? "default" : "pointer",
              }}
              className="report-button"
              tabIndex={0}
              type="button"
              onClick={() => classroomId > 0 && requestStl()}
            >
              Summative Test Log
            </button>
          )}
          {auth?.currentUser?.role !== "Classroom Admin" && (
            <>
              {prevSTLClassroomExists ? (
                <ButtonDropDown
                  options={[
                    {
                      label: "Summative Test Log",
                      value: "Current",
                    },
                    {
                      label: "2023-24",
                      value: "2023-24",
                    },
                  ]}
                  onChange={(selectedValue: any) => {
                    console.log("selectedValue", selectedValue);
                    dispatch({
                      type: ActionType.SET_SCHOOL_STL_PERIOD,
                      payload: selectedValue,
                    });

                    downloadSTL(selectedValue === "2023-24" ? true : false);
                  }}
                  biggerWidth={true}
                />
              ) : (
                <div
                  style={{
                    opacity: !classroomId ? 0.5 : 1,
                    cursor: !classroomId ? "default" : "pointer",
                  }}
                  className={`report-button`}
                  role="button"
                  tabIndex={0}
                  onClick={() => classroomId > 0 && downloadSTL(false)}
                >
                  Summative Test Log
                </div>
              )}
            </>
          )}
        </div>
        <button
          type="button"
          tabIndex={0}
          style={{ ...(!enableReportButtons && { opacity: "0.56" }) }}
          onClick={() =>
            navigateTo(Assessment.formative, ReportsNavigation.OAG, {
              enableReportButtons: !!enableReportButtons,
            })
          }
          className={`report-button ${
            enableReportButtons ? "cursor-pointer" : "cursor-default"
          }`}
        >
          Objective Accountability Graph
        </button>
      </div>
    </div>
  );
};

export default Reports;
