/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback, useRef } from "react";
import cryptoRandomString from "crypto-random-string";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { Alert } from "react-bootstrap";
import EditIcon from "assets/img/pencil-icon.svg";
import DeleteIcon from "assets/img/trash-icon.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import RPIcon from "assets/img/cross-icon.svg";
import SearchIcon from "assets/img/search-icon.svg";
import ArrowDownImg from "assets/img/table-class-before.svg";
import Pagination from "../pagination/pagination";
import { DistrictAdminDeleteModal } from "./management-delete-modal";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { LimitSelect } from "components/common/selectComponent";
import {
  DistrictAdminUpdateModal,
  DistrictLogoUpdateModal,
} from "./management-update-modal";
import {
  DistrictAdminAddModal,
  ResetPasswordModal,
} from "./management-add-modal";
import { useDispatch, useSelector } from "react-redux";
import { removeDistrictLogo, uploadDistrictLogo } from "services/upload-file";
import {
  getDistrictAdmins,
  deleteDistrictAdmins,
  updateDistrictAdmins,
  addDistrictAdmin,
  resetPasswordAdmin,
  setFlagForFetch,
  updateIsSuperIntendentFlag,
  sendWelcomeEmails,
  deleteDistrictAdminFromDistrict
} from "../../redux/actionCreators/management";
import SendEmailConfirmationModal from "./modals/sendEmailConfirmModal";
import PermissionHintModal from "./modals/permissionHintModal";
import { setDistrictLogo } from "redux/actionCreators/management";
import BackButton from "components/common/backButton";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import useSortBy from "hooks/useSortBy";
import Toggle from "components/common/Toggle";

interface RootState {
  management: any;
  auth: any;
}
interface DistrictAdminDetailProps {
  districtId: string;
}

const DistrictAdminDetail: React.FC<DistrictAdminDetailProps> = ({
  districtId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { management, auth } = useSelector((state: RootState) => state);
  const { id }: any = useParams();
  const location:any = useLocation();
  const districtName  = location.state?.name || ""
  const inputRef = useRef<HTMLInputElement>(null);
  const [logoLoading, setLogoLoading] = useState(false);
  const [logoSuccess, setLogoSuccess] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateLogoModal, setUpdateLogoModal] = useState(false);
  const [resetPassModdal, setResetPassModdal] = useState(false);
  const [randomPass, setRandomPass] = useState("");
  const [skeletonLoad, setSkeletonLoad] = useState(true);

  const {sortBy, OrderByColumn, sortDataBy} = useSortBy("last_name", "ASC");

 
  const [lastAddedId, setLastAddedId] = useState(0);
  const [emailConfirmMessage, setEmailConfirmMessage] = useState('');
  const [emailConfirmModal, setEmailConfirmModal] = useState(false);
  const [openHintModal, setHintModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const district_name = searchParams.get("district_name");
  const [isInputFocused, setIsInputFocused] = useState<any>("");
  console.log('district_name', district_name);


  useEffect(() => {
    dispatch(getDistrictAdmins(offSet, limit, searchTerm, districtId, sortBy, OrderByColumn));
    
  }, [offSet, limit, management.districtAdminsFlag, dispatch, sortBy, OrderByColumn,districtId]);

  const handleSearch = useCallback(
    _.debounce((val: any) => {
      console.log(val);
      setCurrentPage(1)
      setOffSet(0)
      dispatch(getDistrictAdmins(0, limit, val, districtId, sortBy, OrderByColumn));
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    /* Fetch page number for last added record*/
    if (management.lastAddedDistrictAdminId > 0) {
      setLastAddedId(management.lastAddedDistrictAdminId)
      setSearchTerm('')
      dispatch(getDistrictAdmins(0, 10, '', districtId, sortBy, OrderByColumn, management.lastAddedDistrictAdminId));
    }
    /* Set page and fetch record of that page */
    if (management.districtAdminPageNumber > 0) {
      setLimit(10)
      setCurrentPage(management.districtAdminPageNumber)
      setOffSet((management.districtAdminPageNumber * 10) - 10)
      management.districtAdminPageNumber === currentPage && dispatch(setFlagForFetch('SET_DISTRICT_ADMIN_FLAG'))
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedDistrictAdminId, management.districtAdminPageNumber]);

  useEffect(() => {
    if (lastAddedId > 0 && !skeletonLoad) {
      const element = document.getElementById('district-admin-data')
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [management.districtAdmins, skeletonLoad]);

  const doChangePage = (pageNo: number) => {
    let newOffset = (pageNo * limit) - limit
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };

  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const doSetUpdateModal = (item: any) => {
    console.log(item, ".................");
    setCurrentItem(item);
    setUpdateModal(true);
  };

  const setPreviousPage = () => {
    if (currentPage > 1 && management.districtAdmins.length === 1) {
      doChangePage(currentPage - 1)
    }
    else {
      dispatch(setFlagForFetch('SET_DISTRICT_ADMIN_FLAG'))
    }
  }

  const onDeleteDistrictAdmin = () => {
    dispatch(deleteDistrictAdminFromDistrict(currentItem.id, parseInt(districtId), setDeleteModal, setPreviousPage));
  };

  const onUpdateDistrictAdmin = (data: any) => {
    dispatch(updateDistrictAdmins(currentItem.id, data, setUpdateModal));
  };

  const onAddDistrictAdmin = (data: any) => {
    let finalData = { ...data, district_id: Number(districtId) };
    dispatch(addDistrictAdmin(finalData, setAddModal));
  };

  const onUpdateLogo = async (file: any) => {
    let data = {
      file: file,
      id: districtId,
    };
    setLogoLoading(true);
    try {
      const response = await uploadDistrictLogo(data);
      dispatch(setDistrictLogo(response?.data?.url))
      setLogoLoading(false);
      setUpdateLogoModal(false);
      response && setLogoSuccess(true);
    } catch (e) {
      setLogoLoading(false);
      console.log(e);
    }
  };

  const onRemoveLogo = async () => {
    try {
      const response = await removeDistrictLogo(districtId);
      setLogoLoading(false);
      if (response) {
        dispatch(setDistrictLogo(null))
      }
    } catch (e) {
      setLogoLoading(false);
      console.log(e);
    }
  }

  const doSetPasModal = (item: any) => {
    setCurrentItem(item);
    let newPass = cryptoRandomString({ length: 10, type: "url-safe" });
    setRandomPass(newPass);
    setResetPassModdal(true);
  };
  const onCreatePassword = () => {
    let data = {
      admin_id: currentItem?.id,
      password: randomPass,
    };
    console.log(currentItem);
    dispatch(resetPasswordAdmin(data, setResetPassModdal));
    // setCurrentItem(null)
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSkeletonLoad(false);
    }, 800);

    return () => {
      clearTimeout(timer);
      setSkeletonLoad(true);
    }
  }, [management.districtAdminsLoading]);

  const sendEmail = async (send: boolean) => {
    const res = await sendWelcomeEmails('districts', id, send)
    setEmailConfirmMessage(send ? '' : res.message)
    setEmailConfirmModal(!send)
  }


  return (
    <>
      {logoSuccess ? (
        <Alert variant={"success"} dismissible onClose={() => setLogoSuccess(false)}>
          <div className="success_container d-flex align-items-center justify-content-between">
            <p className="text-dark mb-0"> Logo Updated Successfully</p>
          </div>
        </Alert>
      ) : (
        ""
      )}
      <div className="accordion__header open cursor-default management-internal-table-header">
        <div className="accordion__header-heading max-width">
          {auth.currentUser?.role === 'Super Admin' &&
            <BackButton 
              onClick={() => navigate('/management')}
            />
          }
          <h6 className="district-name-heading">District: {districtName || district_name}</h6>
        </div>
        <div>
          <div className="accordion__header-buttons district-admin">
            <div className="accordion__header-btnContainer mb-2 mb-xl-0">
              <button
                onClick={() =>
                  navigate("/management/import/clever", {
                    state: { districtData: {district_id: Number(districtId),district_name: districtName} },
                  })
                }
                className="btn outline-btn outline-btn-lg"
              >
                Import Clever CSV
              </button>
            </div>
            {auth.currentUser?.role === 'Super Admin' &&
              <div className="accordion__header-btnContainer mb-2 mb-xl-0" >
                <button onClick={() => sendEmail(false)}
                  className="btn outline-btn outline-btn-lg"
                >
                  Send Welcome Emails
                </button>
              </div>
            }
            <div className="accordion__header-btnContainer">
              <button
                onClick={() => setUpdateLogoModal(true)}
                className="btn outline-btn outline-btn-lg"
              >
                Upload Logo
              </button>
            </div>
            <div className="accordion__header-btnContainer import-csv-btn">
              <button
                onClick={() => setAddModal(true)}
                className="btn outline-btn outline-btn-lg"
              >
                Add Admin
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion__subheader">
        <p className="shadow-text">District Admins</p>
      </div>
      <div>
        <div className="accordion__content border-radious-bottom management-internal-table-dark">
          <div className="section-search">
            <div className="section-search-field animated">
              <div className="icon">
                <img src={SearchIcon} alt="search" />
              </div>
              <input
                ref={inputRef}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  handleSearch(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search Admins"
              />
            </div>

            <div className="d-flex align-items-center">
              <span>Show</span>
              <LimitSelect
                disableHandler={management?.districtAdmins?.length < 10}
                onChangeHandler={(e: any) => setLimit(Number(e?.value))}
              />
              <span>Admins</span>
            </div>
          </div>
          <div className="checo-table-container">
            <div className="table-responsive">
              <table className="checo__table">
                <thead>
                  <tr className="checo__table-row">
                    <th
                    tabIndex={0}
                     className="checo__table-name" 
                     onClick={() => {
                      sortDataBy("last_name");
                    }}
                    onKeyDown={(e)=>{
                      if(e.key==="Enter"){
                        sortDataBy("last_name");
                      }
                    }}
                    >
                      Admin Name
                    {sortBy === "last_name" && <SortIndicatorIcon stroke="white" OrderByColumn={OrderByColumn}  /> }
                    </th>
                    <th
                      tabIndex={0}
                      className="checo__table-email" 
                      onClick={() => {
                        sortDataBy("email")    
                    }}
                    onKeyDown={(e)=>{
                      if(e.key==="Enter"){
                        sortDataBy("email")
                      }
                    }}
                    >Email
                    {sortBy === "email" && (
                         <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="white" /> 
                          
                        )}
                    </th>
                    <th className="checo__table-title">Title</th>

                    <th
                    tabIndex={0}
                     className="checo__table-login" onClick={() => {
                      sortDataBy("last_login")
                      }}
                      onKeyDown={(e)=>{
                        if(e.key==="Enter"){
                          sortDataBy("last_login")
                        }
                      }}
                      >Last Login
                       {sortBy === "last_login" && (
                         <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="white" />
                        )}  
                    </th>
                    <th className="checo__table-superintendent">
                      Admin is <br /> Superintendent
                    </th>
                    {auth &&
                                auth.currentUser?.role === "Super Admin" && <th className="checo__table-login">PIN</th>}
                    <th className="checo__table-options">Options</th>
                  </tr>
                </thead>
                <tbody>
                  {skeletonLoad ? (
                    <TableSkeleton baseColor="#2a539143" highlightColor="#5371b732" columns={7} count={4} height={36} />
                  ) : (
                    ""
                  )}
                  {management?.districtAdmins?.length &&
                    !skeletonLoad ? (
                    management.districtAdmins.map((item: any, i: number) => {
                      return (
                        <tr
                          key={i}
                          className={
                            lastAddedId === item.id ? "checo__table-row highlight-row" : i % 2 !== 0
                              ? "checo__table-row"
                              : "checo__table-row dark-row-blue"
                          }
                          id={lastAddedId === item.id ? 'district-admin-data' : ''}
                        >
                          <td className="name-data">
                            {item?.last_name}, {item?.first_name}
                          </td>
                          <td className="email-data">{item?.email}</td>
                          <td className="title-data">{item?.title}</td>
                          <td className="text-center">
                            {item?.last_login
                              ? moment(item?.last_login).format("M-D-YY")
                              : "-"}
                          </td>
      
                          <td className="text-center vertical-center">
                              <Toggle
                                inputName="Is Superintendent"
                                ariaLabel="Is Superintendent" 
                                isSmall = {true}
                                labelClassName="mb-0"
                                tabIndex={0}
                                activeDisable= {auth.currentUser?.role === "District Admin"}
                                checked={item?.is_superintendent ? true : false}
                                onChange={(e) => {
                                  auth.currentUser?.role === "District Admin" ? setHintModal(true) :
                                    dispatch(
                                      updateIsSuperIntendentFlag(
                                        e.target.checked,
                                        item.id,
                                        parseInt(id)
                                      )
                                    );
                                }}
                                
                              />
                          </td>
                          {auth &&
                                auth.currentUser?.role === "Super Admin" && <td className="text-center">{item?.admins_districts[0]?.support_pin || ''}</td>}
                          <td className="options-data">
                            <div className="d-flex justify-content-start align-items-center">
                              <img
                                  tabIndex={0}
                                  onClick={() => {
                                  auth.currentUser?.role === "District Admin" && item?.is_superintendent ? setHintModal(true) :
                                    doSetUpdateModal(item)
                                }}
                                onKeyDown={(e)=>{ if(e.key=== "Enter"){
                                  auth.currentUser?.role === "District Admin" && item?.is_superintendent ? setHintModal(true) :
                                  doSetUpdateModal(item)
                                }}}
                                src={EditIcon}
                                alt="Edit"
                                className={`management__table-editBtn ${auth.currentUser?.role === "District Admin" && item?.is_superintendent ? 'disabled-item' : ''}`}
                              />
                              {auth.currentUser?.role === "Super Admin" ? (
                                <img
                                  tabIndex={0}
                                  onClick={() => doSetPasModal(item)}
                                  onKeyDown={(e)=>{if(e.key==="Enter"){
                                    doSetPasModal(item)
                                  }}}
                                  src={RPIcon}
                                  alt="Password"
                                  className="management__table-editBtn"
                                />
                              ) : (
                                ""
                              )}
                              <img
                              tabIndex={0}
                                onClick={() => {
                                  auth.currentUser?.role === "District Admin" && item?.is_superintendent ? setHintModal(true) :
                                    doSetDelModal(item)
                                }}
                                onKeyDown={(e)=>{
                                  if(e.key==="Enter"){
                                    auth.currentUser?.role === "District Admin" && item?.is_superintendent ? setHintModal(true) :
                                    doSetDelModal(item)
                                  }
                                }}
                                src={DeleteIcon}
                                alt="Trash"
                                className={`management__table-editBtn ${auth.currentUser?.role === "District Admin" && item?.is_superintendent ? 'disabled-item' : ''}`}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      {management?.districtAdmins?.length === 0 && !skeletonLoad && (
                        <td colSpan={7} className="no-admin dark-table">
                          No Admins
                        </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          totalCount={management.districtAdminsCount}
          currentPage={currentPage}
          pageSize={limit}
          onPageChange={doChangePage}
        />
      </div>
      {deleteModal &&
        <DistrictAdminDeleteModal
          loading={management.districtAdminsDeleteLoading}
          isShow={deleteModal}
          headerText={`Delete ${currentItem?.first_name} ${currentItem?.last_name}`}
          bodyText={`Are you sure you want to delete this district admin?`}
          onCancel={() => setDeleteModal(false)}
          onDelete={onDeleteDistrictAdmin}
        />
      }
      {updateModal && (
        <DistrictAdminUpdateModal
          isShow={updateModal}
          first_name={currentItem?.first_name}
          last_name={currentItem?.last_name}
          input_email={currentItem?.email}
          input_title={currentItem?.title}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateDistrictAdmin(data)}
          loading={management.districtAdminsUpdateLoading}
        />
      )}
      {updateLogoModal && (
        <DistrictLogoUpdateModal
          isShow={updateLogoModal}
          loading={logoLoading}
          onCancel={() => setUpdateLogoModal(false)}
          onUpdate={(data) => onUpdateLogo(data)}
          onRemoveLogo={() => onRemoveLogo()}
        />
      )}
      {addModal && (
        <DistrictAdminAddModal
          district_id={Number(districtId)}
          isShow={addModal}
          loading={management.districtAdminsAddLoading}
          onCancel={() => setAddModal(false)}
          onAdd={(data) => onAddDistrictAdmin(data)}
        />
      )}
      {resetPassModdal && (
        <ResetPasswordModal
          isShow={resetPassModdal}
          loading={management?.resetPwLoading}
          onCancel={() => setResetPassModdal(false)}
          onAdd={onCreatePassword}
          password={randomPass}
          title={`${currentItem?.first_name} ${currentItem?.last_name}`}
        />
      )}
      {emailConfirmModal && (
        <SendEmailConfirmationModal
          isShow={emailConfirmModal}
          onClose={() => {
            setEmailConfirmMessage('')
            setEmailConfirmModal(false)
          }}
          message={emailConfirmMessage}
          onYes={() => sendEmail(true)}
        />
      )}
      {
        openHintModal &&
        <PermissionHintModal
          isShow={openHintModal}
          onClose={() => setHintModal(false)}
          onYes={() => setHintModal(false)}
          message="Please contact the superintendent or Alpha Plus support to edit/delete this record."
        />
      }
    </>
  );
};

export default DistrictAdminDetail;
