/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// import LinkIcon from 'assets/img/link-cion.jpg'
import React, { useState, useEffect, useMemo, Fragment, useRef } from "react";
import SearchIcon from "assets/img/search-icon.svg";
import QuestionMarkIcon from "assets/img/question-mark-tooltip-icon.png";
import LinkIcon from "assets/img/new-link.png";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  clearStudentAssessment,
  monitorSummatives,
  submitStudentAssessment,
  summativeClearAnswers,
  summativeForceSubmit,
  summativePauseAndResume,
  updateStudents,
  sendNewAttemptRequest,
  getTestSimulationStatus,
  simulateTest,
  monitorFormatives,
  monitorAssessments,
  FormativeForceSubmit,
  FormativePauseAndResume,
  FormativeClearAnswers,
  allCurrentSessionsClearAnswers,
  assignSummatives,
  assignFormatives,
  getSingleStudentAssessmentData,
} from "redux/actionCreators/classroom";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { orderBy } from "lodash";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import HowToReadSummative from "components/classroom/modals/readProgressSummative";
import ShowProgress from "components/classroom/modals/showProgress";
import socketKeys from "util/types/socket-keys";
import { PromptModal } from "components/classroom/modals/prompt";
import {
  SearchableSelect,
  TableSelectCommon,
} from "components/common/selectComponent";
import { NewAttemptConfirmationModal } from "../modals/NewAttemptConfirmationModal";
import SubmitModal from "./modals/monitorSubmitModal";
import ClearModal from "./modals/monitorClearModal";
import Socket from "services/core/socket";
import {
  getAllAssessments
} from "services/professionalDevelopment";
import HowToReadFormative from "../modals/readProgressFormative";
import LoadingSkeleton from "components/common/loadingSkeleton";
import Skeleton from "react-loading-skeleton";
import { DEFAULT_PROGRESS_DATA, FORMATIVE_PROFICIENY } from "util/constants";
// import { sortAssessment } from "util/sortAssessment";
import { capitalizeFirstLetter, getFormattedTime } from "util/index";
import { ChooseQuestionOrder } from "../modals/chooseQuestionOrder";
import { NoStudents } from "../modals/noStudents";
import SuccessModal from "components/common/successModal";
import { useFormulaSheet } from "hooks/useFormulaSheet";
import MontorRowSkeleton from "./monitor-row-loading";
import HowToReadAll from "../modals/readProgressBoth";
import { debounce } from "lodash";
import ToolTip from "components/common/Tooltip";

let timeInterval: NodeJS.Timer | null = null;
const ALL_SESSION_OPTION = { id: -1, label: "All Current Sessions", value: -1, is_enabled: true };

const {
  SOCKET_TEACHER_CLEAR_ANSWER,
  SOCKET_TEACHER_FORCE_SUBMIT,
  SOCKET_TEACHER_PAUSE_RESUME,
  SOCKET_STUDENT_UPDATE_ANSWER,
  SOCKET_TEACHER_CONNECT_CLASSROOM_TEST,
  SOCKET_STUDENT_UPDATE_POSITION_QUESTION,
  SOCKET_STUDENT_JOIN_ROOM,
  SOCKET_STUDENT_SUBMIT,
  SOCKET_DISCONNECT_EVENT,
  SOCKET_STUDENT_TEST_TIME,
  SOCKET_TEACHER_KICK_STUDENT,
  SOCKET_TEACHER_VIEW_UPDATE,
  PAUSE_SESSION,
  NEW_ATTEMPT,
  CLEAR_ANSWERS,
  FORCE_SUBMIT_CLOSE,
  SINGLE_SUBMIT,
  SINGLE_CLEAR,
  SOCKET_TEACHER_UNSUBSCRIBE_EVENT,
} = socketKeys;

interface RootState {
  auth: any;
  activity: any;
  classroom: any;
}

type Color = keyof typeof ColorMap;

type T = keyof typeof AnswerMap;

const AnswerMap = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
};

const ColorMap = {
  correct: "high_rating",
  wrong: "low_rating",
  unanswered: "average_rating",
};

const defaultStatusFilter = {
  online: false,
  offline: false,
  submitted: false,
};

const options = [
  { value: "submit", label: "Submit" },
  { value: "clear", label: "Clear" },
];

const MonitorSummative: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classroom }: any = useParams();
  const location = useLocation();

  const app = useSelector((state: RootState) => state);
  const isMath = app.classroom.subject === 1;
  const formulaSheet = useFormulaSheet();

  // const [summatives, setSummatives] = useState<any>([]);
  // const [formatives, setFormatives] = useState<any>([]);
  const [assessments, setAssessments] = useState<any>([]);

  const [selectedSession, setSelectedSession] = useState<any>();
  const selectedSessionIdRef = useRef<number | null>(null);

  const is_formative =
    selectedSession?.isFormative || location.state?.is_formative;
  const id = selectedSession?.value;
  const isAllCurrentSession = id === -1;

  const [SummativeName, setSummativeName] = useState("");
  const [summativeProficiency, setSummativeProficiency] = useState(0);
  const [students, setStudents] = useState<any[]>([]);
  const [studentsTime, setStudentsTime] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [submittedStudents, setSubmittedStudents] = useState(false);
  const [openRandomModal, setOpenRandomModal] = useState(false);
  const [notEnableModal, setNotEnableModal] = useState(false);
  const [openNoStudentsModal, setOpenNoStudentsModal] = useState(false);
  // const [summativeMessage, setSummativeMessage] = useState('');
  const [tempAssessment, setTempAssessment] = useState<any>(null);

  const [modalShow, setModalShow] = useState(false);
  const [enableLoading, setEnableLoading] = useState(false);
  const [isForced, setIsForced] = useState(false);
  const [classroomId, setClassroomId] = useState(parseInt(classroom));
  const [studentIds, setStudentIds] = useState([]);
  const [readProgress, setReadProgress] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const [modalType, setModalType] = useState("");
  const [onlineStudentsCount, setOnlineStudentsCount] = useState<number>(0);
  const [socketMessage, setSocketMessage] = useState<any>(null!);
  const [onlineStudents, setOnlineStudents] = useState<Array<any>>(null!);
  const [statusFilter, setStatusFilter] = useState(defaultStatusFilter);
  const [paused, setPaused] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState<any>(null);
  const [disconnectStudent, setDisconnectStudent] = useState<any>(null);
  const [studentActionDetails, setStudentActionDetails] = useState<{
    data: any;
    socketData: any;
  }>(null!);
  const [newAttemptId, setNewAttemptId] = useState<any>(0);
  const [doUpdateStatus, setUpdateStatus] = useState(false);
  const [rerender, setRerender] = useState(0);

  const [simulationStatus, setSimulationStatus] = useState("NO_SESSION");
  const [simulationButton, setSimulationButton] = useState(false);

  //Random progress
  const [isRandom, setIsRandom] = useState<boolean>(false);

  const socketInstance = Socket.getInstance();

  const setSelectedSessionValue = (selectedSession: any) => {
    setSelectedSession(selectedSession);
    selectedSessionIdRef.current = selectedSession.value;
  }
  const fetchAllAssessments = async () => {
    const allAssessments = await getAllAssessments(classroomId);
    if (allAssessments) {
      const formattedAssessments = allAssessments.map((item:any) => {
        const isSummative = item.name.endsWith("- A1") || item.name.endsWith("- B") || item.name.endsWith("- A2");
        return {
          ...item,
          label: item.name,
          value: item.id,
          isFormative: !isSummative
        }
      });
      setAssessments(formattedAssessments);
      return formattedAssessments;
    }

    return allAssessments;
  }

  const connectTeacherToClassroom = (testId: number, isFormative: boolean) => {
    if (testId === -1) {
      socketInstance.emit(SOCKET_TEACHER_CONNECT_CLASSROOM_TEST, {
        classroom_id: classroom,
        test_id: "all",
      });
    } else {
      socketInstance.emit(SOCKET_TEACHER_CONNECT_CLASSROOM_TEST, {
        classroom_id: classroom,
        test_id: testId,
        is_formative: isFormative,
      });
    }
  };

  const unSubscribeSession = (testId: any, isFormative: boolean) => {
    socketInstance?.emit(SOCKET_TEACHER_UNSUBSCRIBE_EVENT, {
      classroom_id: parseInt(classroom),
      test_id: testId === -1 ? 'all' : Number(testId),
      ...(testId !== -1 && {is_formative: isFormative}),
    });
  }

  const getAssessmentById = (id: number, assessments: any) => {
    if(!assessments?.length) return null;

    const selectedOption = id === ALL_SESSION_OPTION.id
    ? ALL_SESSION_OPTION
    : assessments.find((item: any) => item.id === id);
    
    return selectedOption;
  }

  const setSelectedOptionWithRemoveNavigation = (selectedOption: any) => {
    removeNavigationState();
    sessionStorage.setItem("assessment_id", selectedOption.value);
    setSelectedOption(selectedOption);
  }

  const setSelectedOption = (selectedOption: any) => {
    if(!selectedOption){
      setSkeletonLoad(false);
      return;
    }

    if(selectedSession){
      unSubscribeSession(selectedSession.value, selectedSession.isFormative);
    }
    setSelectedSessionValue(selectedOption);
    fetchStudentHandler(selectedOption.value, selectedOption.isFormative)
    
  }

  useEffect(() => {
    const init = async () => {
      if (app.classroom.type === "GET_ALL_SUMMATIVES") {
        setClassroomId(app.classroom.classroomId);
      }
      // await fetchStudents();
    };
    init();
  }, [app.classroom.type, rerender]);

  useEffect(() => {
    const fetchAssessments = async () => {
      const allAssessments = await fetchAllAssessments();
      if(allAssessments){
        const selectedAssessmentId = location.state?.id
        ? location.state?.id
        : Number(sessionStorage.getItem("assessment_id"));

        const selectedAssessment = getAssessmentById(selectedAssessmentId, allAssessments);
        setSelectedOption(selectedAssessment);
      }
      timeInterval = setInterval(() => {
        fetchAllAssessments();
      },3 * 1000);
    }

    fetchAssessments();
    return () => {
      timeInterval && clearInterval(timeInterval);
    }
  }, []);

  useEffect(() => {
    if (!selectedSession || !selectedSession.is_enabled) {
      return;
    }
    connectTeacherToClassroom(
      selectedSession.value,
      selectedSession.isFormative
    );
  }, [selectedSession]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      if(selectedSessionIdRef.current){
        sessionStorage.removeItem("assessment_id");
        unSubscribeSession(selectedSessionIdRef.current, is_formative)
      }
      
    };
  }, []);

  const clearAnswers = async () => {
    // eslint-disable-next-line no-restricted-globals

    socketInstance.emit(SOCKET_TEACHER_CLEAR_ANSWER, {
      classroom_id: classroom,
      ...(id !== -1 && { is_formative: is_formative }),
      test_id: id === -1 ? "all" : id,
      ...(!is_formative && id !== -1 && { student_id: null }),
    });

    socketInstance.emit(SOCKET_TEACHER_VIEW_UPDATE, {
      classroom_id: classroom,
      ...(id !== -1 && { is_formative: is_formative }),
      test_id: id === -1 ? "all" : id,
      event: CLEAR_ANSWERS,
    });

    if (isAllCurrentSession) {
      await allCurrentSessionsClearAnswers({
        classroom_id: parseInt(classroom),
        student_ids: studentIds,
        students_tests: students.map((st) => ({
          student_id: st.fk_student_id,
          test_id: st.test_id,
          type: st.test_type,
        })),
      });
    } else if (is_formative) {
      await FormativeClearAnswers({
        formative_id: parseInt(id),
        student_ids: studentIds,
        classroom_id: parseInt(classroom),
      });
    } else {
      await summativeClearAnswers({
        summative_id: parseInt(id),
        student_ids: studentIds,
        classroom_id: parseInt(classroom),
      });
    }

    setModalShow(false);
    await fetchStudents();
    setUpdateStatus(true);
  };

  useEffect(() => {
    if (!app.auth?.socketData) {
      return;
    }
    setSocketMessage(app.auth.socketData);
  }, [app]);

  const monitorToMonitorHandler = async () => {
    const { event, isPaused } = socketMessage;
    switch (event) {
      case PAUSE_SESSION: {
        setPaused(isPaused);
        break;
      }
      case FORCE_SUBMIT_CLOSE: {
        navigate(`/classroom`);
        break;
      }
      case NEW_ATTEMPT:
        break
      default:
        await fetchStudents();
    }
  };

  useEffect(() => {
    if (socketMessage) {
      if (
        socketMessage.rerender &&
        socketMessage.for_teacher !== app.auth.currentUser.id
      ) {
        setRerender(Math.random());
      }

      const { type } = socketMessage;

      if (type === SOCKET_TEACHER_CONNECT_CLASSROOM_TEST) {
        const { onlineStudent }: { onlineStudent: Array<any> } = socketMessage;
        setOnlineStudentsCount(onlineStudent.length);
        setOnlineStudents(onlineStudent);
      }

      if (type === SOCKET_STUDENT_UPDATE_ANSWER) {
        studentUpdateAnswer(socketMessage);
      }

      if (type === SOCKET_STUDENT_SUBMIT) {
        studentSubmitTest(socketMessage);
      }

      if (type === SOCKET_TEACHER_VIEW_UPDATE) {
        monitorToMonitorHandler();
      }

      if (type === SOCKET_STUDENT_UPDATE_POSITION_QUESTION) {
        studentUpdateQuestionPosition(socketMessage);
      }

      if (type === SOCKET_STUDENT_JOIN_ROOM) {
        const { online_student }: { online_student: Array<any> } =
          socketMessage;
        
        if(id === -1){
          fetchStudentData(online_student);
        }else{
          setOnlineStudents(online_student);
          setOnlineStudentsCount(online_student.length);
        }
      }

      if (type === SOCKET_DISCONNECT_EVENT) {
        setDisconnectStudent(socketMessage["student_id"]);
        const studentId = socketMessage["student_id"] as string;
        const online_student = onlineStudents?.filter(
          (student: any) => +student.id !== +studentId
        );
        setOnlineStudents(online_student);
        setOnlineStudentsCount(onlineStudentsCount - 1);
      }

      if (type === SOCKET_STUDENT_TEST_TIME) {
        setStudentsTime(socketMessage);
      }
    }
  }, [socketMessage]);

  const fetchStudentData = async (student_data: any) => {
    const data = student_data?.length ? student_data[0] : null;
    if(!data) return;

    const res = await getSingleStudentAssessmentData({
      classroom_id: parseInt(classroom), 
      student_id: +data.id,
      test_type: data?.is_formative,
      test_id: +data.testId
    });

    if(res.data?.code === 200){
      const student_data = res.data.data;
      const updatedStudents = students.map(item => {
        if(item.id === student_data.id){
          return student_data
        }

        return item;
      });

      setStudents(updatedStudents);
    }

    setOnlineStudents(student_data);
    setOnlineStudentsCount(student_data.length);
    
  }

  const updateStatusData = () => {
    const updatedStudents: any = students.map((student: any) => {
      const filteredStudent: any = onlineStudents?.find(
        (onlineStudent: any) => student.id === +onlineStudent?.id
      );

      return {
        ...student,
        status: filteredStudent ? "online" : student.is_submitted ? "submitted" : "offline",
        total_time_in_minutes:
          studentsTime && +studentsTime.student_id === student.id
            ? studentsTime.test_time
            : student.total_time_in_minutes,
      };
    });

    const newUpdatedStudents = updatedStudents.map((student: any) => {
      if (+disconnectStudent === student.id) {
        const progressData = student.progress_data.map((data: any) => {
          if (data.is_active) {
            return {
              ...data,
              is_active: false,
            };
          }
          return data;
        });
        return {
          ...student,
          progress_data: progressData,
        };
      }

      return student;
    });
    setStudents(newUpdatedStudents);
    doUpdateStatus && setUpdateStatus(false);
  };

  const debouncedSearch = debounce(async (value: string) => {
    await fetchStudents(value);
    setUpdateStatus(true);
  },500)

  const onSearch = async (e: any) => {
    debouncedSearch(e.target.value)
  }

  useEffect(() => {
    if (doUpdateStatus && students.length) {
      updateStatusData();
    }
  }, [doUpdateStatus, students]);

  useEffect(() => {
    updateStatusData();
  }, [onlineStudents, disconnectStudent, studentsTime]);

  const studentSubmitTest = (data: any) => {
    const { student_id, score, total_time_in_minutes } = data;
    const updatedStudents = students.map((student: any) => {
      if (student.id === +student_id) {
        return {
          ...student,
          progress_data: student.progress_data.map((data: any) => {
            return {
              ...data,
              is_active: false,
            };
          }),
          score,
          status_time: total_time_in_minutes,
          is_submitted: true,
          is_available: false,
          attempt: student.attempt,
        };
      }

      return student;
    });
    
    setStudents(updatedStudents);
    if(onlineStudents && Array.isArray(onlineStudents)){
      setOnlineStudents(
        onlineStudents.filter((item) => item.student_id !== student_id)
      );
    }
  };

  const studentUpdateAnswer = (data: any) => {
    const { student_id, question_id, answer_info, answer } = data;
    const updatedStudents = students.map((student: any) => {
      if (student.id === Number(student_id)) {
        return {
          ...student,
          progress_data: student.progress_data.map((data: any) => {
            if (data.question_id === question_id) {
              return {
                ...data,
                answer,
                color: answer_info.color,
                info: answer_info.info,
              };
            }
            return data;
          }),
        };
      }

      return student;
    });

    setStudents(updatedStudents);
  };

  const studentUpdateQuestionPosition = (data: any) => {
    const { student_id, question_id } = data;

    const updatedStudents = students.map((student: any) => {
      if (student.id === Number(student_id)) {
        return {
          ...student,
          progress_data: student.progress_data.map((data: any) => {
            if (data.question_id === question_id) {
              return {
                ...data,
                is_active: true,
              };
            }
            return {
              ...data,
              is_active: false,
            };
          }),
          current_question: question_id,
        };
      }

      return student;
    });

    setStudents(updatedStudents);
  };

  const fetchStudentHandler = async (
    assessment_id: number,
    is_formative: boolean,
    search: string = "",
    setLoader: boolean = true
  ) => {
    setLoader && setIsLoading(true);
    let monitor_assessments: any = [];
    if (assessment_id === -1) {
      monitor_assessments = await monitorAssessments({
        classroom_id: parseInt(classroom),
        // formative_id: assessment_id,
      });
    } else if (is_formative) {
      monitor_assessments = await monitorFormatives({
        classroom_id: parseInt(classroom),
        formative_id: assessment_id,
      });
    } else {
      monitor_assessments = await monitorSummatives({
        classroom_id: parseInt(classroom),
        summative_id: assessment_id,
      });
    }

    if (assessment_id !== -1) {
      const checkSimulationStatus = await getTestSimulationStatus({
        classroomId: parseInt(classroom),
        assessmentId: assessment_id,
        type: is_formative ? "formatives" : "summatives",
      });

      setSimulationButton(
        checkSimulationStatus?.data?.data?.show_controls || false
      );
      setSimulationStatus(
        checkSimulationStatus?.data?.data?.status || "NO_SESSION"
      );
    }

    const summativeProficientLevel =
      monitor_assessments?.data?.data?.level?.find(
        (item: any) => item.type === "P"
      );
    summativeProficientLevel &&
      setSummativeProficiency(summativeProficientLevel.percentage);
    setSummativeName(monitor_assessments?.data.data.name);
    const data: any = [];
    if (search) {
      const filtersData = monitor_assessments.data.data.data.filter(
        (std: any) =>
          std.student_name.toLowerCase().includes(search.toLowerCase()) ||
          std.student_id.toString().includes(Number(search))
      );
      filtersData.map((x: any) => data.push(x.id));
      setPaused(monitor_assessments.data.data.is_paused);
      setStudentIds(data);
      setStudents(filtersData);
      setIsLoading(false);
    } else {
      const updatedStudents = monitor_assessments.data.data.data;
      updatedStudents.map((x: any) => data.push(x.id));
      setPaused(monitor_assessments.data.data.is_paused);
      setStudentIds(data);
      setStudents(updatedStudents);
      setIsLoading(false);
    }

    // if random
    const { is_randomized } = monitor_assessments?.data?.data;
    setIsRandom(is_randomized);
    setSkeletonLoad(false);
  };

  const fetchStudents = async (search: string = "", setLoader:boolean = true) => {
    await fetchStudentHandler(id, is_formative, search, setLoader);
  };

  const forceSubmit = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (id === -1) {
      socketInstance.emit(SOCKET_TEACHER_FORCE_SUBMIT, {
        classroom_id: classroom,
        test_id: "all",
      });
    } else {
      socketInstance.emit(SOCKET_TEACHER_FORCE_SUBMIT, {
        classroom_id: classroom,
        is_formative: is_formative,
        test_id: id,
      });
    }

    if (id === -1) {
      socketInstance.emit(SOCKET_TEACHER_VIEW_UPDATE, {
        classroom_id: classroom,
        test_id: "all",
        event: FORCE_SUBMIT_CLOSE,
      });
    } else {
      socketInstance.emit(SOCKET_TEACHER_VIEW_UPDATE, {
        classroom_id: classroom,
        is_formative: is_formative,
        test_id: id,
        event: FORCE_SUBMIT_CLOSE,
      });
    }

    let bodyData = {
      [is_formative ? "formative_id" : "summative_id"]: parseInt(id),
      student_ids: studentIds,
      classroom_id: parseInt(classroom),
    };

    if (isForced) {
      Object.assign(bodyData, {
        is_forced: isForced,
      });
    }

    if (is_formative) {
      await FormativeForceSubmit(bodyData);
    } else {
      await summativeForceSubmit(bodyData);
    }

    navigate(`/classroom`);
  };

  const kickStudent = async () => {
    socketInstance.emit(SOCKET_TEACHER_KICK_STUDENT, {
      student_id: selectedStudent.id,
      classroom_id: classroom,
      is_formative: is_formative,
      test_id: id,
    });
    handleOnChange(false, selectedStudent.id, "offline");
    setSelectedStudent(null);
    setModalType("");
    setModalShow(false);
  };

  const pauseStatus = async () => {
    // eslint-disable-next-line no-restricted-globals

    if (is_formative) {
      await FormativePauseAndResume({
        classroom_id: parseInt(classroom),
        is_paused: !paused,
      });
    } else {
      await summativePauseAndResume({
        classroom_id: parseInt(classroom),
        is_paused: !paused,
      });
    }

    socketInstance.emit(SOCKET_TEACHER_PAUSE_RESUME, {
      classroom_id: classroom,
      is_formative: is_formative,
      test_id: id,
    });

    socketInstance.emit(SOCKET_TEACHER_VIEW_UPDATE, {
      classroom_id: classroom,
      is_formative: is_formative,
      test_id: id,
      isPaused: !paused,
      event: PAUSE_SESSION,
    });

    setPaused(!paused);

    setModalShow(false);
  };

  const backButton = (e: any) => {
    e.preventDefault();

    navigate("/classroom");
    sessionStorage.removeItem("assessment_id");
  };

  const handleOnChange = async (
    checked: boolean,
    student_id: number,
    status: string,
    x: any = null
  ) => {
    if (status === "online" && !checked) {
      setSelectedStudent(x);
      setModalType("KICK_STUDENT");
      setModalShow(true);
    } else {
      const updatedStudentsList: any[] = students.length
        ? students.map((item: any) => {
            if (item.id === student_id && item.is_submitted === false) {
              item.is_available = checked ? true : false;
            }
            return item;
          })
        : [];
      const updatedStudent = [
        {
          student_id: student_id,
          is_available: checked,
        },
      ];
      setStudents(updatedStudentsList);
      dispatch(
        updateStudents({
          summative_id: is_formative ? 0 : parseInt(id),
          formative_id: is_formative ? parseInt(id) : 0,
          students: updatedStudent,
        })
      );
    }
  };

  const tableBodyHeight = skeletonLoad ? "370px" : "auto";

  const onClearClick = async () => {
    if (studentActionDetails) {
      const { data, socketData } = studentActionDetails;
      const { isFormative, ...restData } = data;
      socketInstance.emit(SOCKET_TEACHER_CLEAR_ANSWER, socketData);
      socketInstance.emit(SOCKET_TEACHER_VIEW_UPDATE, {
        classroom_id: classroom,
        is_formative: is_formative,
        test_id: id,
        event: SINGLE_CLEAR,
        resource_id: socketData.student_id,
      });

      await dispatch(
        clearStudentAssessment(
          restData,
          isFormative ? "formative" : "summative"
        )
      );
      setStudentActionDetails(null!);
      setModalShow(false);
      await fetchStudents();
      setUpdateStatus(true);
    }
  };

  const onSubmitClick = async () => {
    if (studentActionDetails) {
      console.log(studentActionDetails);
      const { data, socketData } = studentActionDetails;
      const { isFormative, ...restData } = data;
      socketInstance.emit(SOCKET_TEACHER_FORCE_SUBMIT, socketData);
      socketInstance.emit(SOCKET_TEACHER_VIEW_UPDATE, {
        classroom_id: classroom,
        is_formative: is_formative,
        test_id: id,
        event: SINGLE_SUBMIT,
        resource_id: socketData.student_id,
      });

      await dispatch(
        submitStudentAssessment(
          restData,
          isFormative ? "formative" : "summative"
        )
      );
      setStudentActionDetails(null!);
      setModalShow(false);
      await fetchStudents();
      setOnlineStudents(
        onlineStudents.filter((item) => item.student_id !== socketData.rollNo)
      );
    }
  };

  const performStudentActions = async (
    action: string,
    student: any,
    isFormative: boolean
  ) => {
    const studentId = student.id;
    const data = {
      [isFormative ? "formative_id" : "summative_id"]: student.test_id,
      classroom_id: parseInt(classroom),
      student_ids: [studentId],
      isFormative,
    };

    const socketData = {
      classroom_id: classroom,
      is_formative: is_formative,
      test_id: id,
      student_id: studentId,
      rollNo: student.student_id,
    };

    if (action === "submit") {
      setModalType("SUBMIT_CONFIRMATION_MODAL");
    } else if (action === "clear") {
      setModalType("CLEAR_CONFIRMATION_MODAL");
    }
    setModalShow(true);
    setStudentActionDetails({ data, socketData });
  };

  const onNewAttempt = async (studentData: any, isFormative: boolean) => {
    const studentId = studentData.id;
    const data = {
      [isFormative ? "formative_id" : "summative_id"]: studentData.test_id,
      classroom_id: classroomId,
      student_id: studentId,
    };

    socketInstance.emit(SOCKET_TEACHER_VIEW_UPDATE, {
      classroom_id: classroom,
      is_formative: isFormative,
      test_id: id,
      event: NEW_ATTEMPT,
      resource_id: studentId,
    });

    setNewAttemptId(studentId);
    await dispatch(
      sendNewAttemptRequest(data, isFormative ? "formative" : "summative")
    );
    await fetchStudents("", false);
    setNewAttemptId(0);
    setUpdateStatus(true);
  };

  const statusFilterHandler = (e: any) => {
    const name: string = e.target.name;
    console.log(name, statusFilter[name as keyof typeof defaultStatusFilter]);
    setStatusFilter({
      ...defaultStatusFilter,
      [e.target.name]: !statusFilter[name as keyof typeof defaultStatusFilter],
    });
  };

  const filterStudents = (students: any, filter: string) => {
    let filteredStudents = [];
    switch(filter){
      case 'offline':
        filteredStudents = students.filter((item: any) => {
          const idx = onlineStudents.findIndex(st => +st.id === item.id);
          return idx === -1 && !item.is_submitted
        })
        break;
      case 'online':
        filteredStudents = students.filter((item: any) => onlineStudents.some(st => +st.id === item.id));
        break;
      case 'submitted':
        filteredStudents = students.filter((item: any) => item.is_submitted);
        break;
    }

    return filteredStudents;
  }

  const Students = useMemo(() => {
    const filter = Object.keys(statusFilter).find((status) => {
      return statusFilter[status as keyof typeof defaultStatusFilter] === true;
    });
    if (filter) {
      const filteredStudents = filterStudents(students, filter);

      if (!!filteredStudents.length) {
        setSubmittedStudents(false);
        return filteredStudents;
      }
      setSubmittedStudents(true);
      return [];
    }
    return students;
  }, [statusFilter, onlineStudents, students]);

  const matchStudent = (student: any) => {
    let result;
    for (let i = 0; i < onlineStudents.length; i++) {
      if (+onlineStudents[i].id === student.id) {
        result = <span className={`pill-status online`}></span>;
        break;
      }
    }

    if (result) return result;
    result = (
      <span
        className={`pill-status ${
          student.is_submitted
            ? "submitted"
            : socketMessage &&
              +socketMessage["id"] === student?.id &&
              socketMessage["type"] === SOCKET_STUDENT_SUBMIT
            ? "submitted"
            : "offline"
        }`}
      ></span>
    );
    return result;
  };

  const startSimulating = async () => {
    const response = await simulateTest({
      classroomId: parseInt(classroom),
      assessmentId: id === -1 ? "all" : parseInt(id),
      type: id === -1 ? "all" : is_formative ? "formatives" : "summatives",
    });
    setSimulationButton(response?.data?.data?.show_controls || false);
    setSimulationStatus(response?.data?.data?.status || "NO_SESSION");
  };

  const handleAvaibilityToggle = async (x: any, checked: any) => {
    setSkeletonLoad(true);
    setEnableLoading(true);
    if (!x.isFormative) {
      await assignSummatives(
        {
          classroom_id: classroomId,
          summative_id: x.id,
          is_enabled: checked,
          is_random: x.show_random_option,
        },
        () => {}
      );
      setAssessments((prev: any) =>
        prev.map((item: any) => {
          return x.id === item.id
            ? {
                ...item,
                is_enabled: checked,
                show_random_option: x.show_random_option,
              }
            : item;
        })
      );
    } else {
      await assignFormatives(
        {
          classroom_id: classroomId,
          formative_id: x.id,
          is_enabled: checked,
          is_random: x.show_random_option,
        },
        () => {}
      );
      setAssessments((prev: any) =>
        prev.map((item: any) => {
          return x.id === item.id
            ? {
                ...item,
                is_enabled: checked,
                show_random_option: x.show_random_option,
              }
            : item;
        })
      );
    }
    setSelectedOptionWithRemoveNavigation({ ...x, is_enabled: checked });
    setEnableLoading(false);
    setSkeletonLoad(false);
  };

  const onChangeRandom = async (x: any) => {
    await handleAvaibilityToggle(x, true);
    setOpenRandomModal(false);
    setTempAssessment(null);
  };

  const removeNavigationState = () => {
    if (location.state) {
      const isIdExists = "id" in location.state;
      const isFormativeExists = "is_formative" in location.state;

      isIdExists && delete location.state.id;
      isFormativeExists && delete location.state.is_formative;

      (isIdExists || isFormativeExists) &&
        navigate(location.pathname, { state: location.state });
    }
  };

  const selectMonitorAssessment = (x: any) => {
    const selectedOption = assessmentOptions.find(item => item.value === x.value);
    if(selectedOption){
      selectMonitorSession(selectedOption);
    }
  }

  const selectMonitorSession = (option: any) => {
    // if (option.value !== -1 && !option.students_total_count) {
    //   setOpenNoStudentsModal(true);
    //   return;
    // }

    if (option.value !== -1 && !option.is_enabled) {
      setTempAssessment(option);
      const canBeEnabled = option.isFormative ? true : option.can_be_enabled;

      if (!canBeEnabled) {
        setNotEnableModal(true);
      } else if (option.show_random_option) {
        setOpenRandomModal(true);
      } else {
        handleAvaibilityToggle(option, true);
      }

      return;
    }

    setSelectedOptionWithRemoveNavigation(option)
  };

  const onClickFormulaSheet = () => {
    window.open(formulaSheet);
  }

  const isShowSimulateButton = simulationButton && !isAllCurrentSession;
  
  const assessmentOptions = [ALL_SESSION_OPTION, ...assessments];

  const isAnyNonProficient = !isLoading && students.some(item => {
    const isFormative = id === -1 ? item.test_type === 'formative' : is_formative;
    if(isFormative){
      return item.score && item.score < FORMATIVE_PROFICIENY;
    }else{
      return item.score && item.score < summativeProficiency
    }
  });
  const isAllSubmitted = students.every(item => item.is_submitted || (item.progress_data.every((prg:any) => prg.info === "unanswered")));
  const isClearDisabled = isAllSubmitted;
  const isRandomized = students.some(item => item.is_randomized);
  return (
    <>
      <div className="text-white">
        <div style={{ boxShadow: "unset" }} className="section">
          <div className="section-header monitor-header">
            <div className="section-header-heading">
              <span tabIndex={0} onKeyDown={(e) => {
							if (e.key === 'Enter') {
								backButton(e)
							}
						}} className="section-header-link" onClick={backButton}>
                <img  src={ArrowIcon} alt="arrow" />
              </span>
              <h3 className="section-header-title">Assessment Monitor:</h3>
              <div className="all-sessions-dropDown">
                <SearchableSelect
                  options={assessmentOptions}
                  onChangeHandler={selectMonitorSession}
                  value={assessmentOptions?.find(item => item.value === selectedSession?.value)}
                />
              </div>
            </div>
            {isShowSimulateButton && (
              <div className="section-header-simulation monitor-buttons">
                <button
                  className="btn outline-btn outline-btn-lg"
                  onClick={() => {
                    startSimulating();
                  }}
                  disabled={skeletonLoad || isLoading}
                >
                  {simulationStatus === "NO_SESSION"
                    ? "Simulate Session"
                    : "Session In Progress"}
                </button>
              </div>
            )}
            <div className="section-header-buttons monitor-buttons">
              <div className="section-header-counter">
                <span className={`pill-counter success ${onlineStudentsCount > 99 ? 'pill-text-adjust' : ''}`}>
                  {onlineStudentsCount < 0 ? 0 : onlineStudentsCount}
                </span>
                <h5 className="shadow-text">Students in Session</h5>
              </div>
              <div className="section-header-btnContainer">
                <button
                  className="btn outline-btn outline-btn-lg"
                  onClick={() => {
                    setModalType("PAUSE_STATUS");
                    setModalShow(true);
                  }}
                  disabled={isAllCurrentSession || skeletonLoad || isLoading}
                >
                  {paused === false ? "Pause Session" : "Resume Session"}
                </button>
              </div>
            </div>
          </div>

          <div className="section-search monitor">
            <div>
              <p style={{ color: "#ffffff" }}>
                Students take assessments in the Student Portal located at{" "}
                <Link
                  tabIndex={0}
                  to={"https://student.aplustesting.org"}
                  target="_blank"
                  style={{ color: "white" }}
                  className="customFieldsFocused-active"
                  
                >
                  <strong>https://student.aplustesting.org.</strong>
                </Link>{" "}
                <br />
                Monitor their realtime progress on this screen.
              </p>
            </div>

            <div className="section-header-buttons section-header-btnPosition ms-auto">
              <div className="section-header-btnContainer m-0">
                {paused && (
                  <div className="status-alert">
                    <p>
                      <span>Session is</span> Paused
                    </p>
                  </div>
                )}
              </div>

              <div className="section-header-btnContainer">
                <button
                  className="btn outline-btn"
                  onClick={() => {
                    setModalType("CLEAR_ANSWERS");
                    setModalShow(true);
                  }}
                  disabled={isClearDisabled || skeletonLoad || isLoading}
                >
                  Clear Answers
                </button>
              </div>
              <div className="section-header-btnContainer">
                <button
                  className="btn outline-btn"
                  onClick={() => {
                    setModalType("FORCE_SUBMIT");
                    setModalShow(true);
                    setIsForced(true);
                  }}
                  disabled={isAllCurrentSession || skeletonLoad || isLoading}
                >
                  Force Submit and Close
                </button>
              </div>
            </div>
          </div>

          <div className="section-search monitor">
            <div className="d-flex flex-sm-nowrap flex-wrap gap-2">
              <div className="section-search-monitor">
                <div className="section-search-field">
                  <div className="icon">
                    <a href="javascript:void(0)">
                      <img src={SearchIcon} alt="search" />
                    </a>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Students"
                    onChange={onSearch}
                    disabled={isLoading || skeletonLoad}
                  />
                </div>
              </div>

              <div className="status">
                <div className="status__row">
                  <input
                    className="form-check-input offline "
                    type="radio"
                    name="offline"
                    id="flexRadioDefault1"
                    onClick={statusFilterHandler}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        statusFilterHandler(e)
                      }
                  }}
                    checked={statusFilter.offline}
                  />
                  <label className="status__row-label">Offline</label>
                </div>
                <div className="status__row">
                  <input
                    className="form-check-input online"
                    type="radio"
                    name="online"
                    checked={statusFilter.online}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        statusFilterHandler(e)
                      }
                  }}
                    onClick={statusFilterHandler}
                    id="flexRadioDefault2"
                  />
                  <label className="status__row-label">In Session</label>
                </div>
                <div className="status__row">
                  <input
                    className="form-check-input submitted"
                    type="radio"
                    name="submitted"
                    id="flexRadioDefault3"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        statusFilterHandler(e)
                      }
                  }}
                    onClick={statusFilterHandler}
                    checked={statusFilter.submitted}
                  />
                  <label className="status__row-label">Submitted</label>
                </div>
              </div>
            </div>
            <div className="key-formula">
              <div className={`key-proficient ${isAnyNonProficient ? 'show-key-proficient' : ''}`}>
                <label className="key-label">
                  Key
                </label>
                <div className="percentage">%</div>
                <p>Non-proficient</p>
              </div>
              {isMath && formulaSheet && (
                <button onClick={onClickFormulaSheet} type="button" className="btn formula-sheet-btn">
                  <img src={LinkIcon} alt="New Link" />
                  {/* <img src={LinkIcon} /> */}
                  <span>Formula Sheet</span>
                </button>
              )}
            </div>
          </div>

          <div>
            <div className="table-responsive monitor-table-container">
              <table className="monitor__table">
                <thead>
                  <tr className="monitor__table-row">
                    <th className="monitor__table-status">Status</th>
                    {/* <th className="monitor__table-available">
                      Assessment <br /> is Available
                    </th> */}
                    <th className="monitor__table-name">Student Name</th>
                    <th className="monitor__table-id">
                      Student ID <br /> Last 5
                    </th>
                    <th className="monitor__table-id">Assessment</th>
                    <th className="monitor__table-attempt">Attempt</th>
                    <th className="monitor__table-largeProgress">
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="MonitorProgress">Progress</span>
                        {(
                          <div style={{opacity: skeletonLoad || isLoading ? 0.5 : 1}} className="icon">
                            <img
                              role="button"
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  !skeletonLoad && !isLoading && setReadProgress(true)
                                }
                              }} 
                              src={QuestionMarkIcon}
                              alt="question"
                              onClick={() => !skeletonLoad && !isLoading && setReadProgress(true)}
                              className={skeletonLoad || isLoading ? "" : "cursor-pointer"}
                            />
                          </div>
                        )}
                        {isRandom && (
                          <span className="mx-2 monitor Random">Random</span>
                        )}
                      </div>
                    </th>
                    <th className="monitor__table-score">Score %</th>
                    <th className="monitor__table-optionsRight">Options</th>
                  </tr>
                </thead>
                <tbody style={{ height: tableBodyHeight }}>
                  {isLoading === false && skeletonLoad === false ? (
                    <>
                      {students.length ? (
                        <>
                          {orderBy(
                            Students,
                            [(user: any) => user.student_name.toLowerCase()],
                            ["asc"]
                          ).map((x: any, i: any) => {
                            const studentTime = x.status_time ? x.status_time : x.total_time_in_minutes
                            const formattedTime = getFormattedTime(studentTime);
                            const isOnline = onlineStudents?.find(
                              (std) => +std.id === x.id
                            );

                            const isShowSkeleton =
                              !x.is_submitted && !isOnline && isAllCurrentSession;
                            const anyResponseFromStd =
                              x.progress_data.filter(
                                (prg: any) =>
                                  prg.info !== "unanswered" &&
                                  prg.anwser !== null
                              )?.length > 0;
                            
                            if(isShowSkeleton && !x.progress_data?.length){
                              x.progress_data = DEFAULT_PROGRESS_DATA;
                              x.test_type = "formative";
                            }
                            const dropdownOptions = _.cloneDeep(options).map(
                              (item: any) => {
                                item.disabled = !anyResponseFromStd;
                                return item;
                              }
                            );
                            const isFormative =
                              id === -1
                                ? x.test_type === "formative"
                                : is_formative;
                            const isProficienct = isFormative
                              ? x.score >= FORMATIVE_PROFICIENY
                              : x.score >= summativeProficiency;
                            const scoreBgColor = isProficienct
                              ? "unset"
                              : "#ffe000";

                            if(x.id === newAttemptId){
                              return (
                                <MontorRowSkeleton />
                              )
                            }
                            return (
                              <tr
                                key={i}
                                className={
                                  i % 2 === 0
                                    ? "monitor__table-row dark-row"
                                    : "monitor__table-row"
                                }
                              >
                                <td className="status-data">
                                  <div className="status-table-data">
                                    {onlineStudents && onlineStudents.length ? (
                                      matchStudent(x)
                                    ) : (
                                      <span
                                        className={`pill-status ${
                                          x.is_submitted
                                            ? "submitted"
                                            : socketMessage &&
                                              +socketMessage["id"] === x?.id &&
                                              socketMessage["type"] ===
                                                SOCKET_STUDENT_SUBMIT
                                            ? "submitted"
                                            : "offline"
                                        }`}
                                      ></span>
                                    )}
                                    <span className="status-duration">
                                      {formattedTime}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-right student-name">
                                  <span className="ellipsis-animated">
                                    <p
                                      className={`text-shadow-none ellipsis-animated-text ${
                                        x.student_name.length > 16 &&
                                        "animated ellipsis-animation"
                                      }`}
                                    >
                                      {x.student_name}
                                    </p>
                                  </span>
                                </td>
                                <td className="id-data">
                                  {x.student_id?.toString().slice(-5)}
                                </td>
                                <td
                                  className={`id-data ${
                                    isFormative
                                      ? "assessment-label-report"
                                      : "assessment-label-summ"
                                  }`}
                                >
                                  {isShowSkeleton ? (
                                    <LoadingSkeleton
                                      animate={false}
                                      width={60}
                                      height={18}
                                      borderRadius={4}
                                      customStyles={{
                                        display: "inline-block",
                                        marginBottom: -5,
                                      }}
                                    />
                                  ): x.name ? (
                                    <p
                                      onClick={() =>
                                        isAllCurrentSession &&
                                        selectMonitorAssessment({
                                          ...x,
                                          label: x.name,
                                          value: x.test_id,
                                          isFormative:
                                            x.test_type === "formative",
                                        })
                                      }
                                      className={
                                        isAllCurrentSession
                                          ? "cursor-pointer"
                                          : ""
                                      }
                                    >
                                      {x.name}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td className="text-center">
                                  {isShowSkeleton ? (
                                    <LoadingSkeleton
                                      animate={false}
                                      width={40}
                                      height={17}
                                      borderRadius={4}
                                      customStyles={{
                                        display: "inline-block",
                                        marginBottom: -5,
                                      }}
                                    />
                                  ) : (
                                    x.attempt
                                  )}
                                </td>

                                <td
                                  className={
                                    isFormative
                                      ? "progress-data-formative"
                                      : "progress-data"
                                  }
                                  onClick={() => {
                                    if (isFormative) return;

                                    setSelectedStudentName(x.student_name);
                                    setShowProgress(true);
                                  }}
                                >
                                  {!isShowSkeleton && x.is_randomized && (
                                    <ToolTip top={-7} content="Assessment has random choice order">
                                      <span className="pill">R</span>
                                    </ToolTip>
                                  )}
                                  <ul
                                    className={`${
                                      isFormative
                                        ? "progress__ratings"
                                        : "progress__ratings-large cursor-pointer"
                                    }`}
                                  >
                                    {x.progress_data.map(
                                      (progress: any, index: number) => {
                                        if (isShowSkeleton) {
                                          return (
                                            <li key={index}>
                                              <LoadingSkeleton
                                                animate={false}
                                                width={100}
                                                height="100%"
                                                borderRadius={0}
                                              />
                                            </li>
                                          );
                                        }
                                        return (
                                          <li key={index}>
                                            <div
                                              className={`${
                                                (isOnline &&
                                                  !progress.is_active &&
                                                  progress.question_id ===
                                                    x.current_question) ||
                                                (progress.is_active &&
                                                  socketMessage["type"] !==
                                                    SOCKET_STUDENT_SUBMIT)
                                                  ? "active"
                                                  : ""
                                              } ${
                                                ColorMap[progress.info as Color]
                                              } ${i % 2 !== 0 && "darker"}`}
                                            >
                                              {isFormative ? (
                                                progress.answer !== null ? (
                                                  AnswerMap[
                                                    parseInt(
                                                      progress.answer
                                                    ) as T
                                                  ]
                                                ) : (
                                                  `${index + 1}`
                                                )
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </td>
                                <td className="score-data">
                                  {x.score === 0 || x.score ? (
                                    <span
                                      style={{
                                        backgroundColor: scoreBgColor
                                      }}
                                      className="scoreBox"
                                    >
                                      {x.score}
                                    </span>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td className="options-right-data">
                                  <div>
                                    {x.is_submitted ? (
                                      <button
                                        className="btn outline-btn outline-btn-lg attempt new_attempts_button"
                                        onClick={() =>
                                          onNewAttempt(x, isFormative)
                                        }
                                      >
                                        New Attempt
                                      </button>
                                    ) : (
                                      <TableSelectCommon
                                        customClassName={`${
                                          students.length < 4 ? "top-start" : ""
                                        } ${
                                          students.length < 4 &&
                                          `top-start-${i}`
                                        }`}
                                        isOpen={selectedId === i + 1}
                                        toggleSelect={() => {
                                          if (selectedId !== i + 1) {
                                            setSelectedId(i + 1);
                                          } else {
                                            setSelectedId(0);
                                          }
                                        }}
                                        options={dropdownOptions}
                                        onChange={(value: any) =>
                                          performStudentActions(
                                            value,
                                            x,
                                            isFormative
                                          )
                                        }
                                        resetName={
                                          (modalType ===
                                            "SUBMIT_CONFIRMATION_MODAL" ||
                                            modalType ===
                                              "CLEAR_CONFIRMATION_MODAL") &&
                                          !modalShow
                                        }
                                      />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {!selectedSession ? (
                            <td colSpan={10} className="no-admin">
                              Select a monitor session above to view students
                            </td>
                          ): !statusFilter.online &&
                          !statusFilter.submitted &&
                          !statusFilter.offline && (
                            <td colSpan={10} className="no-admin">
                              No students found
                            </td>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    // <TableSkeleton columns={8} count={10} height={36} />
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => {
                      return (
                        <Fragment key={id}>
                          <MontorRowSkeleton />
                        </Fragment>
                      );
                    })
                  )}
                  {submittedStudents && !skeletonLoad && !isLoading && (
                    <tr>
                      {statusFilter.submitted && (
                        <td colSpan={8} className="no-admin">
                          No Assessments Submitted
                        </td>
                      )}
                      {statusFilter.online && (
                        <td colSpan={8} className="no-admin">
                          No Students In Session
                        </td>
                      )}
                      {statusFilter.offline && (
                        <td colSpan={8} className="no-admin">
                          No Students Found
                        </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {modalType === "CLEAR_ANSWERS" && (
        <PromptModal
          bodyText="Are you sure you want to clear all answers?"
          headerText="Clear Answers"
          cancelText="Cancel"
          okText="Clear"
          isShow={modalShow}
          setModal={setModalShow}
          action={clearAnswers}
        />
      )}

      {modalType === "SUBMIT_CONFIRMATION_MODAL" && (
        <SubmitModal
          isShow={modalShow}
          closeModal={() => setModalShow(false)}
          submitHandler={() => onSubmitClick()}
        />
      )}

      {modalType === "CLEAR_CONFIRMATION_MODAL" && (
        <ClearModal
          isShow={modalShow}
          closeModal={() => setModalShow(false)}
          clearHandler={() => onClearClick()}
        />
      )}

      {modalType === "PAUSE_STATUS" && !paused && (
        <PromptModal
          bodyText="Are you sure you want to pause the session?"
          headerText="Pause Session"
          cancelText="Cancel"
          okText="Pause"
          isShow={modalShow}
          setModal={setModalShow}
          action={pauseStatus}
        />
      )}

      {modalType === "PAUSE_STATUS" && paused && (
        <PromptModal
          bodyText="Are you sure you want to resume the session?"
          headerText="Resume Session"
          cancelText="Cancel"
          okText="Resume"
          isShow={modalShow}
          setModal={setModalShow}
          action={pauseStatus}
        />
      )}

      {modalType === "FORCE_SUBMIT" && (
        <PromptModal
          bodyText="Are you sure you want to force submit and close?"
          headerText="Force Submit"
          cancelText="Cancel"
          okText="Submit"
          isShow={modalShow}
          setModal={setModalShow}
          action={forceSubmit}
        />
      )}

      {modalType === "KICK_STUDENT" && (
        <PromptModal
          bodyText="This will remove student from the test. Are you sure?"
          headerText="Kick Student"
          cancelText="No"
          okText="Yes"
          isShow={modalShow}
          setModal={setModalShow}
          action={kickStudent}
        />
      )}

      {modalType === "NEW_ATTEMPT" && (
        <NewAttemptConfirmationModal
          isShow={modalShow}
          student_name={selectedStudent.student_name}
          onClose={() => {
            setSelectedStudent(null);
            setModalType("");
            setModalShow(false);
          }}
          onSave={() => {
            onNewAttempt(selectedStudent.id, is_formative);
            setSelectedStudent(null);
            setModalType("");
            setModalShow(false);
          }}
        />
      )}

      {openRandomModal && (
        <ChooseQuestionOrder
          isShow
          onClose={() => {
            setOpenRandomModal(false);
            setTempAssessment(null);
          }}
          onSave={onChangeRandom}
          isRandom={true}
          currentData={tempAssessment}
          loading={enableLoading}
          title={`Enable Assessment ${tempAssessment.name}`}
          description="Choose the order of questions below to make the assessment available in the Student Portal"
        />
      )}

      {openNoStudentsModal && (
        <NoStudents
          title="No Students"
          isShow={openNoStudentsModal}
          onClose={() => setOpenNoStudentsModal(false)}
          loading={false}
        />
      )}

      {notEnableModal && (
        <SuccessModal
          isShow
          headerText={`Enable Assessment ${tempAssessment.name}`}
          bodyText={`Cannot enable assessment. ${tempAssessment.assessment_time ? capitalizeFirstLetter(
            tempAssessment.assessment_time
              .split("until")[0]
              .replace(/[{()}]/g, "")
          ) : ""}`}
          closeModal={() => {
            setNotEnableModal(false);
            setTempAssessment(null);
          }}
        />
      )}

      <HowToReadSummative
        show={id !== -1 && !is_formative && readProgress}
        setModalShow={setReadProgress}
        isRandom={isRandom || isRandomized}
      />

      <HowToReadFormative
        show={id !== -1 && is_formative && readProgress}
        setModalShow={setReadProgress}
        isRandom={isRandom || isRandomized}
      />

      <HowToReadAll 
        show={id === -1 && readProgress}
        setModalShow={setReadProgress}
        isRandom={isRandom || isRandomized}
      />
      {selectedStudentName && showProgress && (
        <ShowProgress
          student={students?.find(
            (student: any) => student.student_name === selectedStudentName
          )}
          show={showProgress}
          setModalShow={setShowProgress}
          studentName={selectedStudentName}
          onlineStudents={onlineStudents}
        />
      )}
    </>
  );
};

export default MonitorSummative;
