import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    registerables,
    Chart
} from "chart.js";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
  
  Chart.register(...registerables);
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    PointElement,
    LineElement,
);
  
const getChartData = ( data: any, assessmentType:string) => {
    let labels: string[] = [];
    let dataLinesTemplate: any[] = [];
    let dataPointsTemplate: any[] = [];
    data = _.orderBy(data,)
    data?.map((item:any) => {
        labels.push(item.classroom_name);
        const assessmentData = assessmentType === "Formative" ? item.formative_test_data : item.summative_test_data;
        dataPointsTemplate.push(assessmentData.proficiency)
        dataLinesTemplate.push(assessmentData.total_tests_taken)
        return item;
    })
  
    return { dataLinesTemplate, dataPointsTemplate, labels };
};
  
export const getOptions = (totalData:any) => {
    return {
      responsive: true,
    //   maintainAspectRatio: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context: any) {
                        let label = context.dataset.type === 'bar' ? 'Running Proficiency Rate' : 'Formative Tests Taken';

                        if (label) {
                            label += ': ' + context.raw + (context.dataset.type === 'bar' ? '%' : '');
                        }
                        return label;
                    }
                }
            },
            title: {
                display: true,
            },
            legend: {
                display: false,
            },
            datalabels: false,
        },
        scales: {
            x:{
                ticks: {
                    color: '#323233',
                }
            },
            y: {
                type: 'linear',
                position: 'right',
                stacked: false,
                ticks: {
                    stepSize: 10,
                    color: '#323233',
                }
            },
            y1: {
                type: 'linear',
                position: 'left',
                beginAtZero: false,
                stepSize: 10,
                grid: {
                    drawOnChartArea: false
                },
                ticks: {
                    color: '#323233',
                }
            }
        },
    };
  }
  
export const getData = (data1: number[], data2: number[], labels: string[]) => {
    return {
        labels,
        datasets: [
            {
                type: "line",
                label: "Line Dataset",
                data: data2,
                borderColor: "#FFEC75",
                pointBackgroundColor: "#FFEC75",
                pointBorderColor: "#FFEC75",
                pointRadius: 3,
                yAxisID: 'y'
            },
            {
                type: "bar",
                label: "Bar Dataset",
                data: data1,
                backgroundColor: '#537AB7',
                yAxisID: 'y1',
            },
        ],
    };
};
  
export function ProgressChart(props: any) {
    const { data, assessmentType } = props;
    let { dataLinesTemplate, dataPointsTemplate, labels } = getChartData(data, assessmentType);
    const finalData = getData(dataPointsTemplate, dataLinesTemplate, labels);
    const options = getOptions(dataLinesTemplate);

    
  
    return (
      <div>
        <div >
          <Bar options={options as any} data={finalData as any} className="report__printChart-chart"/>
        </div>
      </div>
    );
}
  