import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { ReactComponent as PencilIcon } from "assets/img/pencil-icon-black-outline.svg";
import { ReactComponent as DeleteIcon } from "assets/img/del-img-icon-black-outline.svg";
import BackArrow from "assets/img/accordian-box-icon.png";
import GroupClose from "assets/img/group-close.svg";
import EditModal from "./Modals/EditModal";
import RichEditor from "./RichEditor";
import { QuestionnaireContext } from "./QuestionnaireContext";
import Cookies from "js-cookie";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import OnSaveModel from "./Modals/OnSaveModel";
import OnBackModal from "./Modals/OnBackModal";
import { useNavigate, useParams } from "react-router-dom";
import HtmlParser from "components/common/htmlParser";
import RemoveGroupingModal from "./Modals/RemoveGroupingModal";
import ToolTip from "components/common/Tooltip";

const QuestionContainer: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { assessmentName } = useSelector(
    (state: RootState) => state.cmsAssessment
  );
  const { assessmentType } = useParams();
  const isMath = Cookies.get("isMath") === "true";
  const {
    currentQuestion,
    questionIdx,
    sharePassageList,
    toggleHasPassage,
    setEditKey,
    onChange,
    onAnswerChange,
    tabIndex,
    setTabIndex,
    setBackModal,
    hasPassage,
    showTabs,
    updatedQuestions,
    groupedDataForQuestion,
    groupedList,
    disableDropdown,
    removeGrouping,
    groupedWithQuestion,
    groupedPassageDataForQuestion,
    joinArrayWithMaxElements,
    disablePassageDropdown,
    setShowGroupRemoveWarningModal,
    setTriggerPassageRemoveGroupingFor,
    sharesPassageWith,
    setIsSave,
    isExpand,
    setIsExpand,
    objectiveName,
  } = useContext(QuestionnaireContext);

  useEffect(() => {
    const divElement = document
      .getElementById("question")
      ?.querySelector("div");
    if (divElement && divElement.style?.maxWidth?.length) {
      divElement.style.maxWidth = "unset";
    }
  }, [currentQuestion]);

  if (!currentQuestion.id) {
    return null;
  }

  const {
    passage_directions,
    direction,
    passage_1,
    passage_2,
    question,
    choice_1,
    choice_2,
    choice_3,
    choice_4,
    answer,
    shares_passage_with,
  } = currentQuestion;

  const showExpandBtn = passage_1 || passage_2;

  return (
    <div className="mainContent">
      <div className={`mainContent-header ${isMath ? "changing-width" : ""}`}>
        <EditModal />
        <OnSaveModel />
        <OnBackModal />
        <RemoveGroupingModal />

        <div className="mainContent-header-empty"></div>

        <div className="mainContent-header-wrapper">
          <div
            className={`mainContent__answer ${isMath ? "changing-width" : ""} ${
              isExpand ? "answer-sec-expand" : ""
            }`}
          >
            <div className="mainContent__answer-content">
              <button
                className="btn btn-link mainContent__answer-link"
                onClick={() => {
                  updatedQuestions
                    ? setBackModal(true)
                    : navigate("/cms/select-assessment");
                }}
              >
                <img alt="Back Arrow" src={BackArrow} />
              </button>
              <div className="mainContent__answer-paragraph">
                {assessmentType === "formatives"
                  ? objectiveName || assessmentName
                  : assessmentName}
              </div>
              {showExpandBtn && (
                <button
                  onClick={() => setIsExpand(!isExpand)}
                  type="button"
                  className={`expand-desc`}
                >
                  <svg
                    className={`${isExpand ? "open" : ""}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="29"
                    viewBox="0 0 30 29"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.76172 28.9999C1.65715 28.9999 0.761719 28.1045 0.761719 26.9999V2.52295C0.761719 1.41838 1.65715 0.522949 2.76172 0.522949H27.2387C28.3433 0.522949 29.2387 1.41838 29.2387 2.52295V26.9999C29.2387 28.1045 28.3433 28.9999 27.2387 28.9999H2.76172ZM10.2547 10.0069V6.22295H27.3397V27.1019H10.2537V23.3019H8.35372V27.1019H2.66172V6.22295H8.35472V10.0229L10.2547 10.0069ZM10.2547 15.7069H8.35472V11.9069H10.2547V15.7069ZM15.9497 17.6069H12.1497V21.4069H15.9497V25.2069L23.5437 19.5119L15.9497 13.8119V17.6069ZM8.35472 21.4069H10.2547V17.6069H8.35472V21.4069Z"
                      fill="#ffffff"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          <div
            className={`mainContent__question ${
              isMath ? "changing-width" : ""
            } ${isExpand ? "question-sec-hide" : ""}`}
          >
            <div className="mainContent__question-content">
              <p className="mainContent__question-paragraph">{`Question ${
                questionIdx + 1
              }`}</p>
            </div>
          </div>
        </div>

        <div
          className={`mainContent-header-empty ${
            isExpand ? "empty-header-color" : ""
          }`}
        ></div>
      </div>
      {isMath && assessmentType === "summatives" && (
        <div
          className={`mainContent-grouping ${isMath ? "changing-width" : ""}`}
        >
          <div className="mainContent-grouping-empty"></div>

          <div className="mainContent-grouping-wrapper">
            <div
              className={`mainContent-grouping__container ${
                isMath ? "changing-width" : ""
              }`}
            >
              <div className="mainContent-grouping__content">
                <p className="mainContent-grouping__text">{`Start of Group`}</p>
              </div>
            </div>

            <div className="mainContent-grouping__group__share">
              <div>
                <select
                  className={`form-select mainContent-grouping__group__share-select ${
                    groupedWithQuestion > -1 ||
                    currentQuestion.id === groupedDataForQuestion?.parent ||
                    groupedDataForQuestion?.parent
                      ? "mainContent-grouping__group__share-select-grouped"
                      : "mainContent-grouping__group__share-select-not-grouped"
                  }`}
                  onChange={(event) =>
                    onChange(
                      "grouped_with_question",
                      event.target.value === "-1"
                        ? null
                        : parseInt(event.target.value)
                    )
                  }
                  value={
                    (currentQuestion.id === groupedDataForQuestion?.parent
                      ? groupedDataForQuestion?.parent
                      : groupedWithQuestion > -1
                      ? groupedWithQuestion
                      : -1) || -1
                  }
                  disabled={disableDropdown}
                >
                  <option value={-1}>Not Grouped</option>
                  {groupedList.map((q) => (
                    <option key={q.id} value={q.id}>
                      {q.questionNo}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {!!groupedDataForQuestion && (
              <div
                className={`mainContent-grouping__container ${
                  isMath ? "changing-width" : ""
                }`}
              >
                <div className="mainContent-grouping__group__questions__content">
                  <p className="mainContent-grouping__group__questions__text">{`Grouped: ${groupedDataForQuestion.questionNumber.join(
                    ", "
                  )}`}</p>
                </div>
              </div>
            )}

            {!!groupedDataForQuestion && (
              <div
                className={`mainContent-grouping__close_image ${
                  isMath ? "changing-width" : ""
                }`}
              >
                <div className="mainContent-grouping__close_image__content">
                  <img
                    alt="group-close"
                    className="mainContent-grouping__close_image_src"
                    src={GroupClose}
                    onClick={() =>
                      removeGrouping(groupedDataForQuestion, false)
                    }
                  ></img>
                </div>
              </div>
            )}
          </div>

          <div className="mainContent-grouping-empty"></div>
        </div>
      )}

      <div className={`mainContent__main ${isMath ? "switch-off-column" : ""}`}>
        <div className="mainContent__main-wrapper"></div>
        {!isMath && (
          <div
            className={`answers ${isMath ? "switch-off-column" : ""} ${
              isExpand ? "answer-sec-expand" : ""
            }`}
          >
            
            <div className={`answers__passage`}>
              <div className="d-flex align-items-center answers__passage-paragraph-toggle-container">
                <p className="answers__passage-paragraph">Has Passage</p>
                <label className={`switch switch-small mb-0`}>
                  <input
                    className={`switch-checkbox`}
                    type="checkbox"
                    checked={hasPassage}
                    disabled={
                      groupedPassageDataForQuestion?.parent &&
                      groupedPassageDataForQuestion?.parent !==
                        currentQuestion.id
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (
                        groupedPassageDataForQuestion?.parent &&
                        !e.target.checked
                      ) {
                        setTriggerPassageRemoveGroupingFor(1);
                        setShowGroupRemoveWarningModal(true);
                      } else {
                        toggleHasPassage(e.target.checked);
                      }
                    }}
                  />
                  <span className="switch-slider switch-slider-small"></span>
                </label>
              </div>
              {!!groupedPassageDataForQuestion && (
                <div className={`answers__passage__grouped-container`}>
                  <div className="answers__passage__grouped__questions-content">
                    <div className="tool-tooltip">
                      <ToolTip
                        content={`${groupedPassageDataForQuestion.questionNumber}`}
                      >
                        <p className="answers__passage__grouped__questions-text">{`Shared: ${joinArrayWithMaxElements(
                          groupedPassageDataForQuestion.questionNumber,
                          3
                        )}`}</p>
                      </ToolTip>
                    </div>
                  </div>
                </div>
              )}
              {!!groupedPassageDataForQuestion && (
                <div
                  className={`answers__passage__grouping__close_image ${
                    isMath ? "changing-width" : ""
                  }`}
                >
                  <div className="answers__passage__grouping__close_image_content">
                    <img
                      alt="group-close"
                      className="answers__passage__grouping__close_image_src"
                      src={GroupClose}
                      onClick={() => {
                        setTriggerPassageRemoveGroupingFor(2);
                        setShowGroupRemoveWarningModal(true);
                      }}
                    ></img>
                  </div>
                </div>
              )}
              <div className="answers__passage-share">
                <p className={`answers__passage-paragraph`}>Shares</p>

                <div>
                  <select
                    className="form-select answers__passage-select"
                    onChange={(event) =>
                      onChange(
                        "shares_passage_with",
                        event.target.value === "-1"
                          ? null
                          : parseInt(event.target.value)
                      )
                    }
                    value={sharesPassageWith || -1}
                    disabled={!hasPassage || disablePassageDropdown}
                  >
                    <option value={-1}>No</option>
                    {sharePassageList.map((q) => (
                      <option key={q.id} value={q.id}>
                        {q.questionNo}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div
              className={`answers-content ${
                !hasPassage || shares_passage_with ? "wrapper-overlay" : ""
              }`}
            >
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index, _, event) => {
                  const id = (event.target as Element).getAttribute("data-id");
                  id !== "delete-icon" && setTabIndex(index);
                }}
              >
                <TabList>
                  {showTabs ? (
                    <>
                      <Tab>Tab 1</Tab>

                      <Tab>
                        <div>
                          <span>Tab 2</span>
                          <DeleteIcon
                            style={{
                              position: "absolute",
                              right: 19,
                            }}
                            className=""
                            onClick={() => onChange("passage_2", null!)}
                          />
                        </div>
                      </Tab>
                    </>
                  ) : (
                    <>
                      <div
                        className="react-tabs__tab react-tabs__tab--selected"
                        style={{ cursor: "default" }}
                      >
                        <button
                          className="react-tabs-addtab"
                          onClick={() => {
                            onChange("passage_2", "");
                          }}
                        >
                          Add Tab
                        </button>
                      </div>
                      <div
                        className="react-tabs__tab"
                        style={{ cursor: "default" }}
                      ></div>
                    </>
                  )}
                </TabList>

                <div
                  className={`answers__editor ${!isMath ? "math-editor" : ""}`}
                >
                  <div
                    className="answers__editor-tab"
                    onClick={() => setEditKey("passage_directions")}
                  >
                    <span className="answers__editor-icon">
                      <PencilIcon />
                    </span>
                    <span className="answers__editor-instructions">
                      <HtmlParser
                        html={passage_directions || "passage_directions"}
                        id="passage_directions"
                      />
                    </span>
                  </div>
                  <div className="answers__editor-edit">
                    {showTabs ? (
                      <>
                        <TabPanel>
                          <RichEditor
                            value={passage_1 || ""}
                            onChange={(value) => onChange("passage_1", value)}
                            setIsSaveEnable={() => {
                              console.log("setIsSaveEnable");
                              setIsSave(true);
                            }}
                            minHeight="60vh"
                          />
                        </TabPanel>

                        <TabPanel>
                          <RichEditor
                            value={passage_2 || ""}
                            onChange={(value) => onChange("passage_2", value)}
                            setIsSaveEnable={() => setIsSave(true)}
                            minHeight="60vh"
                          />
                        </TabPanel>
                      </>
                    ) : (
                      <>
                        <RichEditor
                          value={passage_1 || ""}
                          onChange={(value) => onChange("passage_1", value)}
                          setIsSaveEnable={() => setIsSave(true)}
                          minHeight="60vh"
                        />
                      </>
                    )}
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        )}
        <div
          className={`questions ${isMath ? "switch-off-column" : ""} ${
            isExpand ? "question-sec-hide" : ""
          }`}
        >
          <div className="questions-wrapper">
            <div className="questions__question">
              {
                <div
                  className="questions__dialogue"
                  onClick={() => setEditKey("direction")}
                >
                  <span className="questions__dialogue-icon">
                    <PencilIcon />
                  </span>
                  <p>
                    <HtmlParser html={direction || ""} id="direction" />
                  </p>
                </div>
              }
              <div
                id="chr-ques"
                className="questions__question-item instruction"
              >
                <div className="d-flex" onClick={() => setEditKey("question")}>
                  <span className="questions__question-icon mt-0">
                    <PencilIcon />
                  </span>
                  <div className="questions__question-instruction">
                    <HtmlParser html={question || ""} id="question" />
                  </div>
                </div>
              </div>
              <div className="questions__question-item">
                <div className="questions__question-icons">
                  <span
                    className="questions__question-icon"
                    onClick={() => setEditKey("choice_1")}
                  >
                    <PencilIcon />
                  </span>
                  <span
                    className={`questions__question-suggest first ${
                      answer === 0 ? "fill" : ""
                    }`}
                    onClick={() => onAnswerChange(0)}
                  >
                    A
                  </span>
                </div>
                <p className="questions__question-choice">
                  <HtmlParser html={choice_1 || ""} id="choice_1" />
                </p>
              </div>
              <div className="questions__question-item">
                <div className="questions__question-icons">
                  <span
                    className="questions__question-icon"
                    onClick={() => setEditKey("choice_2")}
                  >
                    <PencilIcon />
                  </span>
                  <span
                    className={`questions__question-suggest ${
                      answer === 1 ? "fill" : ""
                    }`}
                    onClick={() => onAnswerChange(1)}
                  >
                    B
                  </span>
                </div>
                <p className="questions__question-choice">
                  <HtmlParser html={choice_2 || ""} id="choice_2" />
                </p>
              </div>
              <div className="questions__question-item">
                <div className="questions__question-icons">
                  <span
                    className="questions__question-icon"
                    onClick={() => setEditKey("choice_3")}
                  >
                    <PencilIcon />
                  </span>
                  <span
                    id="choice3"
                    className={`questions__question-suggest ${
                      answer === 2 ? "fill" : ""
                    }`}
                    onClick={() => onAnswerChange(2)}
                  >
                    C
                  </span>
                </div>
                <p className="questions__question-choice">
                  <HtmlParser html={choice_3 || ""} id="choice_3" />
                </p>
              </div>
              <div className="questions__question-item">
                <div className="questions__question-icons">
                  <span
                    className="questions__question-icon"
                    onClick={() => setEditKey("choice_4")}
                  >
                    <PencilIcon />
                  </span>
                  <span
                    id="choice4"
                    className={`questions__question-suggest ${
                      answer === 3 ? "fill" : ""
                    }`}
                    onClick={() => onAnswerChange(3)}
                  >
                    D
                  </span>
                </div>
                <p className="questions__question-choice">
                  <HtmlParser html={choice_4 || ""} id="choice_4" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuestionContainer;
