// @ts-nocheck
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { Jodit } from 'jodit'
import { FileImage } from './UploadToS3';
import { debounce } from "lodash";
import PasteModal from "./pasteModal";
import { convertPreviousSqrt, getSqrtHtml } from "util/convertSqrt";
import { ElementTohtml, htmlToElement } from "util/htmlToElement";

interface IRichEditor {
    value: string;
    onChange: (value: string) => void;
    minHeight?: string;
    setIsSaveEnable?: Function
}

const RichEditor: FC<IRichEditor> = ({ value, onChange, minHeight, setIsSaveEnable }: IRichEditor) => {
    const [content, setContent] = useState<string>(null!);
    const [showModal, setShowModal] = useState<boolean>(false);

    const imagePropUpdatedRef = useRef<boolean>(false);

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        const imageUpload = (editor: any) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();

            input.onchange = async function () {

                if (!input.files || !input.files.length) {
                    return;
                }

                const imageFile = input.files[0];

                if (!imageFile) {
                    return;
                }

                if (!imageFile.name.match(/\.(jpg|jpeg|png|webp|gif|svg)$/)) {
                    return;
                }

                const imageInfo = await FileImage(imageFile);;

                insertImage(editor, imageInfo);

            };
        }


        const addQuotes = (editor: any) => {
            // TODO select editorelement with uniqe class name
            const editorelement = document.querySelectorAll('.jodit-react-container')
            const length = editorelement.length
            const focusNode = window.getSelection().focusNode
            const blockNode = isBlockElement(focusNode) ? focusNode : focusNode?.parentNode;
            const isQuoted = blockNode.id === "quotesAppliedToNode"
            console.log({ isQuoted }, editorelement, editorelement[length - 1]?.contains(blockNode))
            if (editorelement[length - 1]?.contains(blockNode)) {
                blockNode.style.border = !isQuoted ? "2px black solid" : ''
                blockNode.style.fontSize = !isQuoted ? '18px' : ''
                blockNode.style.color = !isQuoted ? '#4B4B4B' : ''
                blockNode.style.padding = !isQuoted ? '20px' : ''
                blockNode.style.display = !isQuoted ? 'flex' : ''
                blockNode.style.justifyContent = !isQuoted ? 'center' : ''
                blockNode.style.alignItems = !isQuoted ? 'center' : ''
                blockNode.style.textAlign = !isQuoted ? 'center' : ''
                blockNode.setAttribute('id', !isQuoted ? 'quotesAppliedToNode' : '')
            }
        }

        const addFraction = (editor: any) => {
            editor.selection.insertHTML(`
            <div class="math_frac">
                <span>
                    <b>1</b>
                </span>
                <b>
                    <span class="symbol">/</span>
                    <span class="bottom">
                        <b>2</b>
                    </span>
                </b>
            </div>`
            );
        }

        const addSqrt = (editor: any) => {
            const sqrtHtml = getSqrtHtml('2xy')
            editor.selection.insertHTML(`
            ${sqrtHtml}`
            );
        }

        const uploadImageButton = () => {
            Jodit.defaultOptions.controls.uploadImage = {
                name: 'Insert image',
                iconURL: "https://www.svgrepo.com/show/13704/picture.svg",
                exec: (async (editor) => {
                    await imageUpload(editor);
                })
            };
        }

        const addQuotesButton = () => {

            Jodit.defaultOptions.controls.addQuotes = {
                name: 'Add Quotes',
                iconURL: "https://www.shareicon.net/data/128x128/2015/12/09/684803_button_512x512.png",
                exec: (async (editor) => {
                    addQuotes(editor);
                })
            };
        }

        const addFractionButton = () => {

            Jodit.defaultOptions.controls.addFraction = {
                name: 'Add Fraction',
                iconURL: "/half.png",
                exec: (async (editor) => {
                    addFraction(editor);
                })
            };
        }

        const addSqrtButton = () => {
            Jodit.defaultOptions.controls.addSqrt = {
                name: 'Add Square root',
                iconURL: "/square-root.svg",
                exec: (async (editor) => {
                    console.log('addSqrt', editor);
                    addSqrt(editor);
                })
            };
        }


        const getStylesByTag = (tag: string) => {

            switch (tag) {
                case 'h1':
                    return {
                        fontSize: '20px',
                        color: '#4B4B4B',
                        bold: true,
                        italic: false
                    }
                case 'h2':
                    return {
                        fontSize: '19px',
                        color: '#4B4B4B',
                        bold: true,
                        italic: false
                    }
                case 'h3':
                    return {
                        fontSize: '19px',
                        color: '#4B4B4B',
                        bold: false,
                        italic: false
                    }
                case 'blockquote':
                    return {
                        fontSize: '18px',
                        color: '#4B4B4B',
                        bold: false,
                        italic: true
                    }
                default:
                    return {
                        fontSize: '19px',
                        color: '#4B4B4B',
                        bold: false,
                        italic: false
                    }
            }

        }

        const isBlockElement = (element) => {
            try {
                const cStyle = element.currentStyle || window.getComputedStyle(element, "");
                return cStyle.display === 'block';
            } catch {
                return false
            }

        }

        Jodit.defaultOptions.controls.paragraph.list = {
            h1: 'Heading 1',
            h2: 'Heading 2',
            h3: 'Heading 3',
            blockquote: 'Byline',
            p: 'Body',
        }


        Jodit.defaultOptions.controls.paragraph.exec = (editor, current, { control }) => {
            const tag = control.args && control.args[0]
            const focusNode = window.getSelection().focusNode

            const blockNode = isBlockElement(focusNode) ? focusNode : focusNode?.parentNode;
            const editorelement = document.querySelector('.jodit-react-container')
            if (editorelement?.contains(blockNode)) {

                const { fontSize, color, bold, italic } = getStylesByTag(tag)

                if (blockNode) {
                    blockNode.style.fontSize = fontSize
                    blockNode.style.color = color
                    blockNode.style.fontFamily = 'Arimo'
                    blockNode.style.fontWeight = bold ? 'bold' : 'normal'
                    blockNode.style.fontStyle = italic ? 'italic' : 'normal'
                }
            }

            return false;
        }

        uploadImageButton()
        addQuotesButton()
        addFractionButton()
        addSqrtButton();

    }, [])


    // some dirty work to handle editor content
    useEffect(() => {
        if (value && value !== content) {
            firstUpdate.current = true
            updateState(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])


    useEffect(() => {

        if (!firstUpdate.current && value !== content) {
            onChange(content)
            setIsSaveEnable && setIsSaveEnable()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    const handlePaste = (e: ClipboardEvent) => {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        let isImage = false;
        items.forEach((item: any) => {
            if(!isImage && item.type.includes('image')){
                isImage = true;
            }
        })
        
        if(isImage){
            setShowModal(true);
            return false;
        }

        const html = (e.clipboardData || e.originalEvent.clipboardData).getData('text/html');

        if (!html) return;
        
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const images = doc.querySelectorAll('img');

        if (!images.length) return;

        setShowModal(true);
        return false;
    }


    const EditConfig = useMemo(() => ({
        readonly: false,
        defaultFontSizePoints: "px",
        placeholder: '',
        removeButtons: ['video', 'file', 'print', 'link', 'source', 'about', 'image'],
        removeButtonsMD: ['video', 'file', 'print', 'link', 'source', 'about', 'image'],
        removeButtonsSM: ['video', 'file', 'print', 'link', 'source', 'about', 'image'],
        removeButtonsXS: ['video', 'file', 'print', 'link', 'source', 'about', 'image'],
        buttons: "font,fontsize,bold,italic,underline,strikethrough,brush,eraser,ul,ol,paragraph,classSpan,align,lineHeight,superscript,subscript,addFraction,addSqrt,spellcheck,cut,fullsize,source,table",
        extraButtons: ["uploadImage", "addQuotes"],
        minHeight: minHeight,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        autofocus: false,
        toolbarAdaptive: false,
        askBeforePasteHTML: false,
        controls: {
            font: {
                list: Jodit.atom({
                    'Nunito': 'Nunito',
                    'Arimo': 'Arimo',
                    'Roboto Medium,Arial,sans-serif': 'Roboto',
                    'Helvetica,sans-serif': 'Helvetica',
                    'Arial,Helvetica,sans-serif': 'Arial',
                    'Georgia,serif': 'Georgia',
                    'Impact,Charcoal,sans-serif': 'Impact',
                    'Tahoma,Geneva,sans-serif': 'Tahoma',
                    "'Times New Roman',Times,serif": 'Times New Roman',
                    'Verdana,Geneva,sans-serif': 'Verdana'
                      
                })
            },
            fontsize: {
                list: Jodit.atom([8,9,10,11,12,14,16,18,19,24,30,36,48,60,72,96])
            },
            classSpan: {
                list: {
                    enabled: 'enabled',
                    disabled: 'disabled',
                    activated: 'activated',
                    warning: 'warning',
                    error: 'error'

                }
            }
        },
        style: {
            fontFamily: 'Nunito',
            fontSize: '19px'
        },
        events: {
            beforePaste: handlePaste
        },
        cleanHTML: {
            fillEmptyParagraph: false
        }
        
    }), [minHeight])


    const insertImage = (editor: any, url: any) => {
        const image = editor.selection.j.createInside.element('img');
        image.setAttribute('src', url);
        editor.selection.insertNode(image);
    }

    const cleaFraction = (elem: any) => {
        elem.childNodes.forEach(item => {
            if(item.nodeType === 3){
                item.textContent = item.textContent.trim();
                return;
            }else{
                cleaFraction(item);
            }
        });

        return;
    }

    const formatContant = (content: string) => {
        
        const div = htmlToElement(content);
        const math = div.querySelectorAll(".math_frac");

        convertPreviousSqrt(div);

        math.forEach(fraction => {
            cleaFraction(fraction);
            const prevElement = fraction.previousElementSibling
            const nextElement = fraction.nextElementSibling;
            if (prevElement && prevElement.nodeName === 'P' && !prevElement.className?.includes("math_frac_inline")) {
                prevElement?.classList.add('math_frac_inline')
            }
            if (nextElement && nextElement.nodeName === 'P' && !nextElement.className?.includes("math_frac_inline")) {
                nextElement?.classList.add('math_frac_inline')
            }

            const isSpanRequired = ['spanNextToFraction', 'math_frac_inline'].some(className => nextElement?.className.includes(className))
            console.log({ isSpanRequired })
            if (!nextElement || !isSpanRequired) {
                console.log("Inserting")
                const newSpanElement = document.createElement('span')
                newSpanElement.classList.add('spanNextToFraction')
                newSpanElement.innerText = "."
                fraction.insertAdjacentElement('afterend', newSpanElement)
            }

        });

        const sqrtElems = div.querySelectorAll(".sqr-root");
        sqrtElems.forEach(item => {
            cleaFraction(item);
            if(!item.nextSibling){
                const newtext = document.createTextNode("\u00A0");
                item.parentNode?.appendChild(newtext);
            }else if(item.nextSibling && !item.nextSibling.textContent){
                const newtext = document.createTextNode("\u00A0");
                item.parentNode.insertBefore(newtext, item.nextSibling);
            }

            if(item.nextElementSibling){
                item.nextElementSibling?.classList.add("sqr-root_inline")
            }
            
            if(item.previousElementSibling){
                item.previousElementSibling.classList.add("sqr-root_inline")
            }
        })

        content = ElementTohtml(div)
        setContent(content)
    }

    const makeParaInlineWithFraction = () => {
        const joditTextParas = document.querySelectorAll(".jodit-wysiwyg > .math_frac");
        if(joditTextParas.length){
            joditTextParas.forEach(item => {
                if(item.nextSibling?.nodeName === "P" && !item.nextSibling?.className?.includes("math_frac_inline")){
                    item.nextSibling?.classList.add("math_frac_inline")
                }

                if(item.previousSibling?.nodeName === "P" && !item.previousSibling?.className?.includes("math_frac_inline")){
                    item.previousSibling?.classList.add("math_frac_inline")
                }
            });
        }
    }


    useEffect(() => {
        if(!content) return;
        
        const handler = (e) => {
            setTimeout(() => {
                const dialogBox = document.querySelector(".jodit-dialog__panel");
                if(!dialogBox) return;
                imagePropUpdatedRef.current = true;
                
                const modalOpned = document.querySelector(".fade.modal.show");
                if(modalOpned){
                    modalOpned.style.display = "none";
                }
                

                const applyBtn = document.querySelector(".jodit-ui-button_ok");
                if(!applyBtn) return;

                applyBtn.addEventListener("click", () => {
                    imagePropUpdatedRef.current = false;
                    const modalOpned = document.querySelector(".fade.modal.show");
                    if(modalOpned){
                        modalOpned.style.display = "block";
                    }
                })

                const closeBtn = dialogBox.querySelector(".jodit-toolbar-button_close > button");

                closeBtn?.addEventListener("click", () => {
                    imagePropUpdatedRef.current = false;
                    const modalOpned = document.querySelector(".fade.modal.show");
                    if(modalOpned){
                        modalOpned.style.display = "block";
                    }
                })

                const deleteBtn = dialogBox.querySelector(".jodit-ui-button_bin");
                deleteBtn?.addEventListener("click", () => {
                    imagePropUpdatedRef.current = false;
                    const modalOpned = document.querySelector(".fade.modal.show");
                    if(modalOpned){
                        modalOpned.style.display = "block";
                    }
                })
            },100)
        }
        document.body.addEventListener("click", handler);

        makeParaInlineWithFraction();

        return () => {
            document.body.removeEventListener("click", handler)
        }
    },[content])

    const updateState = debounce((content) => {
        formatContant(content)
    }, 0)

    return (
        <>
            <Row className="w-100 mx-auto">
                <Col className="text-white p-0">
                    <div
                        onClick={() => {
                            firstUpdate.current = false;
                        }}
                    >
                        <JoditEditor
                            value={content}
                            config={EditConfig}
                            onChange={(con) => {
                                !firstUpdate.current && setIsSaveEnable && setIsSaveEnable();
                                if(imagePropUpdatedRef.current){
                                    updateState(con);
                                }
                            }}
                            onBlur={(newContent) => {
                                updateState(newContent)
                            }}
                            tabIndex={1}
                        />
                    </div>
                </Col>
            </Row>

            {showModal && (
                <PasteModal 
                    closeModal={() => setShowModal(false)}
                />
            )}
        </>
    );
}

export default RichEditor;