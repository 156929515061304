import moment from "moment";
import PrintFooter from "components/common/report/Print/footer";
import { browserHeights } from "util/index";
import { ProgressChart } from "./chart";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { Fragment } from "react";

const FormativeProgressChartPrint = ({data,schoolId}:any) => {
  const activity = useSelector((State: RootState) => State.activity);

  const selectedSchool = activity.schoolsList.find((item: any) => item.id === schoolId);

  let i=0;
  let pages: any =[];
  while(i < data?.length){
    pages.push(data.slice(i, i+33))
    i += 33;
  }
  return (
    <>
      {pages.map((page: any) => {
        let j=0;
        let formattedClassrooms: any = [];
        while(j < page?.length){
          formattedClassrooms.push(page.slice(j, j+11))
          j += 11;
        } 
        return (
          <div className="school-progress-report-print">
            <div className={`text-white ${browserHeights}`}>
              <div className="print-content">
                <div className="bg-print">
                  <div className="print-report__header d-flex justify-content-between align-items-end">
                    <div className="black-font">
                      <h3 className="title">Formative Progress Chart</h3>
                    </div>
                    <div className="black-font">
                      <h3 className="black-font title text-right">
                        {moment(new Date()).format("M-D-YY")}
                      </h3>
                      <p className="black-font print-report__header-text text-right">{selectedSchool?.school_name || ''}</p>
                    </div>
                  </div>
                </div>
                <div className="bg-print">
                  <div className="report__performance mt-4">
                    <div className="d-flex justify-content-center">
                      <div className="formative-test">
                        <div></div>
                        <p>Formative Tests Taken (#)</p>
                      </div>
                      <div className="running-rate">
                        <div></div>
                        <p>Running Proficiency Rate (%)</p>
                      </div>
                    </div>
                  </div>
                  {formattedClassrooms.map((classrooms: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <div className="report__printChart">
                          <div className="report__printChart-container">
                            <ProgressChart
                              data={classrooms}
                              assessmentType={"Formative"}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
            <PrintFooter/>
          </div>
        )
      })}
    </>
  );
};
export default FormativeProgressChartPrint;
