export const ActionType: any = {
  SET_SOCKET_DATA: "SET_SOCKET_DATA",
  // User Actions

  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",
  USER_LOGOUT: "USER_LOGOUT",
  USER_CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",
  USER_CHANGE_PASSWORD_FAIL: "USER_CHANGE_PASSWORD_FAIL",
  USER_FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
  USER_FORGOT_PASSWORD_FAIL: "USER_FORGOT_PASSWORD_FAIL",
  RESET_REDUCER_ACTIVITY: "RESET_REDUCER_ACTIVITY",
  USER_PROFILE_DATA: "USER_PROFILE_DATA",
  USER_PROFILE_NOTIFICATION_COUNT: "USER_PROFILE_NOTIFICATION_COUNT",
  USER_PROFILE_DATA_FAIL: "USER_PROFILE_DATA_FAIL",
  // Activity

  GET_TEACHER_GRADE_BY_SCHOOL_ID: "GET_TEACHER_GRADE_BY_SCHOOL_ID",
  GET_TEACHER_GRADE_BY_SCHOOL_ID_FAIL: "GET_TEACHER_GRADE_BY_SCHOOL_ID_FAIL",
  SET_SCHOOL_ID: "SET_SCHOOL_ID",
  SET_TEACHER_ID: "SET_TEACHER_ID",
  SET_GRADE_ID: "SET_GRADE_ID",
  SET_SUBJECT_ID: "SET_SUBJECT_ID",
  SET_ACTIVITY_CLASSROOM_ID: "SET_ACTIVITY_CLASSROOM_ID",
  SET_DISTRICT_ID: "SET_DISTRICT_ID",
  SET_FILTERS: "SET_FILTERS",
  GET_WELCOME_ACTIVITY_STATS: "GET_WELCOME_ACTIVITY_STATS",
  GET_WELCOME_ACTIVITY_STATS_FAIL: "GET_WELCOME_ACTIVITY_STATS_FAIL",
  GET_ACTIVITY_PROGRESS: "GET_ACTIVITY_PROGRESS",
  GET_ACTIVITY_PROGRESS_FAIL: "GET_ACTIVITY_PROGRESS_FAIL",
  GET_ACTIVITY_PD_PROGRESS: "GET_ACTIVITY_PD_PROGRESS",
  GET_ACTIVITY_PD_PROGRESS_FAIL: "GET_ACTIVITY_PD_PROGRESS_FAIL",

  GET_ACTIVITY_RESOURCES: "GET_ACTIVITY_RESOURCES",
  GET_ACTIVITY_RESOURCES_FAIL: "GET_ACTIVITY_RESOURCES_FAIL",

  GET_ACTIVITY_STATS: "GET_ACTIVITY_STATS",
  GET_ACTIVITY_STATS_FAIL: "GET_ACTIVITY_STATS_FAIL",

  GET_DISTRICTS: "GET_DISTRICTS",
  GET_DISTRICTS_FAIL: "GET_DISTRICTS_FAIL",
  UPDATE_DISTRICT_TOGGLE_LOADING: "UPDATE_DISTRICT_TOGGLE_LOADING",
  UPDATE_DISTRICT_RESOURCE_PERMISSION: "UPDATE_DISTRICT_RESOURCE_PERMISSION",

  GET_SCHOOLS: "GET_SCHOOLS",
  GET_GET_SCHOOLS_FAIL: "GET_GET_SCHOOLS_FAIL",

  GET_SCHOOLS_BY_DISTRICT: "GET_SCHOOLS_BY_DISTRICT",
  GET_SCHOOLS_BY_DISTRICT_FAIL: "GET_SCHOOLS_BY_DISTRICT",

  SET_ACTIVITY_INDICATOR: "SET_ACTIVITY_INDICATOR",

  SET_WELCOME_MESSAGE: "SET_WELCOME_MESSAGE",
  SET_WELCOME_MESSAGE_FAIL: "SET_WELCOME_MESSAGE_FAIL",
  RESET_TEACHERS: "RESET_TEACHERS",
  RESET_GRADES: "RESET_GRADES",
  SET_SCHOOL_PROGRESS_REPORT: "SET_SCHOOL_PROGRESS_REPORT",
  RESET_SCHOOL_PROGRESS_REPORT: "RESET_SCHOOL_PROGRESS_REPORT",

  // Classroom
  RESET_REDUCER_CLASSROOMS: "RESET_REDUCER_CLASSROOMS",
  SET_SUMMATIVE_REPORT: "SET_SUMMATIVE_REPORT",
  SET_GRADE_FREGUENCY_SUMMATIVE_REPORT: "SET_GRADE_FREGUENCY_SUMMATIVE_REPORT",
  RESET_GRADE_FREGUENCY_SUMMATIVE_REPORT:
    "RESET_GRADE_FREGUENCY_SUMMATIVE_REPORT",

  SET_CLASSROOM_TOOLTIP: "SET_CLASSROOM_TOOLTIP",
  GET_ALL_SCHOOLS: "GET_ALL_SCHOOLS",
  GET_CLASSROOMS_LIST: "GET_CLASSROOMS_LIST",
  GET_CLASSROOMS_LIST_FAIL: "GET_CLASSROOMS_LIST_FAIL",
  GET_CLASSROOMS_BY_SCHOOL_ID: "GET_CLASSROOMS_BY_SCHOOL_ID",
  SET_CLASSROOM_SCHOOL_ID: "SET_CLASSROOM_SCHOOL_ID",
  SET_CLASSROOM_ID: "SET_CLASSROOM_ID",
  SET_LAST_DISTRICT_ID: "SET_LAST_DISTRICT_ID",
  GET_ALL_FORMATIVES: "GET_ALL_FORMATIVES",
  GET_ALL_FORMATIVES_FAIL: "GET_ALL_FORMATIVES_FAIL",
  GET_ALL_SUMMATIVES: "GET_ALL_SUMMATIVES",
  GET_ALL_SUMMATIVES_FAIL: "GET_ALL_SUMMATIVES_FAIL",
  SET_GRADE: "SET_GRADE",
  SET_SUBJECT: "SET_SUBJECT",
  SET_DISTRICT_LOGO: "SET_DISTRICT_LOGO",
  SET_SELECTED_FILTERS: "SET_SELECTED_FILTERS",
  RESET_SELECTED_FILTERS: "RESET_SELECTED_FILTERS",
  RESET_CLASSROOMS_ARRAYS: "RESET_CLASSROOMS_ARRAYS",
  ADD_ANSWERS_LOADING: "UPDATE_ANSWERS_LOADING",
  ADD_ANSWERS_SUCCESS: "UPDATE_ANSWERS_SUCCESS",
  ADD_ANSWERS_ERROR: "UPDATE_ANSWERS_ERROR",
  UPDATE_ANSWERS_LOADING: "UPDATE_ANSWERS_LOADING",
  UPDATE_ANSWERS_SUCCESS: "UPDATE_ANSWERS_SUCCESS",
  UPDATE_ANSWERS_ERROR: "UPDATE_ANSWERS_ERROR",
  DELETE_ANSWERS_SCORE_LOADING: "DELETE_ANSWERS_SCORE_LOADING",
  DELETE_ANSWERS_SCORE_SUCCESS: "DELETE_ANSWERS_SCORE_SUCCESS",
  DELETE_ANSWERS_SCORE_ERROR: "DELETE_ANSWERS_SCORE_ERROR",
  UPDATE_AVAILIBILTY_LOADING: "UPDATE_AVAILIBILTY_LOADING",
  UPDATE_AVAILIBILTY_SUCCESS: "UPDATE_AVAILIBILTY_SUCCESS",
  UPDATE_AVAILIBILTY_ERROR: "UPDATE_AVAILIBILTY_ERROR",
  GET_STUDENT_REPORT_DATA: "GET_STUDENT_REPORT_DATA",
  GET_STUDENT_REPORT_DATA_ERROR: "GET_STUDENT_REPORT_DATA_ERROR",
  GET_STUDENT_SINGLE_REPORT_DATA: "GET_STUDENT_SINGLE_REPORT_DATA",
  GET_STUDENT_SINGLE_REPORT_DATA_ERROR: "GET_STUDENT_SINGLE_REPORT_DATA_ERROR",
  GET_STUDENT_SINGLE_SBR_REPORT_DATA: "GET_STUDENT_SINGLE_SBR_REPORT_DATA",
  GET_STUDENT_SINGLE_SBR_REPORT_DATA_ERROR:
    "GET_STUDENT_SINGLE_SBR_REPORT_DATA_ERROR",
  GET_STUDENT_DATA_REPORT_DATA: "GET_STUDENT_DATA_REPORT_DATA",
  GET_STUDENT_DATA_REPORT_DATA_ERROR: "GET_STUDENT_DATA_REPORT_DATA_ERROR",

  SET_WELCOME_MESSAGE_CLASSROOM: "SET_WELCOME_MESSAGE_CLASSROOM",
  SET_WELCOME_MESSAGE_CLASSROOM_FAIL: "SET_WELCOME_MESSAGE_CLASSROOM_FAIL",

  // Management
  RESET_MANAGEMENT_REDUCER: "RESET_REDUCER_ACTIVITY",
  ADD_SUPER_ADMIN_LOADING: "ADD_SUPER_ADMIN_LOADING",
  ADD_SUPER_ADMIN_SUCCESS: "ADD_SUPER_ADMIN_SUCCESS",
  ADD_SUPER_ADMIN_ERROR: "ADD_SUPER_ADMIN_ERROR",
  GET_SUPER_ADMIN_LOADING: "GET_SUPER_ADMIN_LOADING",
  GET_SUPER_ADMIN_SUCCESS: "GET_SUPER_ADMIN_SUCCESS",
  GET_SUPER_ADMIN_ERROR: "GET_SUPER_ADMIN_ERROR",
  DELETE_SUPER_ADMIN_LOADING: "DELETE_SUPER_ADMIN_LOADING",
  DELETE_SUPER_ADMIN_SUCCESS: "DELETE_SUPER_ADMIN_SUCCESS",
  DELETE_SUPER_ADMIN_ERROR: "DELETE_SUPER_ADMIN_ERROR",
  UPDATE_SUPER_ADMIN_LOADING: "UPDATE_SUPER_ADMIN_LOADING",
  UPDATE_SUPER_ADMIN_SUCCESS: "UPDATE_SUPER_ADMIN_SUCCESS",
  UPDATE_SUPER_ADMIN_ERROR: "UPDATE_SUPER_ADMIN_ERROR",
  SET_SUPER_ADMIN_FLAG: "SET_SUPER_ADMIN_FLAG",

  ADD_DISTRICT_ADMIN_LOADING: "ADD_DISTRICT_ADMIN_LOADING",
  ADD_DISTRICT_ADMIN_SUCCESS: "ADD_DISTRICT_ADMIN_SUCCESS",
  ADD_DISTRICT_ADMIN_ERROR: "ADD_DISTRICT_ADMIN_ERROR",
  GET_DISTRICT_ADMIN_LOADING: "GET_DISTRICT_ADMIN_LOADING",
  GET_DISTRICT_ADMIN_SUCCESS: "GET_DISTRICT_ADMIN_SUCCESS",
  GET_DISTRICT_ADMIN_ERROR: "GET_DISTRICT_ADMIN_ERROR",
  DELETE_DISTRICT_ADMIN_LOADING: "DELETE_DISTRICT_ADMIN_LOADING",
  DELETE_DISTRICT_ADMIN_SUCCESS: "DELETE_DISTRICT_ADMIN_SUCCESS",
  DELETE_DISTRICT_ADMIN_ERROR: "DELETE_DISTRICT_ADMIN_ERROR",
  UPDATE_DISTRICT_ADMIN_LOADING: "UPDATE_DISTRICT_ADMIN_LOADING",
  UPDATE_DISTRICT_ADMIN_SUCCESS: "UPDATE_DISTRICT_ADMIN_SUCCESS",
  UPDATE_DISTRICT_ADMIN_ERROR: "UPDATE_DISTRICT_ADMIN_ERROR",
  SET_DISTRICT_ADMIN_FLAG: "SET_DISTRICT_ADMIN_FLAG",
  UPDATE_ADMIN_IS_SUPERINTENDENT_SUCCESS:
    "UPDATE_ADMIN_IS_SUPERINTENDENT_SUCCESS",
  UPDATE_ADMIN_IS_SUPERINTENDENT_ERROR: "UPDATE_ADMIN_IS_SUPERINTENDENT_ERROR",

  ADD_DISTRICT_LOADING: "ADD_DISTRICT_LOADING",
  ADD_DISTRICT_SUCCESS: "ADD_DISTRICT_SUCCESS",
  ADD_DISTRICT_ERROR: "ADD_DISTRICT_ERROR",
  GET_DISTRICT_LOADING: "GET_DISTRICT_LOADING",
  GET_DISTRICT_SUCCESS: "GET_DISTRICT_SUCCESS",
  GET_DISTRICT_ERROR: "GET_DISTRICT_ERROR",
  DELETE_DISTRICT_LOADING: "DELETE_DISTRICT_LOADING",
  DELETE_DISTRICT_SUCCESS: "DELETE_DISTRICT_SUCCESS",
  DELETE_DISTRICT_ERROR: "DELETE_DISTRICT_ERROR",
  UPDATE_DISTRICT_LOADING: "UPDATE_DISTRICT_LOADING",
  UPDATE_DISTRICT_SUCCESS: "UPDATE_DISTRICT_SUCCESS",
  UPDATE_DISTRICT_ERROR: "UPDATE_DISTRICT_ERROR",
  SET_DISTRICT_FLAG: "SET_DISTRICT_FLAG",
  UPDATE_DISTRICT_RELOAD_TOGGLE_SUCCESS:
    "UPDATE_DISTRICT_RELOAD_TOGGLE_SUCCESS",

  ADD_SCHOOL_ADMIN_LOADING: "ADD_SCHOOL_ADMIN_LOADING",
  ADD_SCHOOL_ADMIN_SUCCESS: "ADD_SCHOOL_ADMIN_SUCCESS",
  ADD_SCHOOL_ADMIN_ERROR: "ADD_SCHOOL_ADMIN_ERROR",
  GET_SCHOOL_ADMIN_LOADING: "GET_SCHOOL_ADMIN_LOADING",
  GET_SCHOOL_ADMIN_SUCCESS: "GET_SCHOOL_ADMIN_SUCCESS",
  GET_SCHOOL_ADMIN_ERROR: "GET_SCHOOL_ADMIN_ERROR",
  DELETE_SCHOOL_ADMIN_LOADING: "DELETE_SCHOOL_ADMIN_LOADING",
  DELETE_SCHOOL_ADMIN_SUCCESS: "DELETE_SCHOOL_ADMIN_SUCCESS",
  DELETE_SCHOOL_ADMIN_ERROR: "DELETE_SCHOOL_ADMIN_ERROR",
  UPDATE_SCHOOL_ADMIN_LOADING: "UPDATE_SCHOOL_ADMIN_LOADING",
  UPDATE_SCHOOL_ADMIN_SUCCESS: "UPDATE_SCHOOL_ADMIN_SUCCESS",
  UPDATE_SCHOOL_ADMIN_ERROR: "UPDATE_SCHOOL_ADMIN_ERROR",
  SET_SCHOOL_ADMIN_FLAG: "SET_SCHOOL_ADMIN_FLAG",
  UPDATE_SCHOOL_RESOURCE_PERMISSION: "UPDATE_SCHOOL_RESOURCE_PERMISSION",
  UPDATE_SCHOOL_RESOURCE_ERROR: "UPDATE_SCHOOL_RESOURCE_ERROR",

  GET_SCHOOLS_LOADING: "GET_SCHOOL_LOADING",
  GET_SCHOOLS_SUCCESS: "GET_SCHOOL_SUCCESS",
  GET_SCHOOLS_ERROR: "GET_SCHOOL_ERROR",

  ADD_SCHOOL_LOADING: "ADD_SCHOOL_LOADING",
  ADD_SCHOOL_SUCCESS: "ADD_SCHOOL_SUCCESS",
  ADD_SCHOOL_ERROR: "ADD_SCHOOL_ERROR",
  DELETE_SCHOOLS_LOADING: "DELETE_SCHOOL_LOADING",
  DELETE_SCHOOLS_SUCCESS: "DELETE_SCHOOL_SUCCESS",
  DELETE_SCHOOLS_ERROR: "DELETE_SCHOOL_ERROR",
  UPDATE_SCHOOLS_LOADING: "UPDATE_SCHOOLS_LOADING",
  UPDATE_SCHOOLS_SUCCESS: "UPDATE_SCHOOLS_SUCCESS",
  UPDATE_SCHOOLS_ERROR: "UPDATE_SCHOOLS_ERROR",
  GET_SINGLE_SCHOOL_LOADING: "GET_SINGLE_SCHOOL_LOADING",
  GET_SINGLE_SCHOOL_SUCCESS: "GET_SINGLE_SCHOOL_SUCCESS",
  GET_SINGLE_SCHOOL_ERROR: "GET_SINGLE_SCHOOL_ERROR",
  SET_SCHOOL_FLAG: "SET_SCHOOL_FLAG",

  ADD_CLASS_LOADING: "ADD_CLASS_LOADING",
  ADD_CLASS_SUCCESS: "ADD_CLASS_SUCCESS",
  ADD_CLASS_ERROR: "ADD_CLASS_ERROR",
  GET_CLASSES_LOADING: "GET_CLASSES_LOADING",
  GET_CLASSES_SUCCESS: "GET_CLASSES_SUCCESS",
  GET_CLASSES_ERROR: "GET_CLASSES_ERROR",
  DELETE_CLASSES_LOADING: "DELETE_CLASSES_LOADING",
  DELETE_CLASSES_SUCCESS: "DELETE_CLASSES_SUCCESS",
  DELETE_CLASSES_ERROR: "DELETE_CLASSES_ERROR",
  UPDATE_CLASSES_LOADING: "UPDATE_CLASSES_LOADING",
  UPDATE_CLASSES_SUCCESS: "UPDATE_CLASSES_SUCCESS",
  UPDATE_CLASSES_ERROR: "UPDATE_CLASSES_ERROR",
  SET_CLASS_FLAG: "SET_CLASS_FLAG",

  ADD_CLASSADMINS_LOADING: "ADD_CLASSADMINS_LOADING",
  ADD_CLASSADMINS_SUCCESS: "ADD_CLASSADMINS_SUCCESS",
  ADD_CLASSADMINS_ERROR: "ADD_CLASSADMINS_ERROR",
  GET_CLASSADMINS_LOADING: "GET_CLASSADMINS_LOADING",
  GET_CLASSADMINS_SUCCESS: "GET_CLASSADMINS_SUCCESS",
  GET_CLASSADMINS_ERROR: "GET_CLASSADMINS_ERROR",
  DELETE_CLASSADMINS_ERROR: "DELETE_CLASSADMINS_ERROR",
  DELETE_CLASSADMINS_SUCEESS: "DELETE_CLASSADMINS_SUCEESS",
  DELETE_CLASSADMINS_LOADING: "DELETE_CLASSADMINS_LOADING",
  UPDATE_CLASSADMINS_LOADING: "UPDATE_CLASSADMINS_LOADING",
  UPDATE_CLASSADMINS_SUCCESS: "UPDATE_CLASSADMINS_SUCCESS",
  UPDATE_CLASSADMINS_ERROR: "UPDATE_CLASSADMINS_ERROR",
  SET_CLASS_ADMIN_FLAG: "SET_CLASS_ADMIN_FLAG",

  ADD_STUDENT_LOADING: "ADD_STUDENT_LOADING",
  ADD_STUDENT_SUCCESS: "ADD_STUDENT_SUCCESS",
  ADD_STUDENT_ERROR: "ADD_STUDENT_ERROR",
  GET_STUDENTS_LOADING: "GET_STUDENTS_LOADING",
  GET_STUDENTS_SUCCESS: "GET_STUDENTS_SUCCESS",
  GET_STUDENTS_ERROR: "GET_STUDENTS_ERROR",
  SET_STUDENT_FLAG: "SET_STUDENT_FLAG",
  SET_ALL_STUDENTS_DETAILS: "SET_ALL_STUDENTS_DETAILS",

  DELETE_STUDENTS_LOADING: "DELETE_STUDENTS_LOADING",
  DELETE_STUDENTS_SUCCESS: "DELETE_STUDENTS_SUCCESS",
  DELETE_STUDENTS_ERROR: "DELETE_STUDENTS_ERROR",
  UPDATE_STUDENTS_LOADING: "UPDATE_STUDENTS_LOADING",
  UPDATE_STUDENTS_SUCCESS: "UPDATE_STUDENTS_SUCCESS",
  UPDATE_STUDENTS_ERROR: "UPDATE_STUDENTS_ERROR",
  GET_STUDENT_DETAIL_LOADING: "GET_STUDENT_DETAIL_LOADING",
  GET_STUDENT_DETAIL_SUCCESS: "GET_STUDENT_DETAIL_SUCCESS",
  GET_STUDENT_DETAIL_ERROR: "GET_STUDENT_DETAIL_ERROR",
  GET_STUDENT_SBR_DETAIL_SUCCESS: "GET_STUDENT_SBR_DETAIL_SUCCESS",
  GET_STUDENT_SBR_DETAIL_ERROR: "GET_STUDENT_SBR_DETAIL_ERROR",

  GET_BREADCRUMB_LOADING: "GET_BREADCRUMB_LOADING",
  GET_BREADCRUMB_SUCCESS: "GET_BREADCRUMB_SUCCESS",
  GET_BREADCRUMB_ERROR: "GET_BREADCRUMB_ERROR",

  UPDATE_IS_PRINCIPAL_ERROR: "UPDATE_IS_PRINCIPAL_ERROR",
  UPDATE_IS_PRINCIPAL_SUCCESS: "UPDATE_IS_PRINCIPAL_SUCCESS",

  UPDATE_ADMIN_DETAILS_LOADING: "UPDATE_ADMIN_DETAILS_LOADING",
  UPDATE_ADMIN_DETAILS_SUCCESS: "UPDATE_ADMIN_DETAILS_SUCCESS",
  UPDATE_ADMIN_DETAILS_ERROR: "UPDATE_ADMIN_DETAILS_ERROR",

  SET_AUDIT_REPORT: "SET_AUDIT_REPORT",
  SET_SCHOOL_AUDIT_REPORT: "SET_SCHOOL_AUDIT_REPORT",

  SET_DISTRICT_M_ID: "SET_DISTRICT_M_ID",
  SET_SCHOOL_M_ID: "SET_SCHOOL_M_ID",
  SET_CLASS_M_ID: "SET_CLASS_M_ID",

  GET_MERGE_STUDENT_SUCCESS: "GET_MERGE_STUDENT_SUCCESS",
  GET_MERGE_STUDENT_ERROR: "GET_MERGE_STUDENT_ERROR",

  // CSV IMPORT CONSTANTS
  UPLOAD_CSV_LOADING: "UPLOAD_CSV_LOADING",
  UPLOAD_CSV_SUCCESS: "UPLOAD_CSV_SUCCESS",
  CLOSE_MODAL: "CLOSE_MODAL",
  UPLOAD_CSV_ERROR: "UPLOAD_CSV_ERROR",
  CANCEL_CSV_UPLOAD: "CANCEL_CSV_UPLOAD",
  UPDATE_SCHOOL_ADMIN_LOCAL: "UPDATE_SCHOOL_ADMIN_LOCAL",
  DELETE_SCHOOL_ADMIN_LOCAL: "DELETE_SCHOOL_ADMIN_LOCAL",
  DELETE_DISTRICT_ADMIN_LOCAL: "DELETE_DISTRICT_ADMIN_LOCAL",
  UPDATE_DISTRICT_LOCAL: "UPDATE_DISTRICT_LOCAL",
  UPDATE_DISTRICT_ADMIN_LOCAL: "UPDATE_DISTRICT_ADMIN_LOCAL",
  UPDATE_SCHOOL_LOCAL: "UPDATE_SCHOOL_LOCAL",
  DELETE_SCHOOL_LOCAL: "DELETE_SCHOOL_LOCAL",
  IMPORT_DATA_LOADING: "IMPORT_DATA_LOADING",
  IMPORT_DATA_SUCCESS: "IMPORT_DATA_SUCCESS",
  IMPORT_DATA_VALIDATION_ERROR: "IMPORT_DATA_VALIDATION_ERROR",
  IMPORT_DATA_ERROR: "IMPORT_DATA_ERROR",
  IMPORT_DATA_ERROR_STATUS: "IMPORT_DATA_ERROR_STATUS",
  IMPORT_DATA_SUCCESS_STATUS: "IMPORT_DATA_SUCCESS_STATUS",
  IMPORT_DATA_PENDING: "IMPORT_DATA_PENDING",

  UPDATE_CLASS_LOCAL: "UPDATE_CLASS_LOCAL",
  DELETE_CLASS_LOCAL: "DELETE_CLASS_LOCAL",
  UPDATE_STUDENT_LOCAL: "UPDATE_STUDENT_LOCAL",
  DELETE_STUDENT_LOCAL: "DELETE_STUDENT_LOCAL",
  DELETE_CLEVER_LOCAL: "DELETE_CLEVER_LOCAL",
  UPDATE_CLEVER_LOCAL: "UPDATE_CLEVER_LOCAL",
  RESET_RESOURCES_REDUCER: "RESET_RESOURCES_REDUCER",
  RESET_CLEVER_CSV_REDUCER: "RESET_CLEVER_CSV_REDUCER",
  RESET_DISTRICT_CSV_REDUCER: "RESET_DISTRICT_CSV_REDUCER",
  RESET_CLASS_CSV_REDUCER: "RESET_CLASS_CSV_REDUCER",

  //CMS ASSESSMENT
  GET_ALL_SUBJECTS_AND_GRADES: "GET_ALL_SUBJECTS_AND_GRADES",
  GET_ALL_SUBJECTS_AND_GRADES_FAIL: "GET_ALL_SUBJECTS_AND_GRADES_FAIL",
  GET_ALL_ASSESSMENTS: "GET_ALL_ASSESSMENTS",
  GET_ALL_ASSESSMENTS_FAIL: "GET_ALL_ASSESSMENTS_FAIL",
  GET_ALL_QUESTIONS: "GET_ALL_QUESTIONS",
  GET_ALL_QUESTIONS_FAIL: "GET_ALL_QUESTIONS",
  SET_ASSESSMENT_QUESTION: "SET_ASSESSMENT_QUESTION",
  SET_ASSESSMENT: "SET_ASSESSMENT",
  GET_ADMIN_GRADES: "GET_ADMIN_GRADES",
  GET_ADMIN_SUBJECTS: "GET_ADMIN_SUBJECTS",
  GET_ADMIN_GRADES_FAIL: "GET_ADMIN_GRADES_FAIL",
  GET_ADMIN_SUBJECTS_FAIL: "GET_ADMIN_SUBJECTS_FAIL",

  //RECOURCES SECTION
  GET_RESOURCES_LOADING: "GET_RESOURCES_LOADING",
  GET_RESOURCES_SUCCESS: "GET_RESOURCES_SUCCESS",
  GET_RESOURCES_ERROR: "GET_RESOURCES_ERROR",
  ADD_RESOURCES_LOADING: "ADD_RESOURCES_LOADING",
  ADD_RESOURCES_SUCCESS: "ADD_RESOURCES_SUCCESS",
  ADD_RESOURCES_ERROR: "ADD_RESOURCES_ERROR",
  UPDATE_RESOURCES_LOADING: "UPDATE_RESOURCES_LOADING",
  UPDATE_RESOURCES_SUCCESS: "UPDATE_RESOURCES_SUCCESS",
  UPDATE_RESOURCES_ERROR: "UPDATE_RESOURCES_ERROR",
  DELETE_RESOURCES_LOADING: "DELETE_RESOURCES_LOADING",
  DELETE_RESOURCES_SUCCESS: "DELETE_RESOURCES_SUCCESS",
  DELETE_RESOURCES_ERROR: "DELETE_RESOURCES_ERROR",
  SORT_RESOURCES_LOADING: "SORT_RESOURCES_LOADING",
  SORT_RESOURCES_SUCCESS: "SORT_RESOURCES_SUCCESS",
  SORT_RESOURCES_ERROR: "SORT_RESOURCES_ERROR",
  RESET_RESOURCES: "RESET_RESOURCES",
  //OBJECTIVES SECTION
  GET_OBJECTIVES_LOADING: "GET_OBJECTIVES_LOADING",
  GET_OBJECTIVES_SUCCESS: "GET_OBJECTIVES_SUCCESS",
  GET_OBJECTIVES_ERROR: "GET_OBJECTIVES_ERROR",
  ADD_OBJECTIVES_LOADING: "ADD_OBJECTIVES_LOADING",
  ADD_OBJECTIVES_SUCCESS: "ADD_OBJECTIVES_SUCCESS",
  ADD_OBJECTIVES_ERROR: "ADD_OBJECTIVES_ERROR",
  UPDATE_OBJECTIVES_LOADING: "UPDATE_OBJECTIVES_LOADING",
  UPDATE_OBJECTIVES_SUCCESS: "UPDATE_OBJECTIVES_SUCCESS",
  UPDATE_OBJECTIVES_ERROR: "UPDATE_OBJECTIVES_ERROR",
  DELETE_OBJECTIVES_LOADING: "DELETE_OBJECTIVES_LOADING",
  DELETE_OBJECTIVES_SUCCESS: "DELETE_OBJECTIVES_SUCCESS",
  DELETE_OBJECTIVES_ERROR: "DELETE_OBJECTIVES_ERROR",
  RESET_OBJECTIVES: "RESET_OBJECTIVES",
  RESET_OBJECTIVE_REDUCER: "RESET_OBJECTIVE_REDUCER",
  SET_NEW_DISTRICT_LOGO: "SET_NEW_DISTRICT_LOGO",

  RESET_PASSWORD_ADMIN_LOADING: "RESET_PASSWORD_ADMIN_LOADING",
  RESET_PASSWORD_ADMIN_SUCCESS: "RESET_PASSWORD_ADMIN_SUCCESS",
  RESET_PASSWORD_ADMIN_ERROR: "RESET_PASSWORD_ADMIN_ERROR",
  CLEAR_OBJECTIVES_ALREADY_EXIST: "CLEAR_OBJECTIVES_ALREADY_EXIST",

  SET_SCHOOL_STL_PERIOD: "SET_SCHOOL_STL_PERIOD",
  SET_SCHOOL_STL_EXISTS: "SET_SCHOOL_STL_EXISTS",
  SET_STL_LOADING: "SET_STL_LOADING",
  SET_STL_LOADED: "SET_STL_LOADED",
  SET_STL_URL: "SET_STL_URL",
  SET_STL_ID: "SET_STL_ID",

  SET_SINGLE_STL_PERIOD: "SET_SINGLE_STL_PERIOD",
  SET_SINGLE_STL_LOADING: "SET_SINGLE_STL_LOADING",
  SET_SINGLE_STL_LOADED: "SET_SINGLE_STL_LOADED",
  SET_SINGLE_STL_URL: "SET_SINGLE_STL_URL",
  SET_SINGLE_STL_ID: "SET_SINGLE_STL_ID",

  SET_STL_SCHOOL_LOADING: "SET_STL_SCHOOL_LOADING",
  SET_STL_CLASS_LOADING: "SET_STL_CLASS_LOADING",

  FORMATIVE_NAME: "FORMATIVE_NAME",
  SUMMATIVE_NAME: "SUMMATIVE_NAME",
  RE_ENABLE_TEST: "RE_ENABLE_TEST",

  SET_IRR_SUMMATIVE: "SET_IRR_SUMMATIVE",
  SET_IRR_FORMATIVE: "SET_IRR_FORMATIVE",
  SET_OBJECTIVE_ACCOUNTABILITY_GRAPH_REPORT:
    "SET_OBJECTIVE_ACCOUNTABILITY_GRAPH_REPORT",
  SET_MASTER_REPORT: "SET_MASTER_REPORT",
  RESET_MASTER_REPORT: "RESET_MASTER_REPORT",
  SET_STANDARD_RISK_REPORT: "SET_STANDARD_RISK_REPORT",
  RESET_STANDARD_RISK_REPORT: "RESET_STANDARD_RISK_REPORT",

  SET_DISTRICT_DETAILS: "SET_DISTRICT_DETAILS",
  SET_SUMMATIVE_ANALYSIS_REPORT: "SET_SUMMATIVE_ANALYSIS_REPORT",

  SET_IS_REPORT_LOADING: "SET_IS_REPORT_LOADING",

  SET_STUDENT_SUMMATIVE_REPORT: "SET_STUDENT_SUMMATIVE_REPORT",

  SET_SHOW_MODAL_SAFARI: "SET_SHOW_MODAL_SAFARI",
  SET_SHOW_API_ERROR_MODAl: "SET_SHOW_API_ERROR_MODAl",

  SET_LAST_REPORT_NAME: "SET_LAST_REPORT_NAME",
  SET_LAST_REPORT_PRINT_COUNT: "SET_LAST_REPORT_PRINT_COUNT",

  // MASTERY VIDEOS
  GET_MASTERY_VIDEOS_SUCCESS: "GET_MASTERY_VIDEOS_SUCCESS",
  GET_MASTERY_VIDEOS_ERROR: "GET_MASTERY_VIDEOS_ERROR",
  GET_MASTERY_VIDEO_DETAILS_SUCCESS: "GET_MASTERY_VIDEO_DETAILS_SUCCESS",
  ADD_MASTERY_VIDEO_COMMENT_SUCCESS: "ADD_MASTERY_VIDEO_COMMENT_SUCCESS",
  SET_MASTERY_VIDEOS_LOADING: "SET_MASTERY_VIDEOS_LOADING",
};

export default ActionType;
