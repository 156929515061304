/* eslint-disable react-hooks/exhaustive-deps */

import SearchIconGray from "assets/img/search-icon-gray.svg";
import ReportHeader from "components/common/report/header";
import { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import {
  getStandardRiskReport,
  resetReportData,
} from "redux/actionCreators/classroom";
import { RootState } from "redux/reducers/combine";
import InfoSection from "./infoSection";
import Record from "./record";
import RiskModal from "./Modal";
import RiskPrint from "./Print";
import StudentTableHeader from "./studnetTableHeader";
import { RiskReportSkeleton } from "components/common/skeleton/ReportsSkeleton";
import { useQuery } from "hooks/useQuery";
import { replaceURL } from "util/replaceUrl";

const RiskReport = () => {
  const [search, setSearch] = useState<string>("");
  const dispatch = useDispatch();
  const query = useQuery();
  const print = query.get("print");

  const { summative_id }: any = useParams();
  const report = useSelector(
    (state: RootState) => state.classroom.standardRiskReport
  );
  const classroomId = useSelector(
    (state: RootState) => state.classroom.classroomId
  );
  // const classroom_id = (window as any)['classroomId'] || classroomId;
  const [isViewStudents, setIsViewStudents] = useState<boolean>(false);
  const [studentsDetails, setStudentDetails] = useState<any>(null!);
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const [searchParams] = useSearchParams();
  const taskId = searchParams.get("task_id");
  const goalId = searchParams.get("goal_id");

  useEffect(() => {
    setIsLoading(true);
    const selectedClassroomid = (window as any)['classroomId'] || classroomId;
    if(selectedClassroomid){
      dispatch(
        getStandardRiskReport(
          parseInt(selectedClassroomid),
          parseInt(summative_id),
          taskId,
          goalId
        )
      );
      replaceURL(`/classroom/report/standard-at-risk/${classroomId}/${summative_id}`)
    }

    return () => {
      dispatch(resetReportData("RESET_STANDARD_RISK_REPORT"));
    };
  }, [classroomId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // , class_name ,school_name
  const { info, class_name, time, test_name, test_type } = useMemo(() => {
    if (report?.data) {
      setIsLoading(false);
      return {
        ...report.data,
        info: report.data.info.sort(
          (
            first: { objective_number: string },
            second: { objective_number: string }
          ) =>
            first.objective_number
              .trim()
              .localeCompare(second.objective_number.trim())
        ),
      };
    }
    return {
      info: [],
      class_name: "",
      school_name: "",
      test_name: "",
      time: new Date(),
    };
  }, [report]);

  const Information = useMemo(() => {
    if (search) {
      return info.filter((record: any) =>
        (record?.objective_number + record?.description)
          ?.toLowerCase()
          .includes(search.toLowerCase())
      );
    }
    return info;
  }, [info, search]);

  const clickViewStudents = (RiskDetails: any) => {
    setStudentDetails(RiskDetails);
    setIsViewStudents(true);
  };

  const onCloseModal = () => {
    setStudentDetails(null!);
    setIsViewStudents(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `StandardsAtRisk_${test_name}_${moment(new Date()).format(
      "M/DD/YY hh:mmA"
    )}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (report && print) {
      handlePrint();
    }
  }, [report]);

  return (
    <div>
      {!print && (
        <div className="risk-report hide-on-print">
          <ReportHeader
            setPrint={handlePrint}
            reportTitle="Standards at Risk"
            testName={test_name || "OK"}
            reportGuide=""
            headerClassName={"report__transparent_header"}
          />

          <div className="risk-report-content">
            <div className="risk-report__search">
              <div className="risk-report__search-wrapper">
                {!isLoading && (
                  <img
                    src={SearchIconGray}
                    className="report__search-icon animated-fade"
                    alt="Search"
                  />
                )}
                <input
                  className={`form-control report__search-input ${
                    isLoading ? "margin-left" : ""
                  }`}
                  type="search"
                  name="Search"
                  placeholder="Search Objectives"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <InfoSection />
            </div>

            <div className="risk-report__main risk-report-table-container">
              <table className="risk-report__table">
                <StudentTableHeader />
                {!isLoading && Information.length === 0 && (
                  <tr className="risk-report__table-row">
                    <td className="report__table-notFound w-100 text-center">
                      No Objectives Found
                    </td>
                  </tr>
                )}
                {!isLoading ? (
                  Information.map((info: any) => {
                    return (
                      <Record
                        {...info}
                        onClickStudentView={clickViewStudents}
                      />
                    );
                  })
                ) : (
                  <RiskReportSkeleton rowColumns={20} />
                )}
              </table>
            </div>

            {studentsDetails && (
              <RiskModal
                isShow={isViewStudents}
                closeModal={onCloseModal}
                objectiveNumber={studentsDetails.objectiveNumber}
                classroomName={class_name}
                students={studentsDetails.students}
                date={time}
                total={studentsDetails.total}
                test_type={test_type}
              />
            )}
          </div>
        </div>
      )}
      {info && (
        <div ref={componentRef} className="printData">
          <div className="risk-report">
            <RiskPrint
              records={info}
              class_name={class_name || ""}
              time={time}
              test_name={test_name || ""}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default RiskReport;
