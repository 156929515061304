/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState, useCallback, Fragment } from "react";
import { LimitSelect } from "components/common/selectComponent";
import SearchIcon from "assets/img/search-icon.svg";
import CommentModal from "components/classroom/modals/comment";
import Pagination from "../pagination/pagination";
import moment from "moment";
import { getMasteryVideoDetail } from "redux/actionCreators/mastery-videos"
import { useDispatch } from "react-redux";
import { addMasteryVideoComment } from "redux/actionCreators/mastery-videos";
import _ from "lodash";
import {EmptyRecordMasteryVideo} from "components/common/EmptyRecord";
import Skeleton from "react-loading-skeleton";


moment.updateLocale('en', {
  relativeTime : {
    past: "%s ago",
    s: 'a few seconds ago',
    ss: '%d seconds ago',
    m: "a minute ago",
    mm: "%d minutes ago",
    h: "an hour ago",
    hh: "%d hours ago",
    d: "Yesterday"
  }
});

const YESTERDAY = new Date(new Date().setDate(new Date().getDate()-1)).setUTCHours(0,0,0,0);

interface Props {
  videoId: string;
  comments: any[];
  comments_count: number;
  loading: boolean;
}

interface Reply {
  commentId: number,
  comment: string
}

const CommentSection: FC<Props> = (props) => {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [reply, setReply] = useState<Reply>(null!)
  const [search, setSearch] = useState("");
  const [queryParams, setQueryParams] = useState({
    currentPage: 1,
    limit: 5,
    search: ''
  });
  const {currentPage, limit} = queryParams;
  const [loading, setLoading] = useState(false);

  const { videoId, comments = [], comments_count } = props;

  const replayHandler = (commentId: number, comment: string) => {
    setReply({
      commentId,
      comment
    })
    setModalShow(true)
  }

  const commentHandler = () => {
    setModalShow(true)
  }

  const onPageChange = (nextPage: number) => {
    setQueryParams((prev: any) => ({
      ...prev,
      currentPage: nextPage
    }));
  };

  useEffect(() => {
    if (videoId) {
      setLoading(true)
      let offset: number = (currentPage * limit) - limit;
      dispatch(getMasteryVideoDetail(videoId, limit, offset, queryParams?.search));
      setTimeout(() => {
        setLoading(false)
      },1000)
    }
  }, [queryParams])

  const addComment = async (comment: string) => {

    const data = {
      comment,
      ...(reply && { comment_id: reply?.commentId })
    }

    dispatch(addMasteryVideoComment(videoId, data))
    onClose()
  }

  const onClose = () => {
    setReply(null!)
    setModalShow(false)
  }

  const handleSearch = useCallback(
    _.debounce((val: any) => {
      console.log(val);

      setLoading(true)
      setQueryParams((prev: any) => ({
        ...prev,
        currentPage: 1,
        search: val
      }));
      setTimeout(() => {
        setLoading(false)
      },1000)
    }, 800),
    [videoId, queryParams]
  );

  const isReplayAdded = comments.some(com => !!com.replies.length)
  const isCommentsExists = comments.length > 0 || search;

  return (
    <div>
      <div className=" MasteryVideos__comment">
        <div className=" MasteryVideos__header d-flex flex-sm-nowrap flex-wrap align-items-center justify-content-between">
          <h2 className="text-white">Comments</h2>
          <div className="section-search">
            <div className={`section-search-field animated mx-md-0 mx-3 ${!isCommentsExists ? 'mastery-commentSec-header' : ''}`}>
              <div className="icon">
                <img src={SearchIcon} alt="" />
              </div>
              <input
                disabled={!isCommentsExists}
                type="text"
                className="form-control"
                placeholder="Search Comments"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  handleSearch(e.target.value)
                }}
                style={{opacity: isCommentsExists ? 1 : 0.3}}
              />
            </div>
            <div className={`d-md-flex align-items-center d-none ${!isCommentsExists ? 'mastery-commentSec-header' : ''}`}>
              <span className="text-white show-select">Show</span>
              <LimitSelect
                onChangeHandler={(e: any) => {
                  setQueryParams((prev: any) => ({
                    ...prev,
                    limit: e?.value
                  }));
                }}
                options={[{
                  value: 5, label: 5
                }]}
                disableHandler={!isCommentsExists || comments_count <= 5}
              />
            </div>
            <button onClick={commentHandler} className="MasteryVideos__comment__button bg-transparent text-white outline-none">
              New Comment
            </button>
          </div>
        </div>
        <div className="MasteryVideos__comment__container">
          <table className="MasteryVideos__comment__container__table ">
            <tbody>
              {loading
              ? 
              <tr>
              <td className="MasteryVideos__comment__container__table__name">
                <Skeleton baseColor="#2a539143" highlightColor="#5371b732" borderRadius={8} width={46} height={42} />
              </td>
              <td className="MasteryVideos__comment__container__table__info">
                  <div className="d-flex align-items-center basic-info flex-wrap" style={{height: 16, marginBottom: 4}}>
                    <Skeleton baseColor="#2a539143" highlightColor="#5371b732" borderRadius={8} width={70} height={16} style={{marginRight: 16}} />
                    <Skeleton baseColor="#2a539143" highlightColor="#5371b732" borderRadius={8} width={70} height={16} style={{marginRight: 16}} />
                    <Skeleton baseColor="#2a539143" highlightColor="#5371b732" borderRadius={8} width={25} height={16} style={{marginRight: 16}} />
                    <Skeleton baseColor="#2a539143" highlightColor="#5371b732" borderRadius={8} width={35} height={16} />
                  </div>
                  <div style={{height: 16}}>
                    <Skeleton baseColor="#2a539143" highlightColor="#5371b732" borderRadius={8} width={120} height={16} />
                  </div>
              </td>
            </tr>
              : comments.length > 0
              ? comments.map((data) => {
                const { replies } = data;
                const commentDateTime = new Date(data.time).setUTCHours(0,0,0,0);
                const formattedCommentTime = commentDateTime >= YESTERDAY ? moment(data.time).fromNow(true) : moment(data.time).format('M/D');
                
                return <Fragment key={data.id}>
                  <tr key={data.id}>
                    <td className="MasteryVideos__comment__container__table__name">
                      <div style={{ backgroundColor: data.color_hex, fontSize: '20px' }} className="text-center d-flex align-items-center justify-content-center">
                        <p>{data.user_initials}</p>
                      </div>
                    </td>
                    <td className="MasteryVideos__comment__container__table__info">
                      <div className={`${isReplayAdded ? "comment_with_replies" : ""}`}>
                        <div className="d-flex align-items-center basic-info flex-wrap">
                          <p className="me-3 fw-bold">{data.display_name}</p>
                          <p className="me-3 basic-info-color">{data.district_school}</p>
                          <p className="me-3 basic-info-color fst-italic">{formattedCommentTime}</p>
                          <p className="me-3 basic-info-color fw-bold cursor-pointer" onClick={() => replayHandler(data.id, data.comment)}>Reply</p>
                        </div>
                        <div>
                          <p className="comment">
                            {data.comment}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {
                    replies?.length ?
                      replies.map((data: any) => {
                        const commentTime = new Date(data.time).setUTCHours(0,0,0,0) >= YESTERDAY ? moment(data.time).fromNow(true) : moment(data.time).format('M/D');
                        return (
                          <tr>
                            <td className="MasteryVideos__comment__container__table__name d-flex">
                              <div className="text-center d-flex align-items-center justify-content-center replayTd ">
                                <p className="emptySpace"></p>
                              </div>
                              <div style={{ backgroundColor: data.color_hex, fontSize: '20px' }} className="text-center d-flex align-items-center justify-content-center">
                                <p>{data.user_initials}</p>
                              </div>
                            </td>
                            <td className="MasteryVideos__comment__container__table__info">
                              <div className="d-flex align-items-center basic-info flex-wrap">
                                <p className="me-3 fw-bold">{data.display_name}</p>
                                <p className="me-3 basic-info-color">Irving ES</p>
                                <p className="me-3 basic-info-color fst-italic">{commentTime}</p>
                                <p className="me-3 basic-info-color fw-bold cursor-pointer" onClick={() => replayHandler(data.id, data.comment)}>Reply</p>
                              </div>
                              <div>
                                <p className="comment">
                                  {data.comment}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )
                      }) : null
                  }
                </Fragment>
              }):(
                <EmptyRecordMasteryVideo onClick={commentHandler} />
              )}
            </tbody>
          </table>
        </div>
      </div>

      {modalShow && (
        <CommentModal
          show={modalShow}
          reply={reply?.comment}
          onSave={addComment}
          onClose={onClose}
          submitButtonName="Post"
        />
      )}

      <Pagination
        totalCount={comments_count}
        currentPage={currentPage}
        pageSize={limit}
        onPageChange={onPageChange}

      />
    </div>
  );
};
export default CommentSection;
