/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { SchoolProgressChart } from "../graphs/SchoolProgressChart";
import { ReactComponent as LinePlotIcon } from "assets/img/line-plot-key.svg";
import PrintFooter from "components/common/report/Print/footer";
import { browserHeights } from "util/index";

const SchoolProgressReportPrint = ({data,school}:any) => {

  return (
    <div className="school-progress-report-print">
      <div className={`text-white ${browserHeights}`}>
        <div className="print-content">
          <div className="bg-print">
            <div className="print-report__header d-flex justify-content-between align-items-end">
              <div className="black-font">
                <h3 className="title">Formative Progress Report</h3>
              </div>
              <div className="black-font">
                <h3 className="black-font title text-right">
                  {moment(new Date()).format("M-D-YY")}
                </h3>
                <p className="black-font print-report__header-text text-right">{school?.school_name}</p>
              </div>
            </div>
          </div>
          <div className="bg-print">
            <div className="report__performance mt-4">
              <div>
                <div className="report__performance-row">
                  <div className="d-flex align-items-center">
                    <div className="report__performance-avg averagep"></div>
                    <p className="graph-text">Average Proficiency for Grade</p>
                  </div>
                </div>
                <div className="report__performance-row">
                  <div className="d-flex align-items-center">
                    <div className="report__performance-avg averageo"><LinePlotIcon/></div>
                    <p className="graph-text">Average Number of OAS Objectives Assessed Per Class</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="report__printChart">
              <div className="report__printChart-container">
                <SchoolProgressChart data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrintFooter/>
    </div>
  );
};
export default SchoolProgressReportPrint;
